import axios from 'axios';
import {API_ENDPOINT, LOCAL_STORAGE_KEY_TOKEN, ROUTE_FORBIDDEN, ROUTE_NOT_AUTHORIZED} from "../utils/constants"
import {isTokenExpired, refreshAccessToken} from "../utils/auth";

const instance = axios.create({
    withCredentials: true,
    baseURL: API_ENDPOINT,
});
instance.interceptors.request.use(
    async (config) => {
        const isAuthEndpoint = config.url.includes('/auth/authenticate') || config.url.includes('/auth/refresh-token');
        if (!isAuthEndpoint) {
            let token = sessionStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) || localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
            if (token) {
                const isExpired = await isTokenExpired(token);
                if (isExpired) {
                    console.log("Instance: Token is expired, request new token!")
                    token = await refreshAccessToken();
                }
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) {
            window.location.href = ROUTE_FORBIDDEN;
        } else if (error.response && error.response.status === 401) {
            window.location.href = ROUTE_NOT_AUTHORIZED;
        }
        return Promise.reject(error);
    }
);
export default instance;
