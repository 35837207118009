import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from '../../services/axiosInstance';
import {
    API_ENDPOINT,
    DEFAULT_PRIMARY_COLOR,
    fetchSystemInfo,
    handleApiError,
    handleErrorMessage,
    handleSuccessMessage,
    handleWarningMessage,
    LOCAL_STORAGE_AVATAR,
    LOCAL_STORAGE_PRIMARY_COLOR,
    ROUTE_CHANGE_PASSWORD,
    ROUTE_DASHBOARD,
    validateField
} from "../../utils/constants";
import {isUserAuthenticated, LOGIN_API_ENDPOINT, storeAuthenticatedInfo} from "../../utils/auth";
import CircularIndeterminate from "../../components/common/CircularIndeterminate";
import {useAlert} from "../../providers/AlertProvider";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CenteredMessageLayout from "../../components/common/CenteredMessageLayout";
import LoginIcon from '@mui/icons-material/Login';

const defaultLoginData = {
    username: '',
    password: '',
    rememberMe: true,
}
export default function Login() {
    const {openAlert} = useAlert();
    const [loginData, setLoginData] = useState(defaultLoginData);
    const [usernameValid, setUsernameValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        async function checkAuthentication() {
            const authenticated = await isUserAuthenticated();
            setLoading(false);
            if (authenticated) {
                navigate(ROUTE_DASHBOARD);
            }
        }

        checkAuthentication().then(() => console.debug("Check authentication from Login"));
    }, [navigate]);
    const fetchUserProfile = async () => {
        try {
            const response = await axios.get(`${API_ENDPOINT}/profiles`);
            const {color, avatar} = response.data;
            if (color) {
                localStorage.setItem(LOCAL_STORAGE_PRIMARY_COLOR, color);
            } else {
                localStorage.setItem(LOCAL_STORAGE_PRIMARY_COLOR, DEFAULT_PRIMARY_COLOR);
            }
            if (avatar) {
                localStorage.setItem(LOCAL_STORAGE_AVATAR, avatar);
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };
    const handleSubmit = async (event) => {
        let validated = true;
        validated &= validateField(loginData.username, setUsernameValid);
        validated &= validateField(loginData.password, setPasswordValid);
        if (validated) {
            try {
                const response = await axios.post(LOGIN_API_ENDPOINT, loginData);
                const {access_token, password_change_required, password_change_token, full_name} = response.data;
                if (access_token) {
                    await storeAuthenticatedInfo(access_token, loginData.username, full_name);
                    await fetchSystemInfo(openAlert);
                    await fetchUserProfile();
                    handleSuccessMessage('Đăng nhập hệ thống thành công!', openAlert);
                    window.location.href = ROUTE_DASHBOARD;
                } else if (password_change_required && password_change_token) {
                    handleWarningMessage('Hệ thống yêu cầu thay đổi mật khẩu!', openAlert);
                    navigate(ROUTE_CHANGE_PASSWORD, {state: {loginData, passwordChangeToken: password_change_token}});
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    handleErrorMessage('Tên đăng nhập hoặc mật khẩu không đúng!', openAlert);
                } else {
                    handleApiError(error, openAlert)
                }
            }
        }
    };
    if (loading) {
        return <CircularIndeterminate/>;
    }
    return (
        <CenteredMessageLayout>
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <LoginIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Đăng nhâp hệ thống
            </Typography>
            <Box sx={{mt: 1, width: '100%', maxWidth: 400}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Tên đăng nhập"
                    autoFocus
                    onChange={(e) => {
                        setLoginData((prev) => ({
                            ...prev,
                            username: e.target.value,
                        }));
                    }}
                    error={!usernameValid}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Mật khẩu"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                        setLoginData((prev) => ({
                            ...prev,
                            password: e.target.value,
                        }));
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Nhấn vào hiện mật khẩu"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    error={!passwordValid}
                />
                <FormControlLabel
                    control={<Checkbox checked={loginData.rememberMe}
                                       color="primary"
                                       onChange={(e) =>
                                           setLoginData((prev) => ({
                                               ...prev,
                                               rememberMe: e.target.checked,
                                           }))
                                       }
                    />}
                    label="Ghi nhớ tôi"
                />
                <CustomLoadingButton buttonLabel="Đăng nhập"
                                     onClick={(event) => handleSubmit(event)}
                                     fullWidth
                                     variant="contained"
                                     sx={{mt: 3, mb: 2}}
                />
            </Box>
        </CenteredMessageLayout>
    );
}
