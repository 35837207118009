import {axisClasses} from "@mui/x-charts";
import {
    formatBarsData,
    formatCurrency,
    handleApiError,
    readGoldWeightUnits,
    setEndOfTheDate,
    setStartOfTheDate
} from "../../utils/constants";
import {useAlert} from "../../providers/AlertProvider";
import * as React from "react";
import {useEffect, useState} from "react";
import {BarChart} from "@mui/x-charts/BarChart";
import {FormControl, Grid, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {endOfMonth, startOfMonth, subMonths} from "date-fns";
import axios from "../../services/axiosInstance";
import {ORDERS_API_ENDPOINT} from "../order/OrderConstant";

const chartSetting = {
    xAxis: [
        {
            label: 'Mua bán, Trao đổi(x1000vnđ)',
        },
    ],
    maxWidth: 400,
    height: 400,
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
        },
    },
};
const valueCountFormatter = (value) => `${value} đơn`;
const valueFormatter = (value) => `${value < 0 ? 'Chi: ' : 'Thu: '}${formatCurrency(Math.abs(value))}`;
const valueWeightFormatter = (value) => `${readGoldWeightUnits(value)}`;
const TYPE_COUNT = {type: 'COUNT', label: 'Theo số đơn', valueCountFormatter};
const TYPE_AMOUNT = {type: 'AMOUNT', label: 'Theo số tiền', valueFormatter};
const TYPE_WEIGHT = {type: 'GOLD_WEIGHT', label: 'Theo TL vàng', valueWeightFormatter};
const FILTER_SELL_ORDER = {dataKey: 'sell', label: 'Đơn bán', valueFormatter};
const FILTER_BUY_ORDER = {dataKey: 'buy', label: 'Đơn mua', valueFormatter};
const FILTER_EXC_ORDER = {dataKey: 'exchange', label: 'Trao đổi', valueFormatter};
const OrderChart = () => {
    const {openAlert} = useAlert();
    const [chartData, setChartData] = useState([]);
    const [chartDataType, setChartDataType] = useState(TYPE_AMOUNT);
    const [chartSeries, setChartSeries] = useState(FILTER_SELL_ORDER)

    const getValueFormatter = (type) => {
        return type === TYPE_AMOUNT ? valueFormatter : (type === TYPE_WEIGHT ? valueWeightFormatter : valueCountFormatter)
    }
    const formatChartData = (type = TYPE_AMOUNT, buyBars = [], sellBars = [], exchangeBars = []) => {
        const maxLength = Math.max(buyBars.length, sellBars.length, exchangeBars.length);
        return Array.from({length: maxLength}, (_, index) => {
            const {year, month, amount, count, weight} = buyBars[index] || {};
            const sellEntry = sellBars.find(item => item?.year === year && item?.month === month) || {};
            const sellAmount = sellEntry.amount || 0;
            const sellWeight = sellEntry.weight || 0;
            const sellCount = sellEntry.count || 0;
            const exchangeEntry = exchangeBars.find(item => item?.year === year && item?.month === month) || {};
            const exchangeAmount = exchangeEntry.amount || 0;
            const exchangeCount = exchangeEntry.count || 0;
            const exchangeWeight = exchangeEntry.weight || 0;
            const formatMonth = `${(month || 0).toString().padStart(2, '0')}/${year || ''}`;
            return {
                month: formatMonth,
                buy: type === TYPE_AMOUNT ? amount || 0 : (type === TYPE_WEIGHT ? weight || 0 : count || 0),
                sell: type === TYPE_AMOUNT ? sellAmount : (type === TYPE_WEIGHT ? sellWeight : sellCount),
                exchange: type === TYPE_AMOUNT ? exchangeAmount : (type === TYPE_WEIGHT ? exchangeWeight : exchangeCount),
            };
        });
    }
    const fetchOrderCharts = async (filter = undefined) => {
        try {
            const now = new Date();
            const fromDate = startOfMonth(subMonths(now, 11)); // 12 months ago
            const toDate = endOfMonth(now);
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1; // JavaScript months are zero-based
            const requestBody = {fromDate: setStartOfTheDate(fromDate), toDate: setEndOfTheDate(toDate)}
            const response = await axios.post(`${ORDERS_API_ENDPOINT}/charts`, requestBody);
            const sellBars = formatBarsData(response.data.sellBars, currentYear, currentMonth);
            const buyBars = formatBarsData(response.data.buyBars, currentYear, currentMonth);
            const exchangeBars = formatBarsData(response.data.exchangeBars, currentYear, currentMonth);
            const bars = formatChartData(chartDataType, buyBars, sellBars, exchangeBars);
            setChartData(bars);
        } catch (error) {
            console.error(error);
            handleApiError(error, openAlert);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await fetchOrderCharts();
        };
        fetchData().then(() => console.log("Order chart loaded!"));
        // eslint-disable-next-line
    }, [chartDataType, chartSeries]);

    const handleChange = (event) => {
        const type = event.target.value;
        if (TYPE_COUNT.type === type) {
            setChartDataType(TYPE_COUNT);
            setChartSeries({...chartSeries, valueFormatter: valueCountFormatter});
        } else if (TYPE_AMOUNT.type === type) {
            setChartDataType(TYPE_AMOUNT);
            setChartSeries({...chartSeries, valueFormatter: valueFormatter});
        } else {
            setChartDataType(TYPE_WEIGHT);
            setChartSeries({...chartSeries, valueFormatter: valueWeightFormatter});
        }
    };
    const handleFilterChange = (event) => {
        const type = event.target.value;
        switch (type) {
            case FILTER_BUY_ORDER.dataKey: {
                setChartSeries({
                    ...FILTER_BUY_ORDER,
                    valueFormatter: getValueFormatter(chartDataType)
                });
                return;
            }
            case FILTER_SELL_ORDER.dataKey: {
                setChartSeries({
                    ...FILTER_SELL_ORDER,
                    valueFormatter: getValueFormatter(chartDataType)
                });
                return;
            }
            case FILTER_EXC_ORDER.dataKey: {
                setChartSeries({
                    ...FILTER_EXC_ORDER,
                    valueFormatter: getValueFormatter(chartDataType)
                });
                return;
            }
            default: {
                return;
            }
        }
    };
    return (
        <Grid container>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Chọn loại dữ liệu</InputLabel>
                        <Select
                            value={chartDataType.type}
                            label="Chọn loại dữ liệu"
                            onChange={handleChange}
                        >
                            <MenuItem value={TYPE_COUNT.type}>{TYPE_COUNT.label}</MenuItem>
                            <MenuItem value={TYPE_AMOUNT.type}>{TYPE_AMOUNT.label}</MenuItem>
                            <MenuItem value={TYPE_WEIGHT.type}>{TYPE_WEIGHT.label}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Lọc dữ liệu theo</InputLabel>
                        <Select
                            value={chartSeries.dataKey}
                            label="Lọc dữ liệu theo"
                            onChange={handleFilterChange}
                        >
                            <MenuItem value={FILTER_SELL_ORDER.dataKey}>{FILTER_SELL_ORDER.label}</MenuItem>
                            <MenuItem value={FILTER_BUY_ORDER.dataKey}>{FILTER_BUY_ORDER.label}</MenuItem>
                            <MenuItem value={FILTER_EXC_ORDER.dataKey}>{FILTER_EXC_ORDER.label}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <BarChart
                    dataset={chartData}
                    yAxis={[{scaleType: 'band', dataKey: 'month'}]}
                    series={[chartSeries]}
                    layout="horizontal"
                    {...chartSetting}
                />
            </Grid>
        </Grid>
    );
}
export default OrderChart
