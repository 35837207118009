import React, {useState} from "react";
import {Grid, TextField} from "@mui/material";
import {DEFAULT_USER_DATA} from "./UserConstant";
import {connect} from "react-redux";
import {createUserRequest, isUsernameExist} from "./userThunks";
import validator from 'validator'
import {getResponsiveWidth, handleApiError, handleErrorMessage, handleSuccessMessage} from "../../utils/constants";
import {useAlert} from "../../providers/AlertProvider";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CustomDialog from "../../components/dialog/CustomDialog";
import PropTypes from "prop-types";

const CreateUser = ({onCheckUsername, onCreatePressed, open, onClose}) => {
    const {openAlert} = useAlert();
    const [user, setUser] = useState(DEFAULT_USER_DATA);
    const [nameValid, setNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [usernameValid, setUsernameValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const onCreateUserClick = async () => {
        let validated = true;
        if (user.email.trim() !== '' && !validator.isEmail(user.email)) {
            setEmailValid(false);
            validated = false;
        } else {
            setEmailValid(true);
        }
        if (user.name.trim() === "") {
            setNameValid(false);
            validated = false;
        } else {
            setNameValid(true);
        }
        if (user.username.trim() === "") {
            setUsernameValid(false);
            validated = false;
        } else {
            const userExist = await onCheckUsername(user.username);
            if (userExist) {
                handleErrorMessage('Người [' + user.username + '] đã tồn tại!', openAlert)
                validated = false;
                setUsernameValid(false);
            } else {
                setUsernameValid(true);
            }
        }

        if (user.password.trim() === "") {
            setPasswordValid(false);
            validated = false;
        } else {
            setPasswordValid(true);
        }

        if (validated) {
            try {
                const response = await onCreatePressed(user);
                handleSuccessMessage('Người [' + user.username + '] được tạo thành công', openAlert);
                onClose(response);
            } catch (error) {
                handleApiError(error, openAlert);
            }
        }
    };
    const handleClose = async () => {
        onClose();
    };

    const formActions = (
        <CustomLoadingButton variant="contained" onClick={onCreateUserClick}
                             sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Tạo người dùng"/>
    );
    const pageTitle = (
        <>
            {`Tạo người dùng`}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Họ và tên"
                            value={user.name}
                            onChange={(e) => {
                                setUser((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                }));
                                setNameValid(true);
                            }}
                            inputProps={{maxLength: 200}}
                            fullWidth
                            error={!nameValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Địa chỉ email"
                            value={user.email}
                            onChange={(e) => {
                                setUser((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                }));
                                setEmailValid(true);
                            }}
                            inputProps={{maxLength: 200}}
                            fullWidth
                            error={!emailValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Tên đăng nhập"
                            value={user.username}
                            onChange={(e) => {
                                setUser((prev) => ({
                                    ...prev,
                                    username: e.target.value,
                                }));
                                setUsernameValid(true);
                            }}
                            inputProps={{maxLength: 50}}
                            fullWidth
                            error={!usernameValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Mật khẩu"
                            value={user.password}
                            type="password"
                            onChange={(e) => {
                                setUser((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                }));
                                setPasswordValid(true);
                            }}
                            inputProps={{maxLength: 50}}
                            fullWidth
                            error={!passwordValid}
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
        </div>
    );
}

const mapStateToProps = state => ({
    users: state.users,
});

const mapDispatchToProps = dispatch => ({
    onCreatePressed: user => dispatch(createUserRequest(user)),
    onCheckUsername: username => dispatch(isUsernameExist(username)),
});
CreateUser.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreatePressed: PropTypes.func.isRequired,
    onCheckUsername: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
