import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {
    ADMINISTRATOR_USER_NAME,
    DEFAULT_ERROR_MESSAGE,
    getCurrentUser,
    handleErrorMessage,
    handleSuccessMessage,
    SYSTEMS_API_ENDPOINT
} from "../../utils/constants";
import React, {useEffect, useState} from "react";
import axios from '../../services/axiosInstance';
import GoldBrandGrid from "./GoldBrandGrid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoldTypeGrid from "./GoldTypeGrid";
import GoldPriceGrid from "./GoldPriceGrid";
import SysConfig from "./SysConfig";
import {useAlert} from "../../providers/AlertProvider";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import ShopInfoDetails from "./ShopInfoDetails";
import {usePermissions} from "../../providers/PermissionProvider";

const SystemBoard = () => {
    const permissions = usePermissions();
    const {openAlert} = useAlert();
    const [brands, setBrands] = useState([]);
    const [types, setTypes] = useState([]);
    const [prices, setPrices] = useState([]);
    const [configs, setConfigs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = React.useState('panel0');
    const [openShopInfoDialog, setOpenShopInfoDialog] = useState(false);
    const [shopInfo, setShopInfo] = useState({});

    const processUpdate = async (updateInfo) => {
        try {
            await axios.put(`${SYSTEMS_API_ENDPOINT}/shop-info`, updateInfo);
            handleSuccessMessage('Thông tin tiệm được lưu thành công', openAlert);
        } catch (error) {
            handleErrorMessage(DEFAULT_ERROR_MESSAGE, openAlert);
        } finally {
            await getSystemInfo();
        }
    };
    const handleItemUpdate = async (updatedRow) => {
        await processUpdate(updatedRow);
        setOpenShopInfoDialog(false);
    }
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const getSystemInfo = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${SYSTEMS_API_ENDPOINT}`);
            setBrands(response.data.brands.map((brand) => ({
                ...brand,
                id: brand.id,
            })));
            setTypes(response.data.types.map((type) => ({
                ...type,
                id: type.id,
            })));
            setPrices(response.data.prices.map((price) => ({
                ...price,
                id: price.id,
            })));
            let configs = response.data.configs;
            if (ADMINISTRATOR_USER_NAME !== getCurrentUser()) {
                //Hiding EXCLUDE_VISIBLE_ITEM for normal user
                configs = configs.filter(item => item.key !== "EXCLUDE_VISIBLE_ITEM")
            }
            setConfigs(configs.map((config) => ({
                ...config,
                id: config.key,
            })));
            setShopInfo(response.data.shopInfo);
            return response.data;
        } catch (error) {
            handleErrorMessage(DEFAULT_ERROR_MESSAGE, openAlert);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {

        const fetchData = async () => {
            await getSystemInfo();
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            {permissions.SYSTEMS?.includes('VIEW_GOLD_PRICE') && (
                <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography style={{fontWeight: 'bold'}}>Giá vàng</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GoldPriceGrid sourceData={prices} loading={loading}
                                       onUpdateCompleted={async () => await getSystemInfo()}/>
                    </AccordionDetails>
                </Accordion>
            )}
            {permissions.SYSTEMS?.includes('VIEW_GOLD_TYPE') && (
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography style={{fontWeight: 'bold'}}>Loại vàng</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GoldTypeGrid sourceData={types} loading={loading}
                                      onUpdateCompleted={async () => await getSystemInfo()}/>
                    </AccordionDetails>
                </Accordion>
            )}
            {permissions.SYSTEMS?.includes('VIEW_GOLD_BRAND') && (
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography style={{fontWeight: 'bold'}}>Danh mục: Chành</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GoldBrandGrid sourceBrands={brands} loading={loading}
                                       onUpdateCompleted={async () => await getSystemInfo()}/>
                    </AccordionDetails>
                </Accordion>
            )}
            {permissions.SYSTEMS?.includes('VIEW_COMMON_SETTING') && (
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography style={{fontWeight: 'bold'}}>Danh mục: Cài đặt chung </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SysConfig sourceData={configs} loading={loading}
                                   onUpdateCompleted={async () => await getSystemInfo()}/>
                    </AccordionDetails>
                </Accordion>
            )}
            {permissions.SYSTEMS?.includes('VIEW_SHOP_INFO') && (
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography style={{fontWeight: 'bold'}}>Thông tin cửa hàng</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CustomLoadingButton sx={{minWidth: 170, marginBottom: 1}}
                                             fullWidth
                                             variant="contained"
                                             buttonLabel="Thông tin tiệm" onClick={() => {
                            setOpenShopInfoDialog(true);
                        }}/>
                    </AccordionDetails>
                </Accordion>
            )}
            <ShopInfoDetails onClose={() => {
                setOpenShopInfoDialog(false);
            }} open={openShopInfoDialog} sourceData={shopInfo} onItemUpdated={handleItemUpdate}/>
        </div>);
}
export default SystemBoard;
