import {readGoldWeightUnits, readMoneyInVietnameseDong} from "../../utils/constants";
import {getAmountDes, getPositiveAmount} from "./OrderConstant";
import JsBarcode from "jsbarcode";

export const handlePrinting = async (orderData = {}) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
          <html lang="en-US">
              <head>
                <link rel="icon" href="/logo.png">
                <style>
                    .body-stamp {
                        width: 225mm;
                        height: 145mm;
                        white-space: nowrap;
                        display: flex;
                        justify-content: space-between;
                         /* border: 1px solid #000; */
                        font-family: Tahoma, sans-serif;
                    }
                    
                    .left-col {
                        width: 63mm;
                        padding-left: 5px; 
                       /* line-height: 1.5em;*/
                    }
                    .left-col .title {
                        width: 25%
                    }
                    .left-col .content {
                        width: 75%
                    }
                    .left-col .sell-items table {
                        border-collapse: collapse; text-align: center; font-size: 0.6em; width: 60mm;
                    }
                    .left-col .buy-items table {
                        width: 60mm; border-collapse: collapse; text-align: center; font-size: 0.6em;
                    }
                    .left-col .summary-items table {
                        width: 55mm; border-collapse: collapse; font-size: 0.8em;
                    }
                    
                    .right-col {
                        padding-right: 5px;
                        width: 145mm;
                        /*line-height: 1.8em;*/
                    }
                    
                    .col-full-width {
                        width: 100%
                    }
                    
                    .col-half-width {
                        width: 100%
                    }
                    .right-col .title {
                        width: 25%
                    }
                    .right-col .title-name {
                        width: 32%
                    }
                    .right-col .content {
                        width: 75%
                    }
                    .right-col .content-name {
                        width: 68%
                    }
                    .right-col .content-date {
                        display: flex;
                        justify-content: space-between;
                    }
                    .col-with-col {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    .col-with-col .hidden-table {
                        display: none;
                    }
                    .right-col .sell-items table {
                        border-collapse: collapse; text-align: center; font-size: 0.6em; width: 145mm;
                    }
                    .right-col .buy-items table {
                        width: 80mm; border-collapse: collapse; text-align: center; font-size: 0.6em;
                    }
                    .right-col .summary-items table {
                        width: 55mm; border-collapse: collapse; font-size: 0.8em;
                    }
                    .f-bold {
                        font-weight: bold;
                    }
                    .t-right {
                        text-align: right;
                    }
                    .body-stamp .with-border-color {
                        border: 1px solid rgba(0,0,255,0.35);
                    }
                    @media print {
                        body {
                            margin: 0; /* Remove body margin */
                        }
                        @page {
                            size: 210mm 145mm; 
                            margin: 0; /* Remove any default margins */
                        }
                    }
                    svg {
                      height:60px;
                      width: 150px;
                    }
                </style>
                <title>In giấy đảm bảo</title>
              </head>
              <body>
                <div class="body-stamp">
                    <div class="left-col">
                        <div class="col-with-col" style="height: 20mm"></div>
                        <div class="col-with-col" style="font-size: 0.6em;">
                            <div class="title"><span>Ngày lập HĐ: </span></div>
                            <div class="content"><span>${orderData.orderDate.format('HH:mm DD/MM/YYYY')}</span></div>
                        </div>
                        <div class="col-with-col" style="font-size: 0.6em;">
                            <div class="title">Số HĐ: </div>
                            <div class="content"><span>${orderData.orderId}</span></div>
                        </div>
                        <div class="col-with-col" style="font-size: 0.6em;">
                            <div class="title">Khách hàng: </div>
                            <div class="content"><span>${orderData.customerName}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="sell-items">
                                <table class="with-border-color ${!orderData.sellItems || orderData.sellItems.length === 0 ? 'hidden-table' : ''}">
                                    <tr style="font-weight: bold; height: 25px;">
                                        <td class="with-border-color">STT</td>
                                        <td class="with-border-color">Tên</td>
                                        <td class="with-border-color">L.vàng</td>
                                        <td class="with-border-color">TL vàng</td>
                                        <td class="with-border-color">Giá</td>
                                        <td class="with-border-color">Thành tiền</td>
                                    </tr>
                                    ${orderData.sellItems?.map((item) => `
                                        <tr>
                                            <td class="with-border-color">${item.index ? item.index : ''}</td>
                                            <td class="with-border-color" style="text-align: left;">${item.name ? item.name : ''}</td>
                                            <td class="with-border-color">${item.goldType ? item.goldType : ''}</td>
                                            <td class="with-border-color">${readGoldWeightUnits(item.goldWeight)}</td>
                                            <td class="with-border-color t-right">${Number(item.price ? (item.price / 1000) : 0).toLocaleString()}</td>
                                            <td class="with-border-color t-right">${Number(item.amount ? (item.amount / 1000) : 0).toLocaleString()}</td>
                                        </tr>
                                    `).join('')}
                                </table>
                           </div>
                        </div>
                        <div class="col-with-col" style="height: 8mm"></div>
                        <div class="col-with-col">
                            <div class="buy-items">
                                <span style="font-size: 0.6em;" class="${!orderData.buyItems || orderData.buyItems.length === 0 ? 'hidden-table' : ''}">Vàng khách: </span>
                                <table class="with-border-color ${!orderData.buyItems || orderData.buyItems.length === 0 ? 'hidden-table' : ''}">
                                    <tr style="font-weight: bold; height: 25px;">
                                        <td class="with-border-color">STT</td>
                                        <td class="with-border-color">Tên</td>
                                        <td class="with-border-color">L.vàng</td>
                                        <td class="with-border-color">TL vàng</td>
                                        <td class="with-border-color">TL hột</td>
                                        <td class="with-border-color">Đơn giá</td>
                                        <td class="with-border-color">G.bù</td>
                                        <td class="with-border-color">Thành tiền</td>
                                    </tr>
                                    ${orderData.buyItems?.map((item) => `
                                        <tr>
                                            <td class="with-border-color">${item.index ? item.index : ''}</td>
                                            <td class="with-border-color" style="text-align: left;">${item.name ? item.name : ''}</td>
                                            <td class="with-border-color">${item.goldType ? item.goldType : ''}</td>
                                            <td class="with-border-color">${readGoldWeightUnits(item.goldWeight)}</td>
                                            <td class="with-border-color">${readGoldWeightUnits(item.gemWeight)}</td>
                                            <td class="with-border-color t-right">${Number(item.price ? (item.price / 1000) : 0).toLocaleString()}</td>
                                            <td class="with-border-color t-right">${Number(item.compPrice ? (item.compPrice / 1000) : 0).toLocaleString()}</td>
                                            <td class="with-border-color t-right">${Number(item.amount ? (item.amount / 1000) : 0).toLocaleString()}</td>
                                        </tr>
                                    `).join('')}
                                </table>
                            </div>
                        </div>
                        <div class="col-with-col" style="height: 8mm"></div>
                        <div class="col-with-col">
                           <div class="summary-items">
                                <table class="with-border-color">
                                    <tr style="font-weight: bold;">
                                        <td class="with-border-color"></td>
                                        <td class="with-border-color">Thành tiền</td>
                                    </tr>
                                    <tr class="${!orderData.sellItems || orderData.sellItems.length === 0 ? 'hidden-table' : ''}">
                                        <td class="with-border-color">Tiền vàng mới: </td>
                                        <td class="with-border-color t-right">${Number(orderData.sellAmount ? orderData.sellAmount : 0).toLocaleString()}</td>
                                    </tr>
                                    <tr class="${!orderData.buyItems || orderData.buyItems.length === 0 ? 'hidden-table' : ''}">
                                        <td class="with-border-color">Tiền vàng khách: </td>
                                        <td class="with-border-color t-right">${Number(orderData.buyAmount ? orderData.buyAmount : 0).toLocaleString()}</td>
                                    </tr>
                                    <tr class="${!orderData.buyItems || orderData.buyItems.length === 0 ? 'hidden-table' : ''}">
                                        <td class="with-border-color">Vàng dư/bù: ${readGoldWeightUnits(orderData.extraGoldWeight ? orderData.extraGoldWeight : 0)}</td>
                                        <td class="with-border-color t-right">${Number(orderData.extraGoldAmount ? orderData.extraGoldAmount : 0).toLocaleString()}</td>
                                    </tr>
                                    <tr class="${!orderData.sellItems || orderData.sellItems.length === 0 ? 'hidden-table' : ''}">
                                        <td class="with-border-color">Giảm giá: </td>
                                        <td class="with-border-color t-right">${Number(orderData.discount ? orderData.discount : 0).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td class="with-border-color">Thanh toán${getAmountDes(orderData.totalAmount)}: </td>
                                        <td class="with-border-color t-right">${Number(orderData.totalAmount ? getPositiveAmount(orderData.totalAmount) : 0).toLocaleString()}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="right-col">
                        <div class="col-with-col" style="height: 45mm">
                                <div class="col-half-width">
                                    <div class="col-with-col" style="height: 20mm"></div>
                                    <svg id="barcode-container"></svg>
                                </div>
                                <div class="col-half-width t-right">
                                </div>
                        </div>
                        <div class="col-with-col" style="height: 6mm">
                            <div class="title"><span>Khách hàng: </span></div>
                            <div class="content"><span>${orderData.customerName}</span></div>
                        </div>
                        <div style="height: 78mm; width: 145mm">
                            <div class="col-with-col" style="height: 42mm;">
                                <div class="sell-items">
                                    <table class="with-border-color ${!orderData.sellItems || orderData.sellItems.length === 0 ? 'hidden-table' : ''}">
                                        <tr style="font-weight: bold; height: 25px;">
                                            <td class="with-border-color">STT</td>
                                            <td class="with-border-color">Tên</td>
                                            <td class="with-border-color">L.vàng</td>
                                            <td class="with-border-color">Vàng</td>
                                            <td class="with-border-color">Hột</td>
                                            <td class="with-border-color">Đ.giá</td>
                                            <td class="with-border-color">Thành tiền</td>
                                            <td class="with-border-color">T.Công</td>
                                            <td class="with-border-color">G.Giá</td>
                                            <td class="with-border-color">Thành tiền(luôn công)</td>
                                        </tr>
                                        ${orderData.sellItems?.map((item) => `
                                            <tr>
                                                <td class="with-border-color">${item.index ? item.index : ''}</td>
                                                <td class="with-border-color" style="text-align: left;">${item.name ? item.name : ''}</td>
                                                <td class="with-border-color">${item.goldType ? item.goldType : ''}</td>
                                                <td class="with-border-color">${readGoldWeightUnits(item.goldWeight)}</td>
                                                <td class="with-border-color">${readGoldWeightUnits(item.gemWeight)}</td>
                                                <td class="with-border-color t-right">${Number(item.price ? (item.price / 1000) : 0).toLocaleString()}</td>
                                                <td class="with-border-color t-right">${Number(item.goldAmount ? (item.goldAmount / 1000) : 0).toLocaleString()}</td>
                                                <td class="with-border-color t-right">${Number(item.procPrice ? (item.procPrice / 1000) : 0).toLocaleString()}</td>
                                                <td class="with-border-color t-right">${Number(item.discount ? (item.discount / 1000) : 0).toLocaleString()}</td>
                                                <td class="with-border-color t-right">${Number(item.amount ? (item.amount) : 0).toLocaleString()}</td>
                                            </tr>
                                        `).join('')}
                                    </table>
                               </div>
                            </div>
                            <div class="col-with-col" style="height: 30mm;">
                                <div class="buy-items">
                                    <span style="font-size: 0.6em;" class="${!orderData.buyItems || orderData.buyItems.length === 0 ? 'hidden-table' : ''}">Vàng khách: </span>
                                    <table class="with-border-color ${!orderData.buyItems || orderData.buyItems.length === 0 ? 'hidden-table' : ''}">
                                        <tr style="font-weight: bold; height: 25px;">
                                            <td class="with-border-color">STT</td>
                                            <td class="with-border-color">Tên</td>
                                            <td class="with-border-color">L.vàng</td>
                                            <td class="with-border-color">Vàng</td>
                                            <td class="with-border-color">Hột</td>
                                            <td class="with-border-color">Đ.giá</td>
                                            <td class="with-border-color">G.bù</td>
                                            <td class="with-border-color">Thành tiền</td>
                                        </tr>
                                        ${orderData.buyItems.map((item) => `
                                            <tr>
                                                <td class="with-border-color">${item.index ? item.index : ''}</td>
                                                <td class="with-border-color" style="text-align: left;">${item.name ? item.name : ''}</td>
                                                <td class="with-border-color">${item.goldType ? item.goldType : ''}</td>
                                                <td class="with-border-color">${readGoldWeightUnits(item.goldWeight)}</td>
                                                <td class="with-border-color">${readGoldWeightUnits(item.gemWeight)}</td>
                                                <td class="with-border-color t-right">${Number(item.price ? (item.price / 1000) : 0).toLocaleString()}</td>
                                                <td class="with-border-color t-right">${Number(item.compPrice ? (item.compPrice / 1000) : 0).toLocaleString()}</td>
                                                <td class="with-border-color t-right">${Number(item.amount ? (item.amount) : 0).toLocaleString()}</td>
                                            </tr>
                                        `).join('')}
                                    </table>
                               </div>
                                
                               <div class="summary-items">
                                    <table class="with-border-color" >
                                        <tr style="font-weight: bold;">
                                            <td class="with-border-color"></td>
                                            <td class="with-border-color">Thành tiền</td>
                                        </tr>
                                        <tr class="${!orderData.sellItems || orderData.sellItems.length === 0 ? 'hidden-table' : ''}">
                                            <td class="with-border-color">Tiền vàng mới(LC): </td>
                                            <td class="with-border-color t-right">${Number(orderData.sellAmount ? orderData.sellAmount : 0).toLocaleString()}</td>
                                        </tr>
                                        <tr class="${!orderData.buyItems || orderData.buyItems.length === 0 ? 'hidden-table' : ''}">
                                            <td class="with-border-color">Tiền vàng khách: </td>
                                            <td class="with-border-color t-right">${Number(orderData.buyAmount ? orderData.buyAmount : 0).toLocaleString()}</td>
                                        </tr>
                                        <tr class="${!orderData.buyItems || orderData.buyItems.length === 0 ? 'hidden-table' : ''}">
                                            <td class="with-border-color">Vàng dư/bù: ${readGoldWeightUnits(orderData.extraGoldWeight ? orderData.extraGoldWeight : 0)}</td>
                                            <td class="with-border-color t-right">${Number(orderData.extraGoldAmount ? orderData.extraGoldAmount : 0).toLocaleString()}</td>
                                        </tr>
                                        <tr class="${!orderData.sellItems || orderData.sellItems.length === 0 ? 'hidden-table' : ''}">
                                            <td class="with-border-color">Giảm giá: </td>
                                            <td class="with-border-color t-right">${Number(orderData.discount ? orderData.discount : 0).toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td class="with-border-color">Thanh toán${getAmountDes(orderData.totalAmount)}: </td>
                                            <td class="with-border-color t-right" >${Number(orderData.totalAmount ? getPositiveAmount(orderData.totalAmount) : 0).toLocaleString()}</td>
                                        </tr>
                                    </table>
                               </div>
                            </div>
                        </div>
                       <div class="col-with-col" style="font-size: 0.6em; width: 145mm">
                            <div class="title">Số HĐ: ${orderData.orderId}</div>
                            <div class="content t-right f-bold"><span>${getAmountDes(orderData.totalAmount)}: ${readMoneyInVietnameseDong(orderData.totalAmount)}</span></div>
                        </div>
                        <div class="col-with-col" style="font-size: 0.7em; width: 145mm">
                            <div class="title"></div>
                            <div class="content t-right f-bold" style="padding-right: 5px"><span>${Number(orderData.totalAmount ? getPositiveAmount(orderData.totalAmount) : 0).toLocaleString()}</span></div>
                        </div>
                        <div class="col-with-col" style="width: 148mm">
                            <div class="title"></div>
                            <div class="content t-right content-date" style="line-height: 1.2em; padding-right: 5px"><div style="width: 270px"><span>${orderData.orderDate.format('DD')} </span></div><div style="width: 55px"><span>${orderData.orderDate.format('MM')} </span></div><div style="width: 75px"><span>${orderData.orderDate.format('YYYY')} </span></div></div>
                        </div>
                    </div>
                </div>
              </body>
          </html>
        `);
    JsBarcode(printWindow.document.getElementById("barcode-container"), `${orderData.orderId}`, {
        displayValue: true,
        width: 2,
        height: 40,
        margin: 0,
    });
    printWindow.document.close();
    printWindow.print();
}
