import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import KpiTitle from "./KpiTitle";
import Box from "@mui/material/Box";
import {
    formatCurrency,
    getGoldTypeByCode,
    handleApiError,
    readGoldWeightUnits,
    ROUTE_ORDER_MGMT,
    setEndOfTheDate,
    setStartOfTheDate
} from "../../utils/constants";
import {useAlert} from "../../providers/AlertProvider";
import EastIcon from "@mui/icons-material/East";
import {useNavigate} from "react-router-dom";
import axios from "../../services/axiosInstance";
import {getOrderTypeByCode, ORDERS_API_ENDPOINT} from "../order/OrderConstant";
import Backdrop from "@mui/material/Backdrop";
import {CircularProgress} from "@mui/material";
import {randomId} from "@mui/x-data-grid-generator";

const OrderKpiSection = () => {
    const {openAlert} = useAlert();
    const [loading, setLoading] = useState(true);
    const [filterLabel, setFilterLabel] = useState('[Hôm nay]');
    const [dateFilter, setDateFilter] = useState();
    const [orderKPI, setOrderKPI] = useState({
        totalOrderCount: 0,
        totalOrderAmount: 0,
        kpis: [],
    });
    const navigate = useNavigate();
    const fetchOrderKPIs = async (filter) => {
        try {
            setLoading(true);
            const now = new Date();
            const requestBody = filter || {fromDate: setStartOfTheDate(now), toDate: setEndOfTheDate(now)}
            const response = await axios.post(`${ORDERS_API_ENDPOINT}/kpi-section`, requestBody);
            const sortedKpis = response.data.kpis.sort((a, b) => {
                const order = ["BUY", "SELL", "EXCHANGE"];
                return order.indexOf(a.orderType) - order.indexOf(b.orderType);
            });
            setOrderKPI({...response.data, kpis: sortedKpis});
            return response.data;
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const now = new Date();
        setDateFilter({fromDate: setStartOfTheDate(now), toDate: setEndOfTheDate(now)});
        const fetchData = async () => {
            await fetchOrderKPIs();
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    const createOrderFilter = (filter = undefined, orderType = undefined, goldType = undefined) => {
        return {
            ...(filter && {...filter}),
            ...(orderType && {orderType: orderType}),
            ...(goldType && {goldType: goldType}),
        };
    };


    const onMarkChanged = async (mark) => {
        const filter = {fromDate: mark.fromDate, toDate: mark.toDate};
        await fetchOrderKPIs(filter);
        setDateFilter(filter);
        setFilterLabel(mark.text);
    }

    const eastIconClick = (columnName, fromDate, toDate, orderType, goldType) => {
        const filter = createOrderFilter({fromDate: fromDate, toDate: toDate}, orderType, goldType);
        navigate(ROUTE_ORDER_MGMT, {state: {filter: filter}});
    }

    return (
        <>
            <KpiTitle
                label="Mua bán"
                showSlider={true}
                onMarkSelected={onMarkChanged}
            ></KpiTitle>
            {loading ? (
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            ) : (
                <>
                    <Typography variant="overline" display="block">
                        Đơn mới {filterLabel}: {orderKPI.totalOrderCount} | {formatCurrency(orderKPI.totalOrderAmount)}
                        <EastIcon color="primary" sx={{fontSize: 15, cursor: 'pointer'}}
                                  onClick={() => eastIconClick("orderDate", dateFilter.fromDate, dateFilter.toDate)}/>
                    </Typography>
                    {orderKPI.kpis.map((kpiGroup) => (
                        <div key={randomId()}>
                            <Typography variant="overline" display="block" sx={{mt: 1}}>
                                {getOrderTypeByCode(kpiGroup.orderType).label}(Tổng {kpiGroup.totalCount} đơn
                                | {formatCurrency(kpiGroup.totalAmount)})
                            </Typography>
                            {kpiGroup.kpiList.map((kpi) => (
                                <div key={randomId()} style={{paddingLeft: '1rem'}}>
                                    <Typography variant="overline" display="block">
                                        {getGoldTypeByCode(kpi.goldType).label}: {kpi.totalCount} đơn
                                        | {kpi.orderType !== 'EXCHANGE' && (
                                        <> | {readGoldWeightUnits(kpi.totalWeight)}</>
                                    )} | {formatCurrency(kpi.totalAmount)}
                                        <EastIcon color="primary" sx={{fontSize: 15, cursor: 'pointer'}}
                                                  onClick={() => eastIconClick("orderDate", dateFilter.fromDate, dateFilter.toDate, kpi.orderType, kpi.goldType)}/>
                                    </Typography>
                                    {kpi.orderType === 'EXCHANGE' && (
                                        <div style={{paddingLeft: '1rem'}}>
                                            <Typography variant="overline" display="block">
                                                Bán: {formatCurrency(kpi.sellAmount)} | {readGoldWeightUnits(kpi.sellWeight)}
                                            </Typography>
                                            <Typography variant="overline" display="block">
                                                Mua: {formatCurrency(kpi.buyAmount)} | {readGoldWeightUnits(kpi.buyWeight)}
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </>
            )}
            <Box component="section" sx={{display: 'flex', justifyContent: 'flex-end', position: 'relative', mt: 2}}>
                <Link color="primary" sx={{cursor: 'pointer'}} onClick={() => navigate(ROUTE_ORDER_MGMT)}>
                    Xem thêm
                </Link>
            </Box>
        </>
    );
};

export default OrderKpiSection;
