import React from 'react';
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CenteredMessageLayout from "../common/CenteredMessageLayout";
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import {ROUTE_DASHBOARD} from "../../utils/constants";

const NotAuthorized = () => {
    const handleSubmit = async (event) => {
        window.location.href = ROUTE_DASHBOARD;
    };
    return (
        <CenteredMessageLayout>
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <PhonelinkLockIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Bạn không có quyền truy cập trang!
            </Typography>
            <Box sx={{mt: 1}}>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    onClick={(event) => handleSubmit(event)}
                >
                    Về trang chủ
                </Button>
            </Box>
        </CenteredMessageLayout>
    );
};

export default NotAuthorized;


