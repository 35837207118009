import {ListItem, ListItemButton, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from '@mui/icons-material/Logout';
import {usePermissions} from "../../providers/PermissionProvider";
import {hasPermission} from "../../utils/auth";
import {useAuth} from "../../providers/AuthProvider";
import PropTypes from "prop-types";

const NavigationMenu = ({menuItems, onDrawerToggle: handleDrawerToggle, onLogout: handleLogout}) => {
    const permissions = usePermissions();
    const isAuthenticated = useAuth();
    return (
        <>
            {isAuthenticated && menuItems.filter(item => hasPermission(permissions, item.path)).map((item) => (
                <ListItem button key={item.label} onClick={handleDrawerToggle} component={Link}
                          to={item.path} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label}/>
                    </ListItemButton>
                </ListItem>
            ))}
            {isAuthenticated && (
                <ListItem button key="logout" color="inherit" onClick={handleLogout} component={Link}
                          to="#" disablePadding>
                    <ListItemButton>
                        <ListItemIcon> <Logout/>
                        </ListItemIcon>
                        <ListItemText primary="Thoát"/>
                    </ListItemButton>
                </ListItem>
            )
            }
        </>
    )
        ;
}
NavigationMenu.propTypes = {
    menuItems: PropTypes.array.isRequired,
    onDrawerToggle: PropTypes.func,
    onLogout: PropTypes.func,
};

export default NavigationMenu;
