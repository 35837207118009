import React, {useState} from "react";
import {Box, Button, Dialog, DialogTitle, Grid, TextField,} from "@mui/material";
import axios from '../../services/axiosInstance';
import {PAWNS_API_ENDPOINT} from "./PawnConstants";
import {handleApiError, handleSuccessMessage} from "../../utils/constants";
import {useAlert} from "../../providers/AlertProvider";
import PropTypes from "prop-types";

const CancelPawn = ({open, onClose, selectedPawnId}) => {
    const {openAlert} = useAlert();
    const [cancelReason, setCancelReason] = useState("");
    const handleCancelPawn = async () => {
        try {
            await axios.patch(`${PAWNS_API_ENDPOINT}/${selectedPawnId}/cancel`, cancelReason, {
                headers: {
                    'Content-Type': 'text/plain',
                }
            })
            handleSuccessMessage('Đơn cầm [' + selectedPawnId + '] được hủy thành công', openAlert);
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            onClose();
        }
    };
    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    {`Hủy đơn số: ${selectedPawnId}?`}
                </DialogTitle>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Lý do hủy"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                value={cancelReason}
                                onChange={(e) => setCancelReason(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box p={2} display="flex" justifyContent="space-between">
                    <Button onClick={onClose} color="primary" variant="contained">
                        Không
                    </Button>
                    <Button onClick={handleCancelPawn} color="secondary" variant="contained">
                        Hủy đơn
                    </Button>
                </Box>
            </Dialog>
        </div>
    );
}
CancelPawn.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedPawnId: PropTypes.number.isRequired
}
export default CancelPawn;
