import React, {useEffect, useState} from "react";
import {Grid, TextField} from "@mui/material";
import {connect} from "react-redux";
import {updateUserRequest} from "./userThunks";
import validator from 'validator'
import {getResponsiveWidth, handleApiError, handleSuccessMessage} from "../../utils/constants";
import {useAlert} from "../../providers/AlertProvider";
import CustomDialog from "../../components/dialog/CustomDialog";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import PropTypes from "prop-types";

const UpdateUser = ({userData, onUpdatePressed, open, onClose}) => {
    const {openAlert} = useAlert();
    const [user, setUser] = useState(userData);
    const [nameValid, setNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    useEffect(() => {
        setUser(userData);
    }, [open, userData])
    const onUpdateUserClick = async () => {
        let validated = true;
        if (user.email.trim() !== '' && !validator.isEmail(user.email)) {
            setEmailValid(false);
            validated = false;
        } else {
            setEmailValid(true);
        }
        if (user.name.trim() === "") {
            setNameValid(false);
            validated = false;
        } else {
            setNameValid(true);
        }

        if (validated) {
            try {
                const response = await onUpdatePressed(user.username, user);
                handleSuccessMessage('Cập nhật [' + response.username + '] thành công', openAlert);
                onClose(response);
            } catch (error) {
                handleApiError(error, openAlert);
            }
        }
    };
    const handleClose = async () => {
        onClose();
    };

    const formActions = (
        <CustomLoadingButton variant="contained" onClick={onUpdateUserClick}
                             sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu thay đổi"/>
    );
    const pageTitle = (
        <>
            {`Sửa thông tin người dùng: ${user.username}`}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Họ và tên"
                            value={user.name}
                            onChange={(e) => {
                                setUser((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                }));
                                setNameValid(true);
                            }}
                            inputProps={{maxLength: 200}}
                            fullWidth
                            error={!nameValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Địa chỉ email"
                            value={user.email ? user.email : ''}
                            onChange={(e) => {
                                setUser((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                }));
                                setEmailValid(true);
                            }}
                            inputProps={{maxLength: 200}}
                            fullWidth
                            error={!emailValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Tên đăng nhập"
                            value={user.username}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
        </div>
    );
}

const mapStateToProps = state => ({
    users: state.users,
});

const mapDispatchToProps = dispatch => ({
    onUpdatePressed: (username, user) => dispatch(updateUserRequest(username, user)),
});

UpdateUser.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userData: PropTypes.object.isRequired,
    onUpdatePressed: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser)
