import React, {useEffect, useState} from "react";
import {vi_locale} from 'dayjs/locale/vi';
import axios from '../../services/axiosInstance';
import {Box, Grid, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {getPawnDays, InterestTypes, mapPawnStatusToText, PAWNS_API_ENDPOINT} from "./PawnConstants";
import {
    calculateGoldWeight,
    calculateTotalWeight,
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    formatCustomerName,
    getGoldPrice,
    getResponsiveWidth,
    getSortedGoldBrand,
    getSortedGoldType,
    GoldType_Unknown,
    handleApiError,
    handleSuccessMessage,
    setUserTime
} from "../../utils/constants";
import RequestMoneyView from "./RequestMoneyView";
import dayjs from "dayjs";
import {handlePrinting} from "./PawnStamp";
import {useAlert} from "../../providers/AlertProvider";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CustomDialog from "../../components/dialog/CustomDialog";
import PropTypes from "prop-types";

const UpdatePawn = ({open, onClose, sourcePawn}) => {
    const {openAlert} = useAlert();
    const [pawnData, setPawnData] = useState(sourcePawn);
    const [itemNameValid, setItemNameValid] = useState(true);
    const [itemWeightValid, setItemWeightValid] = useState(true);
    const [pawnAmountValid, setPawnAmountValid] = useState(true);
    useEffect(() => {
        setPawnData(sourcePawn);
        setPawnData((prev) => ({
            ...prev,
            totalWeight: calculateTotalWeight(prev.itemWeight, prev.gemWeight),
            deletedRequestIds: []
        }));
    }, [open, sourcePawn])
    const handleSaveButton = async () => {
        let validated = true;
        if (pawnData.itemWeight <= 0) {
            setItemWeightValid(false);
            validated = false;
        } else {
            setItemWeightValid(true);
        }
        if (pawnData.pawnAmount === 0) {
            setPawnAmountValid(false);
            validated = false;
        } else {
            setPawnAmountValid(true);
        }

        if (pawnData.itemName.trim() === "") {
            setItemNameValid(false);
            validated = false;
        } else {
            setItemNameValid(true);
        }

        if (validated) {
            const savedPawn = await onUpdatePawnData(pawnData);
            setItemNameValid(true);
            setItemWeightValid(true);
            setPawnAmountValid(true);
            return savedPawn;
        }
    };
    const onUpdatePawnData = async (pawnData) => {
        try {
            const requestBody = {
                ...pawnData,
                requestType: 'UPDATED',
            };
            const response = await axios.put(`${PAWNS_API_ENDPOINT}/${pawnData.pawnId}`, requestBody);
            handleSuccessMessage('Đơn cầm [' + response.data.pawnId + '] lưu thành công', openAlert);
            onClose(response.data);
            return response.data;
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const calculateItemValue = (itemWeight = 0, itemType = GoldType_Unknown.code) => {
        const priceSelected = Object.values(getGoldPrice()).find(
            (price) => price.code === itemType
        );
        if (priceSelected && itemWeight !== 0) {
            setPawnData((prev) => ({
                ...prev,
                itemValue: priceSelected?.pawn * prev.itemWeight,
            }));
        }
    };
    const calculatePawnDueDate = (pawnDate = dayjs(new Date())) => {
        const pawnDueDate = convertDayjsDate(pawnDate).add(getPawnDays(), 'day').toDate();
        setPawnData((prev) => ({
            ...prev,
            pawnDueDate: convertDayjsDate(pawnDueDate)
        }));
    }
    const handleRequestMoneyDelete = (deletedRequestId) => {
        setPawnData((prevPawnData) => {
            const updatedDeletedRequestIds = [...prevPawnData.deletedRequestIds, deletedRequestId];
            return {...prevPawnData, deletedRequestIds: updatedDeletedRequestIds};
        });
    };
    const handleSavePrintButton = async () => {
        const savedPawn = await handleSaveButton();
        if (savedPawn) {
            await handlePrinting({
                ...savedPawn,
                pawnDate: convertDayjsDate(savedPawn.pawnDate),
                pawnDueDate: convertDayjsDate(savedPawn.pawnDueDate),
                customerName: formatCustomerName(savedPawn.customer),
            });
        }
    }
    const handleClose = async () => {
        onClose();
    };

    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleSavePrintButton}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu và in"/>
            <CustomLoadingButton variant="contained" onClick={handleSaveButton}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Chỉ Lưu"/>
        </>
    );
    const pageTitle = (
        <>
            {`Cập nhật đơn số: ${pawnData.pawnId}`}
            <br/>Tình trạng: {mapPawnStatusToText(pawnData.pawnStatus)}
        </>
    );
    const weightChanges = (totalWeight = 0, gemWeight = 0) => {
        const goldWeight = calculateGoldWeight(totalWeight, gemWeight);
        setPawnData((prev) => ({
            ...prev,
            itemWeight: goldWeight,
        }));
        calculateItemValue(goldWeight, pawnData.itemType);
    };
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <TextField
                                    label="Tên khách hàng"
                                    disabled
                                    value={pawnData.customerName}
                                    fullWidth
                                />
                            </Stack>
                        < /Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField
                                label="Tên món hàng"
                                value={pawnData.itemName}
                                onChange={(e) => {
                                    setPawnData((prev) => ({
                                        ...prev,
                                        itemName: e.target.value,
                                    }));
                                    setItemNameValid(true)
                                }}
                                fullWidth
                                error={!itemNameValid}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <TextField
                                label="Trọng lượng tổng"
                                value={pawnData.totalWeight}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                    setPawnData((prev) => ({
                                        ...prev,
                                        totalWeight: numericInput,
                                    }));
                                    weightChanges(numericInput, pawnData.gemWeight);
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),
                                    inputMode: 'decimal',
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <TextField
                                label="Trọng lượng hột"
                                value={pawnData.gemWeight}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                    setPawnData((prev) => ({
                                        ...prev,
                                        gemWeight: numericInput,
                                    }));
                                    weightChanges(pawnData.totalWeight, numericInput);
                                }}
                                fullWidth
                                InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField
                                label="Trọng lượng VÀNG"
                                value={pawnData.itemWeight}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                    setPawnData((prev) => ({
                                        ...prev,
                                        itemWeight: numericInput,
                                    }));
                                    setItemWeightValid(true);
                                    calculateItemValue(numericInput, pawnData.itemType);
                                }}
                                fullWidth
                                InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                                error={!itemWeightValid}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <TextField
                                label="Số tiền cầm"
                                value={Number(pawnData.pawnAmount).toLocaleString()}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/\D/g, '');
                                    setPawnData((prev) => ({
                                        ...prev,
                                        pawnAmount: numericInput,
                                    }));
                                    setPawnAmountValid(true);
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                                error={!pawnAmountValid}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <Select
                                value={pawnData.itemBrand}
                                fullWidth
                                MenuProps={{
                                    disableAutoFocusItem: true,
                                }}
                                onChange={(e) =>
                                    setPawnData((prev) => ({
                                        ...prev,
                                        itemBrand: e.target.value,
                                    }))
                                }
                            >
                                {getSortedGoldBrand().map((goldBrand) => (
                                    <MenuItem key={goldBrand.code} value={goldBrand.code}>
                                        {goldBrand.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={getResponsiveWidth()}>
                            <Select
                                value={pawnData.itemType}
                                fullWidth
                                MenuProps={{
                                    disableAutoFocusItem: true,
                                }}
                                onChange={(e) => {
                                    calculateItemValue(pawnData.itemWeight, e.target.value);
                                    setPawnData((prev) => ({
                                        ...prev,
                                        itemType: e.target.value,
                                    }));
                                }}
                            >
                                {getSortedGoldType().map((goldType) => (
                                    <MenuItem key={goldType.code} value={goldType.code}>
                                        {goldType.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <TextField
                                label="Số tiền cầm tối đa"
                                value={Number(pawnData.itemValue).toLocaleString()}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/\D/g, '');
                                    setPawnData((prev) => ({
                                        ...prev,
                                        itemValue: numericInput,
                                    }));
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                <DatePicker label="Ngày cầm"
                                            value={pawnData.pawnDate}
                                            onChange={(date) => {
                                                const selectedDate = setUserTime(date);
                                                setPawnData((prev) => ({
                                                    ...prev,
                                                    pawnDate: selectedDate,
                                                }));
                                                calculatePawnDueDate(selectedDate);
                                            }}
                                            fullWidth
                                            format={DATE_SHORT_FORMAT}
                                            slotProps={{
                                                field: {shouldRespectLeadingZeros: true},
                                                textField: {fullWidth: true}
                                            }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                <DatePicker label="Ngày đến hạn"
                                            value={pawnData.pawnDueDate}
                                            onChange={(date) => setPawnData((prev) => ({
                                                ...prev,
                                                pawnDueDate: setUserTime(date),
                                            }))}
                                            fullWidth
                                            format={DATE_SHORT_FORMAT}
                                            slotProps={{
                                                field: {shouldRespectLeadingZeros: true},
                                                textField: {fullWidth: true}
                                            }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <TextField
                                label="Lãi suất"
                                value={pawnData.interestRate}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                    setPawnData((prev) => ({
                                        ...prev,
                                        interestRate: numericInput,
                                    }))
                                }}
                                fullWidth
                                InputProps={{endAdornment: (<InputAdornment position="end">%</InputAdornment>),}}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <Select
                                value={pawnData.interestDaysPerMonth}
                                fullWidth
                                onChange={(e) => {
                                    setPawnData((prev) => ({
                                        ...prev,
                                        interestDaysPerMonth: e.target.value,
                                    }));
                                }}
                            >
                                {Object.values(InterestTypes).map((interestType) => (
                                    <MenuItem key={interestType.code} value={interestType.code}>
                                        {interestType.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Ghi chú"
                                value={pawnData.itemDescription}
                                onChange={(e) =>
                                    setPawnData((prev) => ({
                                        ...prev,
                                        itemDescription: e.target.value,
                                    }))
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RequestMoneyView reqMoneyData={pawnData.reqMoneys} editable={true}
                                              onDelete={handleRequestMoneyDelete}/>
                        </Grid>
                    </Grid>
                </Box>
            </CustomDialog>
        </div>
    )
};
UpdatePawn.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sourcePawn: PropTypes.object.isRequired
};
export default UpdatePawn;

