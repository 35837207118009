import React, {useEffect, useState} from "react";
import {DEFAULT_JEWELRY_DATA, JEWELRY_API_ENDPOINT} from "./JewelryConstants";
import {Autocomplete, Grid, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import axios from '../../services/axiosInstance';
import {
    calculateGoldWeight,
    getCounters,
    getResponsiveWidth,
    getSortedGoldBrand,
    getSortedGoldType,
    getTopProducts,
    handleApiError,
    handleSuccessMessage,
    isNumber,
    validateField,
    validateFieldCondition,
    validateNumberField
} from "../../utils/constants";
import {isMobile} from "react-device-detect";
import {useAlert} from "../../providers/AlertProvider";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CustomDialog from "../../components/dialog/CustomDialog";
import PropTypes from "prop-types";


const UpdateJewelry = ({open, onClose, sourceJewelry}) => {
    const {openAlert} = useAlert();
    const [jewelryData, setJewelryData] = useState(sourceJewelry);
    const [itemNameValid, setItemNameValid] = useState(true);
    const [goldWeightValid, setGoldWeightValid] = useState(true);
    const [gemWeightValid, setGemWeightValid] = useState(true);
    const [priceValid, setPriceValid] = useState(true);
    const [procPriceValid, setProcPriceValid] = useState(true);
    const [sellProcPriceValid, setSellProcPriceValid] = useState(true);
    const [exchangeProcPriceValid, setExchangeProcPriceValid] = useState(true);

    useEffect(() => {
        setJewelryData(sourceJewelry);
    }, [open, sourceJewelry]);
    const handleSave = async () => {
        let validated = true;
        validated &= validateField(jewelryData.name, setItemNameValid);
        validated &= validateNumberField(jewelryData.goldWeight, setGoldWeightValid, 0);
        validated &= validateFieldCondition(jewelryData.gemWeight, setGemWeightValid, isNumber);
        validated &= validateFieldCondition(jewelryData.price, setPriceValid, isNumber);
        validated &= validateFieldCondition(jewelryData.procPrice, setProcPriceValid, isNumber);
        validated &= validateFieldCondition(jewelryData.sellProcPrice, setSellProcPriceValid, isNumber);
        validated &= validateFieldCondition(jewelryData.exchangeProcPrice, setExchangeProcPriceValid, isNumber);
        if (validated) {
            await onSave(jewelryData);
            setJewelryData(DEFAULT_JEWELRY_DATA);
        }
    };

    const onSave = async (jewelryData) => {
        try {
            const response = await axios.put(`${JEWELRY_API_ENDPOINT}/${jewelryData.jewelryId}`, jewelryData);
            handleSuccessMessage('Lưu thành công sản phẩm [' + response.data.jewelryId + ']', openAlert);
            onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };

    const weightChanges = (totalWeight = 0, gemWeight = 0) => {
        const goldWeight = calculateGoldWeight(totalWeight, gemWeight);
        setJewelryData((prev) => ({
            ...prev,
            goldWeight: goldWeight,
        }));
        goldPriceChanges(goldWeight, jewelryData.goldPrice);
    };


    const goldPriceChanges = (goldWeight = 0, goldPrice = 0) => {
        let price = parseFloat(goldWeight) * parseInt(goldPrice);
        if (isNaN(price)) {
            price = 0;
        }
        setJewelryData((prev) => ({
            ...prev,
            price: price,
        }));

        priceChanges(price, jewelryData.procPrice);
    }
    const priceChanges = (price = 0, procPrice = 0) => {
        let totalPrice = parseFloat(price) + parseFloat(procPrice);
        if (isNaN(totalPrice)) {
            totalPrice = 0;
        }
        setJewelryData((prev) => ({
            ...prev,
            totalPrice: totalPrice,
        }));
    };
    const handleProductSelect = (event, selectedValue) => {
        setJewelryData((prevData) => ({
            ...prevData,
            name: selectedValue,
        }));
    };

    const handleClose = async () => {
        onClose();
    };
    const formActions = (
        <CustomLoadingButton variant="contained" onClick={handleSave}
                             sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu thay đổi"/>
    );
    const pageTitle = (
        <>
            {`Cập nhật sản phẩm: ${jewelryData.jewelryId}`}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <Autocomplete
                            freeSolo
                            value={jewelryData.name}
                            onChange={handleProductSelect}
                            options={getTopProducts().map((option) => option)}
                            renderInput={(params) =>
                                <TextField {...params}
                                           value={jewelryData.name}
                                           onChange={(e) => {
                                               setJewelryData((prev) => ({
                                                   ...prev,
                                                   name: e.target.value,
                                               }));
                                               setItemNameValid(true)
                                           }}
                                           label="Tên món hàng"
                                           InputProps={{
                                               ...params.InputProps,
                                               type: 'search',
                                           }}
                                           error={!itemNameValid}
                                />}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <Select
                            value={jewelryData.goldType}
                            fullWidth
                            MenuProps={{
                                disableAutoFocusItem: true,
                            }}
                            onChange={(e) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    goldType: e.target.value,
                                }));
                            }}
                        >
                            {getSortedGoldType().map((goldType) => (
                                <MenuItem key={goldType.code} value={goldType.code}>
                                    {goldType.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <Select
                            value={jewelryData.brand}
                            fullWidth
                            MenuProps={{
                                disableAutoFocusItem: true,
                            }}
                            onChange={(e) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    brand: e.target.value,
                                }));
                            }}
                        >
                            {getSortedGoldBrand().map((goldBrand) => (
                                <MenuItem key={goldBrand.code} value={goldBrand.code}>
                                    {goldBrand.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <Select
                            value={jewelryData.counterId}
                            fullWidth
                            MenuProps={{
                                disableAutoFocusItem: true,
                            }}
                            onChange={(e) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    counterId: e.target.value,
                                }));
                            }}
                        >
                            {Object.values(getCounters()).map((counter) => (
                                <MenuItem key={counter.code} value={counter.code}>
                                    {counter.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <TextField
                            label="Ký hiệu"
                            value={jewelryData.symbol ? jewelryData.symbol : ''}
                            onChange={(e) => {
                                setJewelryData((prev) => ({
                                    ...prev,
                                    symbol: e.target.value,
                                }))
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label={(isMobile) ? "TL tổng" : "Trọng lượng tổng"}
                            value={jewelryData.totalWeight}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    totalWeight: numericInput,
                                }));
                                weightChanges(numericInput, jewelryData.gemWeight);
                            }}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <TextField
                            label={(isMobile) ? "TL hột" : "Trọng lượng hột"}
                            value={jewelryData.gemWeight}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    gemWeight: numericInput,
                                }));
                                weightChanges(jewelryData.totalWeight, numericInput);
                            }}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                            error={!gemWeightValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <TextField
                            label={(isMobile) ? "TL vàng" : "Trọng lượng vàng"}
                            readOnly disabled
                            value={jewelryData.goldWeight}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    goldWeight: numericInput,
                                }));
                                setGoldWeightValid(true);
                            }}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                            error={!goldWeightValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <TextField
                            label="Số lượng" readOnly disabled
                            value={Number(jewelryData.quantity).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    quantity: numericInput,
                                }))
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <TextField
                            label="Giá vàng"
                            value={Number(jewelryData.goldPrice).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    goldPrice: numericInput,
                                }));
                                goldPriceChanges(jewelryData.goldWeight, numericInput);
                            }}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),}}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <TextField
                            label="Thành tiền(GV x TL vàng) " readOnly disabled
                            value={Number(jewelryData.price).toLocaleString()}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),}}
                            error={!priceValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 12)}>
                        <TextField
                            label="Giá công(nhập)"
                            value={Number(jewelryData.procPrice).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    procPrice: numericInput,
                                }));
                                priceChanges(jewelryData.price, numericInput);
                            }}
                            fullWidth
                            InputProps={{endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),}}
                            error={!procPriceValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField readOnly disabled
                                   label="Tổng giá nhập((GV * TL vàng) + Công)"
                                   value={Number(jewelryData.totalPrice).toLocaleString()}
                                   fullWidth
                                   InputProps={{
                                       endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                                   }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Tiền công bán"
                            value={Number(jewelryData.sellProcPrice).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    sellProcPrice: numericInput,
                                }))
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">vnđ</InputAdornment>
                                ),
                            }}
                            error={!sellProcPriceValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Tiền công đổi"
                            value={Number(jewelryData.exchangeProcPrice).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    exchangeProcPrice: numericInput,
                                }))
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">vnđ</InputAdornment>
                                ),
                            }}
                            error={!exchangeProcPriceValid}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mô tả thêm"
                            value={jewelryData.description}
                            onChange={(e) =>
                                setJewelryData((prev) => ({
                                    ...prev,
                                    description: e.target.value,
                                }))
                            }
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
        </div>
    )
};
UpdateJewelry.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    sourceJewelry: PropTypes.object.isRequired
}
export default UpdateJewelry;
