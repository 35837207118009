import React from 'react';
import {Box, Container, CssBaseline, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import PropTypes from 'prop-types';
import Copyright from "../apps/Copyright";

const defaultTheme = createTheme();

const CenteredMessageLayout = ({children}) => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" sx={{minHeight: '90vh', display: 'flex', flexDirection: 'column'}}>
                <CssBaseline/>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1,
                    }}
                >
                    {children}
                </Box>
                <Box sx={{mt: 8, mb: 4}}>
                    <Copyright/>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

CenteredMessageLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default CenteredMessageLayout;
