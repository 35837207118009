import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import SystemAlert from "../components/dialog/SystemAlert";
import PropTypes from "prop-types";

const AlertContext = createContext();
export const AlertProvider = ({children}) => {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');

    const openAlert = useCallback((severity, message) => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }, []);

    const closeAlert = useCallback(() => {
        setAlertOpen(false);
    }, []);
    const alertContextValue = useMemo(() => ({
        openAlert,
        closeAlert,
    }), [openAlert, closeAlert]);

    return (
        <AlertContext.Provider value={alertContextValue}>
            {children}
            <SystemAlert
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                onClose={closeAlert}
            />
        </AlertContext.Provider>
    );
};

AlertProvider.propTypes = {
    children: PropTypes.element,
}
export const useAlert = () => {
    return useContext(AlertContext);
};
