import {findGoldBrandNameByCode, getShopInfo, readGoldWeightStamp} from "../../utils/constants";
import JsBarcode from "jsbarcode";

export const handleSilverPrinting16x30 = async (jewelryData = {}) => {
    const printWindow = window.open('', '_blank');
    const shopInfo = getShopInfo();
    const brand = findGoldBrandNameByCode(jewelryData.brand);
    printWindow.document.write(`
          <html lang="en-US">
              <head>
                <link rel="icon" href="/logo.png">
                <style>
                    .jewelry-stamp {
                        width: 61mm;
                        height: 15mm;
                        /*white-space: nowrap;*/
                        /*border: 1px solid #000;*/
                        display: flex;
                        justify-content: space-between;
                        font-family: Tahoma, sans-serif;
                        font-size: 5pt;
                        padding-top: 1px;
                        line-height: 1.2em;
                    }
                    
                    .column {
                        width: 49.5%;
                    }
                    
                    .left-block {
                        width: 110px;
                        padding-right: 1px;
                        /*border: 1px solid #000;*/
                    }
                    .right-block {
                        width: 115px;
                        /*border: 1px solid #000;*/
                    }
                    
                    .col-full-width {
                        width: 100%
                    }
                    
                    .col-half-width {
                        width: 100%
                    }
                    
                    .col-with-col {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    .f-bold {
                        font-weight: bold;
                    }
                    .t-right {
                        text-align: right;
                    }
                    .t-center {
                        text-align: center;
                    }
                    .f-shop-name {
                        text-align: center;
                        font-size: 5pt !important;
                        font-weight: bold;
                    }
                    .f-name {
                        font-size: 5.5pt !important;
                        font-weight: bold;
                    }
                    .f-gold-weight {
                        font-size: 6pt !important;
                        font-weight: bold;
                    }
                    .content-overflow {
                        white-space: nowrap; 
                        overflow: hidden; 
                        text-overflow: ellipsis;
                    }
                    @media print {
                        body {
                            margin: 0; /* Remove body margin */
                        }
                   
                        @page {
                            size: 62mm 17mm; /* 4.1cm x 1cm */
                            margin: 0; /* Remove any default margins */
                        }
                    }
                    svg {
                      height:8px;
                      width: 30px;
                    }
                </style>
                <title>In tem [${jewelryData.name}]</title>
              </head>
              <body>
                <div class="jewelry-stamp">
                    <div class="left-block">
                        <div class="col-with-col">
                            <div class="col-half-width f-name"><span>${jewelryData.name}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width">${!jewelryData.sellByItem ? 'Khối lượng tổng:' + readGoldWeightStamp(jewelryData.totalWeight) : 'Bán theo món'}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width"><span>${!jewelryData.sellByItem ? 'Khối lượng hột:' + readGoldWeightStamp(jewelryData.gemWeight) : ''}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div class="col-half-width f-gold-weight"><span>${!jewelryData.sellByItem ? 'Khối lượng bạc:' + readGoldWeightStamp(jewelryData.goldWeight) : 'Giá bán: ' + Number(jewelryData.sellProcPrice).toLocaleString() + 'đ'}</span></div>
                        </div>
                        <div class="col-with-col">
                            <div style="width: 60% !important;">
                                <div class="col-half-width"><span>${!jewelryData.sellByItem ? 'Công bán:' + Number(jewelryData.sellProcPrice).toLocaleString() + 'đ' : ''}</span></div>
                                <div class="col-half-width"><span>${jewelryData.exchangeProcPrice ? 'Công đổi:' + Number(jewelryData.exchangeProcPrice).toLocaleString() + 'đ' : ''}</span></div>
                                <div class="col-half-width"><span>Hàm lượng bạc:${jewelryData.goldTypeName}</span></div>
                            </div>
                            <div class="t-right" style="width: 40% !important; padding-right: 2px;">
                                <div class="col-half-width f-bold"><span>${jewelryData.jewelryId}</span></div>
                                <div class="col-half-width"><svg id="barcode-container" width="100%" height="100%"></svg></div>
                                <div class="col-half-width"><span>Ký hiệu:${jewelryData.symbol}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="right-block">
                      <div class="col-full-width f-shop-name"><span>${shopInfo.shortName}</span></div>
                      <div class="col-full-width"><span>Địa chỉ: ${shopInfo.shortAddress}</span></div>
                      <div class="col-with-col"><span>NSX: ${brand.shortName} - Địa chỉ: ${brand.address ? brand.address : ''}</span></div>
                      <div class="col-with-col">
                            <div class="col-half-width"><span>TCCS:${brand.pubStand}</span></div>
                            <div class="col-half-width t-right"><span>Xuất xứ:${brand.origin}</span></div>
                        </div>
                    </div>
                </div>
              </body>
          </html>
        `);
    JsBarcode(printWindow.document.getElementById("barcode-container"), `${jewelryData.jewelryId}`, {
        displayValue: false,
        width: 3,
        height: 50,
        margin: 0,
    });
    printWindow.document.close();
    printWindow.print();
}

