import CustomDialog from "../../components/dialog/CustomDialog";
import {Grid, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import {getResponsiveWidth} from "../../utils/constants";
import PropTypes from "prop-types";

const GoldPriceDetails = ({open, onClose, sourceData, onItemUpdated}) => {
    const [goldPrice, setGoldPrice] = useState(sourceData);
    const [sellValid, setSellValid] = useState(true);
    const [pawnValid, setPawnValid] = useState(true);
    const [buyValid, setBuyValid] = useState(true);
    useEffect(() => {
        setGoldPrice(sourceData);
    }, [open, sourceData]);
    const handleClose = async () => {
        onClose();
    };
    const handleUpdatedItem = async () => {
        let validated = true;
        if (isNaN(goldPrice.pawn) || parseInt(goldPrice.pawn) <= 0) {
            setPawnValid(false);
            validated = false;
        } else {
            setPawnValid(true);
        }
        if (isNaN(goldPrice.sell) || parseInt(goldPrice.sell <= 0)) {
            setSellValid(false);
            validated = false;
        } else {
            setSellValid(true);
        }
        if (isNaN(goldPrice.buy) || parseInt(goldPrice.buy <= 0)) {
            setBuyValid(false);
            validated = false;
        } else {
            setBuyValid(true);
        }
        if (validated) {
            return onItemUpdated(goldPrice);
        }
    }

    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleUpdatedItem}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu thay đổi"/>
            <CustomLoadingButton variant="contained" onClick={handleClose}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Hủy"/>
        </>
    );
    const pageTitle = (
        <>
            {`Sửa giá [${goldPrice.label}]`}
        </>
    );
    return (
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Mã số"
                            value={goldPrice.id ? goldPrice.id : 0}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Loại vàng"
                            value={goldPrice.label ? goldPrice.label : ''}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Giá mua vào"
                            value={Number(goldPrice.buy).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setGoldPrice((prev) => ({
                                    ...prev,
                                    buy: numericInput,
                                }));
                                setBuyValid(true);
                            }}
                            fullWidth
                            autoFocus
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">vnđ/chỉ</InputAdornment>
                                ),
                                type: 'search'
                            }}
                            error={!buyValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Giá bán ra"
                            value={Number(goldPrice.sell).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setGoldPrice((prev) => ({
                                    ...prev,
                                    sell: numericInput,
                                }));
                                setSellValid(true);
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">vnđ/chỉ</InputAdornment>
                                ),
                                type: 'search'
                            }}
                            error={!sellValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Giá cầm đồ"
                            value={Number(goldPrice.pawn).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setGoldPrice((prev) => ({
                                    ...prev,
                                    pawn: numericInput,
                                }));
                                setPawnValid(true);
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">vnđ/chỉ</InputAdornment>
                                ),
                                type: 'search'
                            }}
                            error={!pawnValid}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <Select
                            value={goldPrice.showInBoard ? "YES" : "NO"}
                            fullWidth
                            onChange={(e) => {
                                const flag = (e.target.value === "YES");
                                setGoldPrice((prev) => ({
                                    ...prev,
                                    showInBoard: flag,
                                }));
                            }}
                        >
                            <MenuItem value="YES">Hiện thị ở bảng giá</MenuItem>
                            <MenuItem value="NO">Không hiển thị ở bảng giá</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </CustomDialog>
    );
};
GoldPriceDetails.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sourceData: PropTypes.object.isRequired,
    onItemUpdated: PropTypes.func.isRequired
};
export default GoldPriceDetails;
