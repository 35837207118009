import {DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbar} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {Box, LinearProgress} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
    convertDayjsDate,
    DATE_LONG_FORMAT,
    DEFAULT_ERROR_MESSAGE,
    GoldBrand_Unknown,
    handleErrorMessage,
    handleSuccessMessage,
    SYSTEMS_API_ENDPOINT
} from "../../utils/constants";
import axios from '../../services/axiosInstance';
import CustomNoRowsOverlay from "../../components/grid/CustomNoRowsOverlay";
import {useAlert} from "../../providers/AlertProvider";
import GoldBrandDetails from "./GoldBrandDetails";
import {usePermissions} from "../../providers/PermissionProvider";
import PropTypes from "prop-types";
import {randomId} from "@mui/x-data-grid-generator";

const GoldBrandGrid = ({sourceBrands = [], loading = false, onUpdateCompleted}) => {
    const permissions = usePermissions();
    const {openAlert} = useAlert();
    const [brands, setBrands] = useState(sourceBrands)
    const [rowModelModes, setRowModelModes] = useState({});
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [editData, setEditData] = useState({});
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleEditClick = (id) => () => {
        const editedRow = brands.find((row) => row.id === id);
        if (editedRow) {
            setEditData({
                ...editData,
                id: editedRow.id,
                code: editedRow.code,
                label: editedRow.label,
                name: editedRow.name,
                shortName: editedRow.shortName,
                pubStand: editedRow.pubStand,
                phone: editedRow.phone,
                note: editedRow.note,
                order: editedRow.order,
                origin: editedRow.origin,
                address: editedRow.address,
                silverBrand: editedRow.silverBrand
            });
            setOpenDetailsDialog(true);
        }
    };

    const handleSaveClick = (id) => () => {
        setRowModelModes({...rowModelModes, [id]: {mode: GridRowModes.View}});
    };

    const handleCancelClick = (id) => () => {
        setRowModelModes({
            ...rowModelModes,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = brands.find((row) => row.id === id);
        if (editedRow.isNew) {
            setBrands(brands.filter((row) => row.id !== id));
        }
    };
    const processUpdate = async (brand) => {
        try {
            await axios.put(`${SYSTEMS_API_ENDPOINT}/gold-brands/${brand.id}`, brand);
            handleSuccessMessage('Thông tin [' + brand.name + '] được lưu thành công', openAlert);
        } catch (error) {
            handleErrorMessage(DEFAULT_ERROR_MESSAGE, openAlert);
        } finally {
            await onUpdateCompleted();
        }
    };
    const handleItemUpdate = async (updatedRow) => {
        await processUpdate(updatedRow);
        setOpenDetailsDialog(false);
    }

    const processRowUpdate = async (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setBrands(brands.map((row) => (row.id === newRow.id ? updatedRow : row)));
        await processUpdate(updatedRow);
        return updatedRow;
    };

    const handleBuyItemRowModelChange = (newRowModesModel) => {
        setRowModelModes(newRowModesModel);
    };

    useEffect(() => {
        const items = sourceBrands.filter(item => item.code !== GoldBrand_Unknown.code)
        setBrands(items);
    }, [sourceBrands]);
    const COLUMNS = [
        {
            field: 'actions',
            type: 'actions',
            align: 'center',
            headerName: 'Tùy chọn',
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModelModes[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            key={randomId()}
                            label="Lưu"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            key={randomId()}
                            label="Hủy"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        key={randomId()}
                        label="Sửa"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {field: 'id', headerName: 'M.số', width: 90},
        {field: 'label', headerName: 'Hiển thị', width: 160, editable: true},
        {field: 'code', headerName: 'Ký hiệu', width: 80, editable: false},
        {field: 'name', headerName: 'Tên', width: 200, editable: true},
        {field: 'shortName', headerName: 'Viết tắt', width: 150, editable: true},
        {field: 'pubStand', headerName: 'TCCS', width: 100, editable: true},
        {field: 'origin', headerName: 'Xuất xứ', width: 100, editable: true},
        {field: 'order', headerName: 'Thứ tự', width: 90, type: 'number', editable: true},
        {field: 'phone', headerName: 'Số Đt', width: 100, editable: true},
        {field: 'address', headerName: 'Địa chỉ', width: 150, editable: true},
        {field: 'note', headerName: 'Ghi chú', width: 150, editable: true},
        {field: 'silverBrand', headerName: 'Chành bạc?', width: 150, editable: true},
        {field: 'createdBy', headerName: 'Người tạo', width: 100, editable: false},
        {
            field: 'createdAt', headerName: 'Ngày tạo', width: 100, editable: false,
            valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
        },
        {field: 'updatedBy', headerName: 'Người cập nhật', width: 100, editable: false},
        {
            field: 'updatedAt', headerName: 'Ngày cập nhật', width: 100, editable: false,
            valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
        },
    ];
    const getFilteredColumns = () => {
        if (permissions.SYSTEMS?.includes('UPDATE_GOLD_BRAND')) {
            return COLUMNS;
        }
        return COLUMNS.filter(column => column.field !== 'actions');
    };
    return (
        <div>
            <Box sx={{width: 1, overflow: "auto", height: 450}}>
                <DataGrid
                    autoHeight={false}
                    editMode="row"
                    rowModesModel={rowModelModes}
                    onRowModesModelChange={handleBuyItemRowModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnFilter
                    disableDensitySelector
                    slots={{
                        toolbar: GridToolbar,
                        loadingOverlay: LinearProgress,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        toolbar: {
                            csvOptions: {disableToolbarButton: true},
                            printOptions: {disableToolbarButton: true},
                            showQuickFilter: true,
                        },
                    }}
                    loading={loading}
                    rows={brands}
                    columns={getFilteredColumns()}
                />
            </Box>
            <GoldBrandDetails onClose={() => {
                setOpenDetailsDialog(false);
            }} open={openDetailsDialog} sourceData={editData} onItemUpdated={handleItemUpdate}/>
        </div>);
};
GoldBrandGrid.propTypes = {
    loading: PropTypes.bool,
    onUpdateCompleted: PropTypes.func,
    sourceBrands: PropTypes.array
};
export default GoldBrandGrid;
