import React, {useEffect, useState} from "react";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CustomDialog from "../../components/dialog/CustomDialog";
import {Grid, Switch, TextField} from "@mui/material";
import {getResponsiveWidth} from "../../utils/constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";


const GoldBrandDetails = ({open, onClose, sourceData, onItemUpdated}) => {
    const [goldBrand, setGoldBrand] = useState(sourceData);

    useEffect(() => {
        setGoldBrand(sourceData);
    }, [open, sourceData]);
    const handleClose = async () => {
        onClose();
    };

    const handleUpdatedItem = async () => {
        return onItemUpdated(goldBrand);
    }

    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleUpdatedItem}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu thay đổi"/>
            <CustomLoadingButton variant="contained" onClick={handleClose}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Hủy"/>
        </>
    );
    const pageTitle = (
        <>
            {`Cập nhật thông tin chành: [${goldBrand.label}]`}
        </>
    );
    return (
        <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
            <Grid container spacing={2}>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        readOnly disabled
                        label="Mã chành"
                        value={goldBrand.id ? goldBrand.id : 0}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)} justifyContent="center">
                    <FormControlLabel control={
                        <Switch
                            checked={goldBrand.silverBrand ? goldBrand.silverBrand : false}
                            onChange={(e) => {
                                setGoldBrand((prev) => {
                                    return {
                                        ...prev,
                                        silverBrand: e.target.checked,
                                    };
                                });
                            }}/>
                    }
                                      label="Chành bạc?"/>
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Tên hiển thị"
                        required
                        value={goldBrand.label ? goldBrand.label : ''}
                        fullWidth
                        InputProps={{type: 'search'}}
                        onChange={(e) => {
                            setGoldBrand((prev) => ({
                                ...prev,
                                label: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        readOnly disabled
                        label="Ký hiệu chành"
                        value={goldBrand.code ? goldBrand.code : ''}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Tên chành đầy đủ"
                        value={goldBrand.name ? goldBrand.name : ''}
                        fullWidth
                        InputProps={{type: 'search'}}
                        onChange={(e) => {
                            setGoldBrand((prev) => ({
                                ...prev,
                                name: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Tên viết tắt"
                        value={goldBrand.shortName ? goldBrand.shortName : ''}
                        fullWidth
                        InputProps={{type: 'search'}}
                        onChange={(e) => {
                            setGoldBrand((prev) => ({
                                ...prev,
                                shortName: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Địa chỉ"
                        value={goldBrand.address ? goldBrand.address : ''}
                        fullWidth
                        InputProps={{type: 'search'}}
                        onChange={(e) => {
                            setGoldBrand((prev) => ({
                                ...prev,
                                address: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Tiêu chuẩn công bố"
                        value={goldBrand.pubStand ? goldBrand.pubStand : ''}
                        fullWidth
                        InputProps={{type: 'search'}}
                        onChange={(e) => {
                            setGoldBrand((prev) => ({
                                ...prev,
                                pubStand: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Xuất xứ"
                        value={goldBrand.origin ? goldBrand.origin : ''}
                        fullWidth
                        InputProps={{type: 'search'}}
                        onChange={(e) => {
                            setGoldBrand((prev) => ({
                                ...prev,
                                origin: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Số điện thoại liên hệ"
                        value={goldBrand.phone ? goldBrand.phone : ''}
                        fullWidth
                        InputProps={{type: 'search'}}
                        onChange={(e) => {
                            setGoldBrand((prev) => ({
                                ...prev,
                                phone: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Ghi chú"
                        value={goldBrand.note ? goldBrand.note : ''}
                        fullWidth
                        onChange={(e) => {
                            setGoldBrand((prev) => ({
                                ...prev,
                                note: e.target.value,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Thứ tự hiển thị"
                        value={goldBrand.order ? goldBrand.order : 0}
                        fullWidth
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/\D/g, '');
                            setGoldBrand((prev) => ({
                                ...prev,
                                order: numericInput,
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        </CustomDialog>
    );
}
GoldBrandDetails.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sourceData: PropTypes.object.isRequired,
    onItemUpdated: PropTypes.func.isRequired
};
export default GoldBrandDetails;
