import {jwtDecode} from "jwt-decode";
import {
    API_ENDPOINT,
    LOCAL_STORAGE_AVATAR,
    LOCAL_STORAGE_KEY_ACCOUNT_NAME,
    LOCAL_STORAGE_KEY_BRANDS,
    LOCAL_STORAGE_KEY_CONFIGS,
    LOCAL_STORAGE_KEY_COUNTERS,
    LOCAL_STORAGE_KEY_PRICES,
    LOCAL_STORAGE_KEY_PRODUCTS,
    LOCAL_STORAGE_KEY_TOKEN,
    LOCAL_STORAGE_KEY_TYPES,
    LOCAL_STORAGE_KEY_USERNAME,
    LOCAL_STORAGE_PRIMARY_COLOR,
    ROUTE_CUSTOMER_MGMT,
    ROUTE_INVENTORY_MGMT,
    ROUTE_INVOICE_MGMT,
    ROUTE_LOGIN,
    ROUTE_ORDER_MGMT,
    ROUTE_PAWN_MGMT,
    ROUTE_PRICE_BOARD,
    ROUTE_SYSTEM_MGMT,
    ROUTE_USER_MGMT
} from "./constants";
import authAxios from '../services/axiosInstance';

export const LOGIN_API_ENDPOINT = API_ENDPOINT + '/auth/authenticate';
export const CHANGE_PWD_API_ENDPOINT = API_ENDPOINT + '/auth/change-password';
export const REFRESH_TOKEN_API_ENDPOINT = API_ENDPOINT + '/auth/refresh-token';
export const LOGOUT_API_ENDPOINT = API_ENDPOINT + '/auth/logout';
export const storeAuthenticatedInfo = (access_token, username, full_name) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, access_token);
    localStorage.setItem(LOCAL_STORAGE_KEY_USERNAME, username);
    localStorage.setItem(LOCAL_STORAGE_KEY_ACCOUNT_NAME, full_name);
}
export const removeLoginData = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_USERNAME);
    localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY_ACCOUNT_NAME);
    localStorage.removeItem(LOCAL_STORAGE_PRIMARY_COLOR);
    localStorage.removeItem(LOCAL_STORAGE_AVATAR);
}
export const removeSystemData = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_BRANDS);
    localStorage.removeItem(LOCAL_STORAGE_KEY_TYPES);
    localStorage.removeItem(LOCAL_STORAGE_KEY_COUNTERS);
    localStorage.removeItem(LOCAL_STORAGE_KEY_PRICES);
    localStorage.removeItem(LOCAL_STORAGE_KEY_PRODUCTS);
    localStorage.removeItem(LOCAL_STORAGE_KEY_CONFIGS);
}
export const refreshAccessToken = async () => {
    try {
        const reqBody = {
            username: localStorage.getItem(LOCAL_STORAGE_KEY_USERNAME),
            accessToken: localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN),
        }
        const response = await authAxios.post(
            REFRESH_TOKEN_API_ENDPOINT,
            reqBody,
            {
                withCredentials: true, // Include cookies in the request
            });
        const access_token = response.data.access_token;
        await localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, access_token);
        return access_token;
    } catch (error) {
        if (error.response.status === 401) {
            window.location.href = ROUTE_LOGIN + '?refreshToken=true';
        }
        removeLoginData();
        window.location.href = ROUTE_LOGIN + '?refreshToken=true';
    }
}
export const isTokenExpired = async (accessToken) => {
    try {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now();
        const exp = (decodedToken.exp * 1000);
        return exp <= currentTime;
    } catch (error) {
        console.log('Exception while checking token', error);
        return false;
    }
};

export const isUserAuthenticated = async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
    if (!accessToken) {
        return false;
    }
    try {
        const isExpired = await isTokenExpired(accessToken);
        if (isExpired) {
            const token = await refreshAccessToken();
            return !!token;
        } else {
            return true;
        }
    } catch (error) {
        console.log('Exception while checking token', error);
        return false;
    }
};

export const logout = async () => {
    try {
        const reqBody = {
            username: localStorage.getItem(LOCAL_STORAGE_KEY_USERNAME),
            accessToken: localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN),
        }
        await authAxios.post(
            LOGOUT_API_ENDPOINT,
            reqBody);
    } catch (error) {
        console.error('Logout failed:', error);
        throw error;
    } finally {
        removeLoginData();
        removeSystemData();
    }
};
export const getPermissionsFromToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.auth || {};
    } catch (error) {
        console.error("Invalid token");
        return {};
    }
};

export const hasPermission = (permissions, path) => {
    switch (path) {
        case ROUTE_PAWN_MGMT:
            return permissions.PAWN?.includes('MANAGE');
        case ROUTE_CUSTOMER_MGMT:
            return permissions.CUSTOMER?.includes('MANAGE');
        case ROUTE_ORDER_MGMT:
            return permissions.ORDER?.includes('MANAGE');
        case ROUTE_INVOICE_MGMT:
            return permissions.INVOICE?.includes('MANAGE');
        case ROUTE_INVENTORY_MGMT:
            return permissions.INVENTORY?.includes('MANAGE');
        case ROUTE_SYSTEM_MGMT:
            return permissions.SYSTEMS?.includes('MANAGE');
        case ROUTE_USER_MGMT:
            return permissions.USER?.includes('MANAGE');
        case ROUTE_PRICE_BOARD:
            return permissions.GOLD_PRICE?.includes('MANAGE');
        default:
            return true;
    }
};

export const requiredPermission = (path) => {
    switch (path) {
        case ROUTE_PAWN_MGMT:
            return 'PAWN:MANAGE';
        case ROUTE_CUSTOMER_MGMT:
            return 'CUSTOMER:MANAGE';
        case ROUTE_ORDER_MGMT:
            return 'ORDER:MANAGE';
        case ROUTE_INVOICE_MGMT:
            return 'INVOICE:MANAGE';
        case ROUTE_INVENTORY_MGMT:
            return 'INVENTORY:MANAGE';
        case ROUTE_SYSTEM_MGMT:
            return 'SYSTEMS:MANAGE';
        case ROUTE_USER_MGMT:
            return 'USER:MANAGE';
        case ROUTE_PRICE_BOARD:
            return 'GOLD_PRICE:MANAGE';
        default:
            return '';
    }
};
