import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import PropTypes from "prop-types";

const CustomLoadingButton = ({buttonLabel = '', onClick: onClickFunction, ...rest}) => {
    const [loading, setLoading] = useState(false);
    const handleOnClick = async () => {
        try {
            setLoading(true);
            await onClickFunction();
        } catch (error) {
            console.log("Error: ", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <LoadingButton {...rest}
                       onClick={handleOnClick}
                       loading={loading}
                       loadingIndicator="Loading…"

        >
            {buttonLabel}
        </LoadingButton>
    );
};
CustomLoadingButton.propTypes = {
    buttonLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default CustomLoadingButton;
