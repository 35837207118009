import React, {useEffect, useRef, useState} from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from "prop-types";

const DropDownButton = ({
                            options,
                            disabled = true,
                            action,
                            handleActionClick,
                            handleMenuItemClick,
                            isLoading,
                            fullWidth = false,
                        }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedAction, setSelectedAction] = React.useState(action);
    useEffect(() => {
        setSelectedAction(action);
    }, [action, options]);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const getSelectedAction = () => {
        const option = options.find(act => act.code === selectedAction) || options[0];
        return option?.label;
    }
    const onClickAction = () => {
        const option = options.find(act => act.code === selectedAction) || options[0];
        handleActionClick(option.code);
    }
    return (
        <>
            <ButtonGroup disabled={disabled} variant="contained" ref={anchorRef} sx={{marginBottom: 1}}
                         fullWidth={fullWidth}>
                <LoadingButton
                    onClick={onClickAction}
                    loading={isLoading}
                    loadingIndicator="Loading…"
                    variant="contained"
                >
                    <span>{getSelectedAction()}</span>
                </LoadingButton>
                <Button onClick={handleToggle} sx={{maxWidth: 40}}>
                    <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{zIndex: 1, minWidth: 200}}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option) => (
                                        <MenuItem
                                            key={option.code}
                                            disabled={option.disabled}
                                            selected={option.code === selectedAction}
                                            onClick={(event) => {
                                                handleMenuItemClick(event, option.code);
                                                setOpen(false);
                                            }}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
};
DropDownButton.propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    action: PropTypes.any.isRequired,
    handleActionClick: PropTypes.func.isRequired,
    handleMenuItemClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    fullWidth: PropTypes.bool,
};
export default DropDownButton
