import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import React from "react";
import {Box, Container, Typography} from "@mui/material";
import Login from "../features/auths/Login";
import ChangePassword from "../features/auths/ChangePassword";
import PawnGrid from "../features/pawn/PawnGrid";
import OrderGrid from "../features/order/OrderGrid";
import CustomerGrid from "../features/customer/CustomerGrid";
import JewelryGrid from "../features/jewelry/JewelryGrid";
import SystemBoard from "../features/system/SystemBoard";
import UserGrid from "../features/user/UserGrid";
import Dashboard from "../features/dashboard/Dashboard";
import Logout from "../features/auths/Logout";
import InvoiceGrid from "../features/invoice/InvoiceGrid";
import NotAuthorized from "../components/auth/NotAuthorized";
import {requiredPermission} from "../utils/auth";
import GoldPriceTable from "../features/system/GoldPriceTable";
import TradingViewWidget from "../features/prices/TradingViewWidget";
import Forbidden from "../components/auth/Forbidden";
import {useAuth} from "../providers/AuthProvider";
import {
    ROUTE_CHANGE_PASSWORD,
    ROUTE_CUSTOMER_MGMT,
    ROUTE_DASHBOARD,
    ROUTE_FORBIDDEN,
    ROUTE_HOME,
    ROUTE_INVENTORY_MGMT,
    ROUTE_INVOICE_MGMT,
    ROUTE_LOGIN,
    ROUTE_LOGOUT,
    ROUTE_NOT_AUTHORIZED,
    ROUTE_ORDER_MGMT,
    ROUTE_PAWN_MGMT,
    ROUTE_PRICE_BOARD,
    ROUTE_PRICES,
    ROUTE_SYSTEM_MGMT,
    ROUTE_USER_MGMT
} from "../utils/constants";
import PropTypes from "prop-types";

const Home = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Dashboard/>
        </Container>
    );
};
const LoginRoute = () => {
    return (
        <Login/>
    );
}

const ChangePasswordRoute = () => {
    return (
        <ChangePassword/>
    );
}
const PawnMgmtRoute = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                QL Cầm đồ
            </Typography>
            <Box mt={2}>
                <PawnGrid/>
            </Box>
        </Container>
    );
};

const OrderMgmtRoute = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                Mua bán, trao đổi
            </Typography>
            <Box mt={2}>
                <OrderGrid/>
            </Box>
        </Container>
    );
};

const CustomerMgmtRoute = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                QL Khách hàng
            </Typography>
            <Box mt={2}>
                <CustomerGrid/>
            </Box>
        </Container>
    );
};
const InventoryMgmtRoute = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>

            </Typography>
            <Box mt={2}>
                <JewelryGrid/>
            </Box>
        </Container>
    );
};
const SystemMgmtRoute = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                QL Hệ thống
            </Typography>
            <Box mt={2}>
                <SystemBoard/>
            </Box>
        </Container>
    );
};
const UserMgmtRoute = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                QL Người dùng
            </Typography>
            <Box mt={2}>
                <UserGrid/>
            </Box>
        </Container>
    );
};

const PriceBoardRoute = ({onToggleFullScreen}) => {
    PriceBoardRoute.propTypes = {
        onToggleFullScreen: PropTypes.func.isRequired
    }
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Box mt={2}>
                <GoldPriceTable onToggleFullScreen={onToggleFullScreen}/>
            </Box>
        </Container>
    );
}

const LivePrices = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Box sx={{height: 300}}>
                <TradingViewWidget/>
            </Box>
        </Container>
    );
};
const InvoiceMgmtRoute = () => {
    return (
        <Container maxWidth={false} sx={{pl: 1, pr: 1}}>
            <Typography variant="h5" component="div" sx={{mt: 2}}>
                Hóa đơn điện tử
            </Typography>
            <Box mt={2}>
                <InvoiceGrid/>
            </Box>
        </Container>
    );
};

const AppRoutes = ({onToggleFullScreen}) => {
    const isAuthenticated = useAuth();
    return (
        <Routes>
            <Route path={ROUTE_HOME} element={<ProtectedRoute auth={isAuthenticated}><Home/></ProtectedRoute>}/>
            <Route path={ROUTE_DASHBOARD} element={<ProtectedRoute auth={isAuthenticated}><Home/></ProtectedRoute>}/>
            <Route path={ROUTE_LOGIN} element={<LoginRoute/>}/>
            <Route path={ROUTE_PAWN_MGMT} element={<ProtectedRoute auth={isAuthenticated}
                                                                   requiredPermission={requiredPermission(ROUTE_PAWN_MGMT)}><PawnMgmtRoute/></ProtectedRoute>}/>
            <Route path={ROUTE_CUSTOMER_MGMT} element={<ProtectedRoute auth={isAuthenticated}
                                                                       requiredPermission={requiredPermission(ROUTE_CUSTOMER_MGMT)}><CustomerMgmtRoute/></ProtectedRoute>}/>
            <Route path={ROUTE_INVENTORY_MGMT} element={<ProtectedRoute auth={isAuthenticated}
                                                                        requiredPermission={requiredPermission(ROUTE_INVENTORY_MGMT)}><InventoryMgmtRoute/></ProtectedRoute>}/>
            <Route path={ROUTE_ORDER_MGMT} element={<ProtectedRoute auth={isAuthenticated}
                                                                    requiredPermission={requiredPermission(ROUTE_ORDER_MGMT)}><OrderMgmtRoute/></ProtectedRoute>}/>
            <Route path={ROUTE_INVOICE_MGMT} element={<ProtectedRoute auth={isAuthenticated}
                                                                      requiredPermission={requiredPermission(ROUTE_INVOICE_MGMT)}><InvoiceMgmtRoute/></ProtectedRoute>}/>
            <Route path={ROUTE_SYSTEM_MGMT} element={<ProtectedRoute auth={isAuthenticated}
                                                                     requiredPermission={requiredPermission(ROUTE_SYSTEM_MGMT)}><SystemMgmtRoute/></ProtectedRoute>}/>
            <Route path={ROUTE_USER_MGMT} element={<ProtectedRoute auth={isAuthenticated}
                                                                   requiredPermission={requiredPermission(ROUTE_USER_MGMT)}><UserMgmtRoute/></ProtectedRoute>}/>
            <Route path={ROUTE_PRICE_BOARD} element={<ProtectedRoute auth={isAuthenticated}
                                                                     requiredPermission={requiredPermission(ROUTE_PRICE_BOARD)}><PriceBoardRoute
                onToggleFullScreen={onToggleFullScreen}/></ProtectedRoute>}/>
            <Route path={ROUTE_PRICES} element={<ProtectedRoute auth={isAuthenticated}><LivePrices/></ProtectedRoute>}/>
            <Route path={ROUTE_CHANGE_PASSWORD} element={<ChangePasswordRoute/>}/>
            <Route path={ROUTE_LOGOUT} element={<Logout/>}/>
            <Route path={ROUTE_NOT_AUTHORIZED} element={<NotAuthorized/>}/>
            <Route path={ROUTE_FORBIDDEN} element={<Forbidden/>}/>
        </Routes>
    );
}
AppRoutes.propTypes = {
    onToggleFullScreen: PropTypes.func.isRequired
}
export default AppRoutes;
