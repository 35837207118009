import dayjs from "dayjs";
import {API_ENDPOINT, Counter_Unknown, getCounters, getGoldPriceByType, GoldBrand_Unknown} from "../../utils/constants";
import axios from '../../services/axiosInstance';

export const JEWELRY_API_ENDPOINT = API_ENDPOINT + '/jewelries';
export const JewelryStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
};
export const JewelrySellStatus = {
    SOLD_OUT: 'SOLD_OUT',
    IN_STOCK: 'IN_STOCK'
};
export const initDefaultJewelry = () => {
    return {
        ...DEFAULT_JEWELRY_DATA,
        goldPrice: getGoldPriceByType("610").buy,
    };
}
export const DEFAULT_JEWELRY_DATA = {
    goldWeight: 0,
    gemWeight: 0,
    totalWeight: 0,
    quantity: 1,
    name: "",
    description: "",
    brand: GoldBrand_Unknown.code,
    goldType: "610",
    symbol: "",
    counterId: "Unknown",
    goldPrice: 0,
    price: 0,
    procPrice: 0,
    sellStatus: "IN_STOCK",
    status: "ACTIVE",
    totalPrice: 0,
    sellProcPrice: 0,
    exchangeProcPrice: 0,
    barCode: '',
    visible: true,
    sellByItem: false,
};
export const mapStatusToText = (jewelryStatus) => {
    switch (jewelryStatus) {
        case JewelryStatus.ACTIVE:
            return 'Hoạt động';
        case JewelryStatus.INACTIVE:
            return 'Ẩn';
        default:
            return 'Không xác định';
    }
};
export const mapSellStatusToText = (jewelryStatus) => {
    switch (jewelryStatus) {
        case JewelrySellStatus.IN_STOCK:
            return 'Trong kho';
        case JewelrySellStatus.SOLD_OUT:
            return 'Bán hết';
        default:
            return 'Không xác định';
    }
};

export const getCounterLabelById = (code) => {
    const codeAsString = code.toString();
    const counters = getCounters();
    if (counters.hasOwnProperty(codeAsString)) {
        return counters[codeAsString].label;
    } else {
        return Counter_Unknown.label;
    }
}
export const convertDayjsDate = (stringDate) => {
    return dayjs(stringDate);
}
export const fetchJewelryDetails = async (jewelryId) => {
    try {
        const response = await axios.get(`${JEWELRY_API_ENDPOINT}/${jewelryId}`);
        return response.data;
    } catch (error) {
        console.error("Error while fetching jewelry details:", error)
        throw error;
    }
};

export const SELECTED_JEWELRY_ACTIONS = [
    {label: 'Sửa', code: 'UPDATED', actStatus: [], permission: 'UPDATE'},
    {label: 'Xóa', code: 'DELETE', actStatus: [], permission: 'DELETE'},
    {label: 'In tem', code: 'RECEIPT', actStatus: [], permission: 'RECEIPT'},
    {label: 'Trạng thái', code: 'UPDATED_STATUS', actStatus: [], permission: 'UPDATE', administration: true},
];

export const DEFAULT_FILTER = {
    status: "ACTIVE",
    sellStatus: "IN_STOCK"
};

export const findJewelries = async (pageNumber, pageSize, requestFilter) => {
    const requestBody = requestFilter || DEFAULT_FILTER
    return await axios.post(
        `${JEWELRY_API_ENDPOINT}/find?page=${pageNumber}&size=${pageSize}&sort=jewelryId,desc`, requestBody);
}

export const exportJewelries = async (pageNumber, pageSize, requestFilter) => {
    const requestBody = requestFilter || DEFAULT_FILTER
    const response = await axios.post(
        `${JEWELRY_API_ENDPOINT}/export?page=${pageNumber}&size=${pageSize}&sort=jewelryId,desc`, requestBody, {
            responseType: 'blob'
        });
    return response.data;
}
export const CREATE_JEWELRY_ACTIONS = [
    {label: 'Thêm vàng', code: 'GOLD', actStatus: []},
    {label: 'Thêm bạc', code: 'SILVER', actStatus: []},
];
