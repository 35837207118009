import React, {useEffect, useState} from "react";
import {Grid, TextField} from "@mui/material";
import {connect} from "react-redux";
import {resetUserPasswordRequest} from "./userThunks";
import {getResponsiveWidth, handleApiError, handleSuccessMessage} from "../../utils/constants";
import {useAlert} from "../../providers/AlertProvider";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CustomDialog from "../../components/dialog/CustomDialog";
import PropTypes from "prop-types";

const ResetUserPassword = ({userData, onResetPressed, open, onClose}) => {
    const {openAlert} = useAlert();
    const [user, setUser] = useState(userData);

    useEffect(() => {
        setUser(userData);
    }, [open, userData])
    const onResetUserPwdClick = async () => {
        try {
            const response = await onResetPressed(user.username);
            handleSuccessMessage('Thành công! Mật mới là: ' + response, openAlert);
            onClose(response);
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const handleClose = async () => {
        onClose();
    };

    const formActions = (
        <CustomLoadingButton variant="contained" onClick={onResetUserPwdClick}
                             sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Đặt lại mật khẩu"/>
    );
    const pageTitle = (
        <>
            {`Đặt lại mật khẩu cho: ${user.username}`}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            readOnly disabled
                            label="Họ và tên"
                            value={user.name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Địa chỉ email"
                            value={user.email ? user.email : ''}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Tên đăng nhập"
                            value={user.username}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
        </div>
    );
}

const mapStateToProps = state => ({
    users: state.users,
});

const mapDispatchToProps = dispatch => ({
    onResetPressed: (username) => dispatch(resetUserPasswordRequest(username)),
});

ResetUserPassword.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userData: PropTypes.object.isRequired,
    onResetPressed: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetUserPassword)
