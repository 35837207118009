import {Box, Button, Divider, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {fetchSystemInfo, getShopInfo, ROUTE_DASHBOARD} from "../../utils/constants";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {isMobile} from "react-device-detect";
import AccountMenu from "../auth/AccountMenu";
import {usePermissions} from "../../providers/PermissionProvider";
import {hasPermission} from "../../utils/auth";
import {useAuth} from "../../providers/AuthProvider";
import PropTypes from "prop-types";

const AppToolbar = ({menuItems, onDrawerToggle: handleDrawerToggle, onLogout: handleLogout}) => {
    const navigate = useNavigate();
    const permissions = usePermissions();
    const isAuthenticated = useAuth();
    const navigateDashboard = async () => {
        await fetchSystemInfo();
        navigate(ROUTE_DASHBOARD);
    };
    return (
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{mr: 2, display: {sm: 'none'}}}
            >
                <MenuIcon/>
            </IconButton>
            <Typography variant={(isMobile) ? "h6" : "h4"} component="div" sx={{flexGrow: 1}}>
                <button
                    onClick={navigateDashboard}
                    style={{
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        color: 'inherit',
                        textAlign: 'left'
                    }}
                >
                    {getShopInfo().name ? getShopInfo().name : 'PM QUẢN LÝ TIỆM VÀNG'}
                </button>
            </Typography>
            {isAuthenticated && (<AccountMenu onLogout={handleLogout}/>)}
            <Divider/>
            <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                {isAuthenticated && menuItems.filter(item => hasPermission(permissions, item.path)).map((item) => (
                    <Button key={item.label} color="inherit" component={Link} to={item.path}>
                        {item.label}
                    </Button>
                ))}
                {isAuthenticated && (
                    <Button color="inherit" onClick={handleLogout}>Thoát</Button>
                )}
            </Box>
        </Toolbar>
    )
}
AppToolbar.propTypes = {
    menuItems: PropTypes.array.isRequired,
    onDrawerToggle: PropTypes.func,
    onLogout: PropTypes.func,
}
export default AppToolbar;
