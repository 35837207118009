import React, {useEffect, useState} from "react";
import {vi_locale} from 'dayjs/locale/vi';
import axios from '../../services/axiosInstance';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Grid,
    InputAdornment,
    TextField
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {mapPawnStatusToText, PAWNS_API_ENDPOINT, suggestPawnValue} from "./PawnConstants";
import {
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    formatDate,
    getResponsiveWidth,
    handleApiError,
    handleSuccessMessage,
    setUserTime
} from "../../utils/constants";
import RequestMoneyView from "./RequestMoneyView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useAlert} from "../../providers/AlertProvider";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CustomDialog from "../../components/dialog/CustomDialog";
import ViewPawn from "./ViewPawn";
import PropTypes from "prop-types";

const RequestMoney = ({open, onClose, sourcePawn}) => {
    const {openAlert} = useAlert();
    const [pawnData, setPawnData] = useState(sourcePawn);
    const [requestAmountValid, setRequestAmountValid] = useState(true);
    const [requestData, setRequestData] = useState({
        pawnId: sourcePawn.pawnId,
        requestAmount: 0,
        requestDate: convertDayjsDate(new Date()),
    });
    useEffect(() => {
        setPawnData(sourcePawn);
    }, [open, sourcePawn])
    const handleSave = async () => {
        let validated = true;
        if (validated) {
            await onCreateRequestMoney();
        }
    };

    const onCreateRequestMoney = async () => {
        try {
            await axios.post(`${PAWNS_API_ENDPOINT}/${pawnData.pawnId}/request-money`, requestData);
            handleSuccessMessage('Thêm thành công đơn số [' + pawnData.pawnId + ']', openAlert);
            onClose(pawnData);
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleClose = async () => {
        onClose();
    };

    const formActions = (
        <CustomLoadingButton variant="contained" onClick={handleSave}
                             sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu yêu cầu"/>
    );
    const pageTitle = (
        <>
            {`Lấy thêm đơn cầm số: ${pawnData.pawnId}`}
            <br/>Tình trạng: {mapPawnStatusToText(pawnData.pawnStatus)}
        </>
    );

    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <ViewPawn sourcePawn={pawnData} onChange={handleChangeAccordion('panel100')}/>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin yêu cầu
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField readOnly disabled
                                           label="Ngày cầm"
                                           value={formatDate(pawnData.pawnDate, DATE_SHORT_FORMAT)}
                                           fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    readOnly disabled
                                    label="Số tiền đang cầm"
                                    value={Number(pawnData.pawnAmount).toLocaleString()}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    readOnly disabled
                                    label="Số tiền cầm tối đa"
                                    value={Number(pawnData.itemValue).toLocaleString()}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                    <DatePicker label="Ngày yêu cầu"
                                                value={requestData.requestDate}
                                                onChange={(date) => setRequestData((prev) => ({
                                                    ...prev,
                                                    requestDate: setUserTime(date),
                                                }))}
                                                fullWidth
                                                format={DATE_SHORT_FORMAT}
                                                slotProps={{
                                                    field: {shouldRespectLeadingZeros: true},
                                                    textField: {fullWidth: true}
                                                }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <Autocomplete
                                    freeSolo
                                    value={!isNaN(requestData.requestAmount) ? Number(requestData.requestAmount).toLocaleString() : '0'}
                                    onChange={(event, selectedValue) => {
                                        setRequestData((prev) => ({
                                            ...prev,
                                            requestAmount: selectedValue ? selectedValue.value : 0,
                                        }));
                                    }}
                                    options={suggestPawnValue(100000, undefined, (pawnData.itemValue - pawnData.pawnAmount)).map((option) => ({
                                        label: Number(option).toLocaleString(),
                                        value: parseInt(option),
                                    }))}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            value={requestData.requestAmount ? Number(requestData.requestAmount).toLocaleString() : '0'}
                                            onChange={(e) => {
                                                const numericInput = e.target.value.replace(/\D/g, '');
                                                setRequestData((prev) => ({
                                                    ...prev,
                                                    requestAmount: numericInput,
                                                }));
                                                setRequestAmountValid(true);
                                            }}
                                            fullWidth
                                            label="Số tiền yêu cầu thêm"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                                endAdornment: (
                                                    <InputAdornment position="end">vnđ</InputAdornment>
                                                ),
                                            }}
                                            error={!requestAmountValid}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RequestMoneyView reqMoneyData={pawnData.reqMoneys} editable={false}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </CustomDialog>
        </div>
    )
};
RequestMoney.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sourcePawn: PropTypes.object.isRequired
}
export default RequestMoney;

