import React, {useEffect, useState} from "react";
import {Grid, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import {getInterestTypeLabel, InterestTypes, mapPawnStatusToText, PawnStatus} from "./PawnConstants";
import {
    calculateTotalWeight,
    convertDayjsDate,
    DATE_FULL_VN_FORMAT,
    DATE_SHORT_FORMAT,
    formatCurrency,
    formatDate,
    getGoldBrandLabelByCode,
    getGoldTypeLabelByCode,
    getResponsiveWidth,
    readGoldWeightUnits
} from "../../utils/constants";
import RequestMoneyView from "./RequestMoneyView";
import CustomAccordion from "../../components/common/CustomAccordion";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import {randomId} from "@mui/x-data-grid-generator";

const getActionTypeString = (current) => {
    switch (current.actionType) {
        case 'INSERT':
            return 'TẠO';
        case 'UPDATE': {
            if (PawnStatus.FORFEITED === current.pawnStatus) {
                return 'THANH LÝ';
            } else if (PawnStatus.CANCELLED === current.pawnStatus) {
                return 'HỦY';
            } else if (PawnStatus.REDEEMED === current.pawnStatus) {
                return "CHUỘC";
            }
            return "CẬP NHẬT";
        }
        case 'DELETE':
            return 'XÓA';
        default:
            return current.actionType;
    }
};

function appendForfeitedPawn(current) {
    let str = '';
    if (current.forfeitedAmount) {
        str += `, tiền thanh lý: ${current.forfeitedAmount && formatCurrency(current.forfeitedAmount)}`;
    }
    if (current.forfeitedDate) {
        str += `, ngày thanh lý chọn: ${current.forfeitedDate && convertDayjsDate(current.forfeitedDate).format(DATE_SHORT_FORMAT)}`;
    }
    if (current.forfeitedReason) {
        str += `, lý do thanh lý: ${current.forfeitedReason}`;
    }
    return str;
}

function cancelledPawn(current) {
    if (current.canceledReason) {
        return `, lý do hủy: ${current.canceledReason}`;
    }
    return '';
}

function redeemedPawn(current) {
    if (current.redeemDate) {
        return `, ngày chuộc chọn: ${current.redeemDate && convertDayjsDate(current.updatedAt).format(DATE_SHORT_FORMAT)}`;
    }
    return '';
}

const buildAuditString = (current, previous) => {
    if (!current) return '';
    console.debug(previous);
    let str = `Hoạt động: ${getActionTypeString(current)}, Lúc: ${convertDayjsDate(current.actionTime).add(7, 'hour').format(DATE_FULL_VN_FORMAT)}`;
    const isInsert = ("INSERT" === current.actionType);
    str += `, bởi: ${isInsert ? current.createdBy : current.updatedBy}`;
    str += `, món hàng: ${current.itemName}`;
    str += `, TL vàng: ${current.itemWeight ? readGoldWeightUnits(current.itemWeight) : ''}`;
    str += `, trạng thái: ${mapPawnStatusToText(current.pawnStatus)}`;
    str += `, số tiền cầm: ${formatCurrency(current.pawnAmount)}`;
    str += `, tuổi vàng: ${getGoldTypeLabelByCode(current.itemType)}`;
    str += `, chành: ${getGoldBrandLabelByCode(current.itemBrand)}`;
    str += `, ngày cầm: ${current.pawnDate && convertDayjsDate(current.pawnDate).format(DATE_SHORT_FORMAT)}`;
    str += `, ngày hết hạn: ${current.pawnDueDate && convertDayjsDate(current.pawnDueDate).format(DATE_SHORT_FORMAT)}`;
    str += `, lãi suất: ${current.interestRate}%`;
    str += `, PP lãi: ${getInterestTypeLabel(current.interestDaysPerMonth)}`;
    if (PawnStatus.FORFEITED === current.pawnStatus) {
        str += appendForfeitedPawn(current);
    } else if (PawnStatus.CANCELLED === current.pawnStatus) {
        str += cancelledPawn(current)
    } else if (PawnStatus.REDEEMED === current.pawnStatus) {
        str += redeemedPawn(current);
    }
    if (current.interestAmount) {
        str += `, tiền lãi: ${current.interestAmount && formatCurrency(current.interestAmount)}`;
    }
    return str;
};
const ViewPawn = ({sourcePawn, defExpanded = "panel0", onChange: onChangeAccordion}) => {
    const [pawnData, setPawnData] = useState(sourcePawn);
    const [expanded, setExpanded] = React.useState(defExpanded);
    const handleChangeAccordion = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        onChangeAccordion(newExpanded);
    };
    useEffect(() => {
        setPawnData(sourcePawn);
        const buildAuditStrings = () => {
            const auditData = sourcePawn.audits;
            return auditData ? auditData.map((current, index) => {
                const previous = auditData[index + 1];
                return buildAuditString(current, previous);
            }) : "";
        };
        setPawnData((prev) => ({
            ...prev,
            totalWeight: calculateTotalWeight(prev.itemWeight, prev.gemWeight),
            auditString: buildAuditStrings(),
        }));
    }, [sourcePawn]);

    return (
        <>
            <CustomAccordion expanded={expanded}
                             title="Thông tin đơn cầm"
                             panel='panel100'
                             onChangeAccordion={handleChangeAccordion}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Khách hàng"
                            readOnly disabled
                            value={pawnData.customerName}
                            fullWidth
                        />
                    < /Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Tên món hàng"
                            value={pawnData.itemName}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Ghi chú"
                            value={pawnData.itemDescription}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Trọng lượng tổng" readOnly disabled
                            value={pawnData.totalWeight}
                            fullWidth
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>)
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Trọng lượng hột" readOnly disabled
                            value={pawnData.gemWeight}
                            fullWidth
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Trọng lượng vàng"
                            value={pawnData.itemWeight}
                            fullWidth
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Chành"
                            readOnly disabled
                            value={getGoldBrandLabelByCode(pawnData.itemBrand)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            label="Tuổi"
                            readOnly disabled
                            value={getGoldTypeLabelByCode(pawnData.itemType)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Số tiền cầm tối đa"
                            value={Number(pawnData.itemValue).toLocaleString()}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">vnđ</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            readOnly disabled
                            label="Số tiền cầm"
                            value={Number(pawnData.pawnAmount).toLocaleString()}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">vnđ</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField
                            readOnly disabled
                            label="Lãi suất"
                            value={pawnData.interestRate}
                            fullWidth
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                            }}
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField readOnly disabled
                                   label="Ngày cầm"
                                   value={formatDate(pawnData.pawnDate, DATE_SHORT_FORMAT)}
                                   fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <TextField readOnly disabled
                                   label="Ngày đến hạn"
                                   value={formatDate(pawnData.pawnDueDate, DATE_SHORT_FORMAT)}
                                   fullWidth
                        />
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <Select
                            value={pawnData.interestDaysPerMonth}
                            fullWidth
                            readOnly disabled
                        >
                            {Object.values(InterestTypes).map((interestType) => (
                                <MenuItem key={interestType.code} value={interestType.code}>
                                    {interestType.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <RequestMoneyView reqMoneyData={pawnData.reqMoneys} editable={false}/>
                    </Grid>
                </Grid>
            </CustomAccordion>
            {(pawnData.pawnStatus === PawnStatus.REDEEMED) && (
                <CustomAccordion expanded={expanded}
                                 title="Thông tin chuộc đồ"
                                 panel='panel101'
                                 onChangeAccordion={handleChangeAccordion}>
                    <Grid container spacing={2}>
                        <Grid item xs={getResponsiveWidth(6, 12)}>
                            <TextField readOnly disabled
                                       label="Ngày chuộc"
                                       value={formatDate(pawnData.redeemDate, DATE_SHORT_FORMAT)}
                                       fullWidth
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 12)}>
                            <TextField
                                readOnly disabled
                                label="Tiền lãi"
                                value={Number(pawnData.interestAmount).toLocaleString()}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                readOnly disabled
                                label="Tổng tiền"
                                value={Number(pawnData.totalAmount).toLocaleString()}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </CustomAccordion>
            )}
            {(pawnData.pawnStatus === PawnStatus.FORFEITED) && (
                <CustomAccordion expanded={expanded}
                                 title="Thông tin thanh lý"
                                 panel='panel102'
                                 onChangeAccordion={handleChangeAccordion}>
                    <Grid container spacing={2}>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <TextField readOnly disabled
                                       label="Ngày thanh lý"
                                       value={formatDate(pawnData.forfeitedDate, DATE_SHORT_FORMAT)}
                                       fullWidth
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(6, 6)}>
                            <TextField
                                readOnly disabled
                                label="Số tiền thanh lý"
                                value={Number(pawnData.forfeitedAmount ? pawnData.forfeitedAmount : 0).toLocaleString()}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                readOnly disabled
                                label="Lý do thanh lý"
                                value={pawnData.forfeitedReason}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                readOnly disabled
                                label="Tiền lãi"
                                value={Number(pawnData.interestAmount).toLocaleString()}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)}>
                            <TextField
                                readOnly disabled
                                label="Tổng tiền"
                                value={Number(pawnData.totalAmount).toLocaleString()}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </CustomAccordion>
            )}
            <CustomAccordion expanded={expanded}
                             title="Thông tin lịch sử đơn cầm"
                             panel='panel103'
                             onChangeAccordion={handleChangeAccordion}>
                {pawnData.auditString && pawnData.auditString.map((auditString) => (
                    <React.Fragment key={randomId()}>
                        <Typography display="block">{auditString}</Typography>
                        <Divider/>
                    </React.Fragment>
                ))}
            </CustomAccordion>
        </>
    )
};
ViewPawn.propTypes = {
    defExpanded: PropTypes.string,
    onChange: PropTypes.func,
    sourcePawn: PropTypes.object.isRequired
};
export default ViewPawn;

