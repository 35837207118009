import {Grid, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import {getResponsiveWidth, getSortedPaymentTypes, ROUTE_INVOICE_MGMT} from "../../utils/constants";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {vi_locale} from "dayjs/locale/vi";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

const GeneralInvoiceInfo = forwardRef(({invoiceData, setInvoiceData, onSearchOrder, viewMode = false}, ref) => {
    const [invoiceSeriesValid, setInvoiceSeriesValid] = useState(true);
    const [editable, setEditable] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (viewMode) {
            setEditable(false);
        } else {
            setEditable(true);
        }
    }, [viewMode]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        async function checkInvoiceRequest() {
            const orderId = params.get('orderId');
            if (orderId > 0) {
                onSearchOrder(orderId);
                navigate(ROUTE_INVOICE_MGMT);
            }
        }

        checkInvoiceRequest();
    }, [location.search, onSearchOrder, navigate]);
    const handleSearchClick = async () => {
        onSearchOrder(invoiceData.orderId);
    };
    const validateRequest = () => {
        let validated = true;
        if (invoiceData.invoiceSeries && invoiceData.invoiceSeries !== '') {
            setInvoiceSeriesValid(true);
        } else {
            validated = false;
            setInvoiceSeriesValid(false);
        }
        return validated;
    }
    useImperativeHandle(ref, () => ({validateRequest}));
    return (
        <Grid container spacing={2}>
            <Grid item xs={getResponsiveWidth(12, 6)}>
                <TextField
                    label="Ký hiệu hóa đơn"
                    required
                    disabled={!editable}
                    value={invoiceData.invoiceSeries || ''}
                    onChange={(e) => {
                        setInvoiceData((prev) => ({
                            ...prev,
                            invoiceSeries: e.target.value,
                        }));
                        setInvoiceSeriesValid(true);
                    }}
                    fullWidth
                    error={!invoiceSeriesValid}
                />
            </Grid>
            <Grid item xs={getResponsiveWidth(12, 6)}>
                <TextField
                    label="Nhập đơn đơn hàng tìm kiếm"
                    required
                    disabled={!editable}
                    value={invoiceData.orderId === 0 ? '' : invoiceData.orderId}
                    onChange={(e) => {
                        const numericInput = e.target.value.replace(/\D/g, '');
                        setInvoiceData((prev) => ({
                            ...prev,
                            orderId: numericInput,
                        }));
                    }}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon style={{cursor: 'pointer'}} onClick={handleSearchClick}/>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={getResponsiveWidth(12, 6)}>
                <Select
                    value={invoiceData.paymentType}
                    fullWidth
                    disabled={!editable}
                    onChange={(e) => {
                        setInvoiceData((prev) => ({
                            ...prev,
                            paymentType: e.target.value,
                        }));
                    }}
                >
                    {getSortedPaymentTypes().map((type) => (
                        <MenuItem key={type.code} value={type.code}>
                            {type.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

            <Grid item xs={getResponsiveWidth(12, 6)}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                    <DateTimePicker
                        disabled={!editable}
                        label="Ngày giờ xuất hóa đơn"
                        onChange={(date) => {
                            setInvoiceData((prev) => ({
                                ...prev,
                                invoiceIssuedDate: date,
                            }));
                        }}
                        fullWidth
                        disableFuture={true}
                        defaultValue={invoiceData.invoiceIssuedDate ? invoiceData.invoiceIssuedDate : dayjs()}
                        value={invoiceData.invoiceIssuedDate}
                        slotProps={{field: {shouldRespectLeadingZeros: true}, textField: {fullWidth: true}}}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    );
});
GeneralInvoiceInfo.propTypes = {
    viewMode: PropTypes.bool,
    invoiceData: PropTypes.object.isRequired,
    setInvoiceData: PropTypes.func.isRequired,
    onSearchOrder: PropTypes.func
};
export default GeneralInvoiceInfo;
