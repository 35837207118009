import {GridToolbarContainer} from "@mui/x-data-grid";
import {Grid, IconButton, InputAdornment, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import QrCodeIcon from '@mui/icons-material/QrCode';
import React, {useRef, useState} from "react";
import {getResponsiveWidth} from "../../utils/constants";
import {Html5QrcodeScanner} from "html5-qrcode";
import CachedIcon from '@mui/icons-material/Cached';
import PropTypes from "prop-types";

const CustomGridToolbar = ({onSearch: onSearchFunction}) => {
    const [filter, setFilter] = useState({searchWord: ''});
    const [isScanning, setIsScanning] = useState(false);
    const cameraRef = useRef(null);
    const handleSearchClick = async () => {
        await onSearchFunction(filter);

    };
    const handleScanClick = () => {
        setIsScanning(true);
        const qrCodeScanner = new Html5QrcodeScanner(
            "camera-container",
            {
                fps: 10,
                qrbox: {width: 180, height: 80},
                disableFlip: false,
                rememberLastUsedCamera: true,
                aspectRatio: 2.0,
                videoConstraints: {
                    facingMode: "environment",
                },
            },
        );
        qrCodeScanner.render(
            async (text) => {
                qrCodeScanner.clear().then(() => {
                    setIsScanning(false);
                }).catch((err) => {
                    console.error(`Error stopping scanner: ${err}`);
                    setIsScanning(false);
                });
                setFilter((prev) => ({
                    ...prev,
                    searchWord: text,
                }));
                await onSearchFunction({searchWord: text});
            },
            (error) => {
                //Do nothing
            }
        );
    };
    const handleReloadClick = () => {
        window.location.reload();
    };

    return (
        <GridToolbarContainer>
            <Grid container spacing={2}>
                <Grid item xs={getResponsiveWidth()}>
                    <TextField
                        label="Nhập vào để tìm kiếm"
                        type="search"
                        size="small"
                        variant="standard"
                        value={filter.searchWord}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                handleSearchClick();
                            }
                        }}
                        onChange={(e) => {
                            setFilter((prev) => ({
                                ...prev,
                                searchWord: e.target.value,
                            }));
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{cursor: 'pointer'}} onClick={handleSearchClick}/>
                                    <IconButton onClick={isScanning ? handleReloadClick : handleScanClick}>
                                        {isScanning ? <CachedIcon/> : <QrCodeIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            {/* The container where the camera will be rendered */}
            <div id="camera-container" ref={cameraRef} style={{width: '100%', height: isScanning ? 'auto' : '0'}}/>
        </GridToolbarContainer>
    );
}
CustomGridToolbar.propTypes = {
    onSearch: PropTypes.func.isRequired,
};
export default CustomGridToolbar;
