import {Box, Grid} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import PawnKpiSection from "./PawnKpiSection";
import JewelryKPISection from "./JewelryKPISection";
import OrderKpiSection from "./OrderKpiSection";
import {getResponsiveWidth} from "../../utils/constants";
import {isDesktop} from "react-device-detect";
import PawnChart from "./PawnChart";
import JewelryChart from "./JewelryChart";
import OrderChart from "./OrderChart";
import RecentActivity from "./RecentActivity";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {usePermissions} from "../../providers/PermissionProvider";
import TradingViewTickerTape from "../prices/TradingViewTickerTape";

const getGridSx = () => {
    return (isDesktop) ? {mt: 1, pr: 1} : {mt: 1, pr: 0};
}
const getDefaultPageSx = () => {
    return {
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 330,
        position: 'relative',
    };
}
const Dashboard = () => {
    const permissions = usePermissions();
    return (
        <Box>
            <Grid container>
                <Grid item xs={getResponsiveWidth(12, 12)}
                      alignItems="center">
                    <TradingViewTickerTape/>
                </Grid>
                {permissions.PAWN?.includes('DASHBOARD') && (
                    <Grid item xs={getResponsiveWidth(12, 4)} sx={getGridSx()}>
                        <Paper
                            sx={getDefaultPageSx()}
                        >
                            <PawnKpiSection/>
                        </Paper>
                    </Grid>
                )}
                {permissions.ORDER?.includes('DASHBOARD') && (
                    <Grid item xs={getResponsiveWidth(12, 4)} sx={getGridSx()}>
                        <Paper
                            sx={getDefaultPageSx()}
                        >
                            <OrderKpiSection/>
                        </Paper>
                    </Grid>
                )}
                {permissions.INVENTORY?.includes('DASHBOARD') && (
                    <Grid item xs={getResponsiveWidth(12, 4)} sx={getGridSx()}>
                        <Paper sx={getDefaultPageSx()}>
                            <JewelryKPISection/>
                        </Paper>
                    </Grid>
                )}
                {permissions.PAWN?.includes('CHART') && (
                    <Grid item xs={getResponsiveWidth(12, 4)} sx={getGridSx()}>
                        <Paper sx={{p: 1}}>
                            <PawnChart/>
                        </Paper>
                    </Grid>
                )}
                {permissions.ORDER?.includes('CHART') && (
                    <Grid item xs={getResponsiveWidth(12, 4)} sx={getGridSx()}>
                        <Paper sx={{p: 1}}>
                            <OrderChart/>
                        </Paper>
                    </Grid>
                )}
                {permissions.INVENTORY?.includes('CHART') && (
                    <Grid item xs={getResponsiveWidth(12, 4)} sx={getGridSx()}>
                        <Paper sx={{p: 1}}>
                            <JewelryChart/>
                        </Paper>
                    </Grid>
                )}
                {permissions.COMMON?.includes('ACTIVITY') && (
                    <Grid item xs={getResponsiveWidth(12, 12)} sx={getGridSx()}>
                        <Paper sx={{p: 1}}><RecentActivity/></Paper>
                    </Grid>
                )}
                <Grid item xs={getResponsiveWidth(12, 12)} sx={getGridSx()}>
                    <Paper sx={{p: 1}}>
                        <>
                            <Typography variant="h6" gutterBottom>
                                Chức năng đang phát triển
                            </Typography>
                            <Typography display="block">Bảng giá vàng tivi</Typography>
                            <Divider/>
                            <Typography display="block">Zalo nhắc đóng lãi</Typography>
                            <Divider/>
                            <Typography display="block">Tham khảo giá vàng: Taiem, pnj...</Typography>
                        </>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dashboard;
