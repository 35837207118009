import {ADMINISTRATOR_USER_NAME, API_ENDPOINT, convertDayjsDate, DATE_LONG_FORMAT} from "../../utils/constants";
import {
    createUser,
    loadUsersFailure,
    loadUsersInProgress,
    loadUsersSuccess,
    requestUserSuccess,
    updateUser
} from "./userActions";
import axios from '../../services/axiosInstance'
import {getUserStatusLabel} from "./UserConstant";

export const loadUsers = (pagination = {page: 0, pageSize: 100,}) => async (dispatch, getState) => {
    try {
        dispatch(loadUsersInProgress());
        const response = await axios.get(API_ENDPOINT + `/users?page=${pagination.page}&size=${pagination.pageSize}`);
        const users = await response.data.content.map((user) => ({
            ...user,
            id: user.username,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            translatedStatus: getUserStatusLabel(user.status),
        }));
        const items = users.filter(item => item.username !== ADMINISTRATOR_USER_NAME)
        dispatch(loadUsersSuccess(items));
    } catch (e) {
        const error = {severity: 'error', message: 'Error while loading data!!!'};
        dispatch(loadUsersFailure(error));
    }
}

export const getUserDetail = (username) => async (dispatch, getState) => {
    try {
        dispatch(loadUsersInProgress());
        const response = await axios.get(API_ENDPOINT + `/users/${username}`);
        const user = {
            ...response.data,
            id: response.data.username,
        };
        dispatch(requestUserSuccess());
        return user;
    } catch (e) {
        const error = {severity: 'error', message: 'Error while loading data!!!'};
        dispatch(loadUsersFailure(error));
    }
}

export const isUsernameExist = (username) => async () => {
    try {
        await axios.get(API_ENDPOINT + `/users/${username}`);
    } catch (error) {
        if (error.response.status === 404) return false;
    }
    return true;
}

export const createUserRequest = user => async dispatch => {
    try {
        dispatch(loadUsersInProgress());
        const response = await axios.post(API_ENDPOINT + `/users`, user);
        const createdUser = {
            ...response.data,
            id: response.data.username,
            createdAt: convertDayjsDate(response.data.createdAt).format(DATE_LONG_FORMAT),
            updatedAt: convertDayjsDate(response.data.updatedAt).format(DATE_LONG_FORMAT),
            status: getUserStatusLabel(response.data.status)
        };
        dispatch(createUser(createdUser));
        dispatch(requestUserSuccess())
    } catch (e) {
        console.log("Error while creating user", e);
        dispatch(loadUsersFailure(e));
    }
}

export const updateUserRequest = (username, user) => async dispatch => {
    try {
        const response = await axios.put(API_ENDPOINT + `/users/${username}`, user);
        const updatedUser = {
            ...response.data,
            id: response.data.username,
        };
        dispatch(updateUser(updatedUser));
        return updatedUser;
    } catch (e) {
        console.log("Error while updating user", e);
        dispatch(loadUsersFailure(e));
        throw e;
    }
}
export const resetUserPasswordRequest = (username) => async dispatch => {
    try {
        const response = await axios.post(API_ENDPOINT + `/users/${username}/reset-password`);
        dispatch(requestUserSuccess());
        return response.data;
    } catch (e) {
        console.log("Error while resetting user password", e);
        dispatch(loadUsersFailure(e));
        throw e;
    }
}

export const deactiveUserRequest = (username, user) => async dispatch => {
    try {
        await axios.post(API_ENDPOINT + `/users/${username}/de-active`);
        dispatch(requestUserSuccess());
    } catch (e) {
        console.log("Error while deactivating user", e);
        dispatch(loadUsersFailure(e));
        throw e;
    }
}

export const updateUserPermissions = (username, permissions) => async (dispatch) => {
    try {
        await axios.post(API_ENDPOINT + `/users/${username}/permissions`, permissions);
        dispatch(requestUserSuccess());
    } catch (error) {
        console.error("Error updating permissions:", error.response ? error.response.data : error.message);
        dispatch(loadUsersFailure(error));
        throw error;
    }
};
