import React, {createContext, useContext, useEffect, useState} from "react";
import {getPermissionsFromToken} from "../utils/auth";
import {LOCAL_STORAGE_KEY_TOKEN} from "../utils/constants";
import CircularIndeterminate from "../components/common/CircularIndeterminate";
import PropTypes from "prop-types";

const PermissionContext = createContext();

export const PermissionProvider = ({children}) => {
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPermissions = async () => {
            const token = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
            if (token) {
                try {
                    const perms = await getPermissionsFromToken(token);
                    setPermissions(perms);
                } catch (error) {
                    console.error("Error fetching permissions:", error);
                    setPermissions({});
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchPermissions();
    }, []);
    if (loading) {
        return <CircularIndeterminate/>;
    }
    return (
        <PermissionContext.Provider value={permissions}>
            {children}
        </PermissionContext.Provider>
    );
};

PermissionProvider.propTypes = {
    children: PropTypes.element,
};
export const usePermissions = () => {
    return useContext(PermissionContext);
};
