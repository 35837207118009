import React, {useEffect, useState} from "react";
import axios from '../../services/axiosInstance';
import {Box, Button, Grid} from "@mui/material";
import {
    calculateAge,
    CUSTOMER_COLUMNS,
    CUSTOMERS_API_ENDPOINT,
    DEFAULT_CUSTOMER_DATA,
    getCustomerDetailsById
} from "./CustomerConstants";
import {getResponsiveWidth, handleApiError, handleErrorMessage} from "../../utils/constants";
import CreateCustomer from "./CreateCustomer";
import UpdateCustomer from "./UpdateCustomer";
import {useAlert} from "../../providers/AlertProvider";
import CustomDataGrid from "../../components/grid/CustomDataGrid";
import {usePermissions} from "../../providers/PermissionProvider";

const CustomerGrid = () => {
    const permissions = usePermissions();
    const {openAlert} = useAlert();
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(true);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [customerData, setCustomerData] = useState(DEFAULT_CUSTOMER_DATA);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 100,
        totalRowCount: 100
    });
    const fetchCustomers = async (pageNumber, pageSize, requestFilter) => {
        try {
            setLoading(true);
            const requestBody = requestFilter || {
                visitingGuest: false,
            }
            const response = await axios.post(
                `${CUSTOMERS_API_ENDPOINT}/find?page=${pageNumber}&size=${pageSize}&sort=customerId,desc`, requestBody
            );
            // Update the page state
            setPagination(prevPagination => ({
                ...prevPagination,
                totalRowCount: response?.data?.totalElements,
                pageSize: response?.data?.size,
                totalPages: response.data.totalPages,
                currentPage: response.data.number,
            }));
            const formattedCustomers = response.data.content.map((customer) => ({
                ...customer,
                id: customer.customerId,
                status: customer.status,
                dateOfBirth: customer.dateOfBirth,
                gender: customer.gender,
                phone: customer.phone,
                age: customer.dateOfBirth ? calculateAge(customer.dateOfBirth) : '',
                visitingGuest: customer.visitingGuest ? 'Đúng' : 'Không',
            }))
            setCustomers(formattedCustomers);
        } catch (error) {
            console.error("Error while fetching data: ", error);
            handleApiError(error, openAlert)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            await fetchCustomers(pagination.page, pagination.pageSize);
        }
        fetchData();
        // eslint-disable-next-line
    }, [pagination.page, pagination.pageSize]);

    const handleChangePage = async (page, event) => {
        setPagination((prevPage) => ({
            ...prevPage,
            page: page?.page,
            pageSize: page?.pageSize,
        }));
    };
    const handleAddNewClick = () => {
        setOpenAddDialog(true);
    };
    const getCustomerDetails = async (customerId) => {
        try {
            setLoading(true);
            const formattedCustomer = await getCustomerDetailsById(customerId);
            setCustomerData(formattedCustomer);
        } catch (error) {
            console.error("Error while fetching data: ", error);
            handleApiError(error, openAlert)
        } finally {
            setLoading(false);
        }
    }
    const handleUpdateClick = async () => {
        if (!selectedCustomer) return;
        const customerId = selectedCustomer.customerId;
        await getCustomerDetails(customerId);
        setOpenUpdateDialog(true);
    };
    const handleSetSelectedRow = async (selectedRow, rows) => {
        try {
            const selectedId = selectedRow.length === 1 ? selectedRow[0] : 0;
            const rowData = rows.find((row) => row.customerId === selectedId);
            setSelectedCustomer(rowData)
        } catch (error) {
            console.log("Error at process selected item", selectedRow);
        }
    };
    const handleFindById = async (customerId) => {
        const customer = await getCustomerDetails(customerId)
        if (customer) {
            setOpenUpdateDialog(true);
        }
    };
    const handleSearch = async (searchFilter) => {
        const searchResult = await fetchCustomers(pagination.page, pagination.pageSize, searchFilter);
        if (searchResult && searchResult.totalElements === 1 && searchResult.content[0].jewelryId) {
            await handleFindById(searchResult.content[0].customerId);
        }
    };
    return (
        <div>
            <Box>
                <Grid container spacing={2} sx={{paddingBottom: 2}}>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        {permissions.CUSTOMER?.includes('CREATE') && (
                            <Button variant="contained" onClick={handleAddNewClick} fullWidth sx={{minWidth: 170}}>
                                Tạo mới
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        {permissions.CUSTOMER?.includes('UPDATE') && (
                            <Button variant="contained" onClick={handleUpdateClick} disabled={!selectedCustomer}
                                    sx={{minWidth: 170}}
                                    fullWidth>
                                Cập nhật
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <CustomDataGrid
                rows={customers}
                pagination={pagination}
                loading={loading}
                setSelectedRows={handleSetSelectedRow}
                columns={CUSTOMER_COLUMNS}
                onSearch={handleSearch}
                onPaginationModelChange={handleChangePage}
                onFindById={handleFindById}
                pageSummary={undefined}
                onExportData={() => {
                    handleErrorMessage("Chức năng đang phát triển.", openAlert);
                }}
            />
            <CreateCustomer open={openAddDialog} onClose={async () => {
                setOpenAddDialog(false);
                setOpenUpdateDialog(false);
                await fetchCustomers(pagination.page, pagination.pageSize);
            }}/>
            <UpdateCustomer open={openUpdateDialog} onClose={async () => {
                setOpenUpdateDialog(false);
                await fetchCustomers(pagination.page, pagination.pageSize);
            }} sourceCustomer={customerData}/>
        </div>
    );
}
export default CustomerGrid;
