import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {logout} from "../../utils/auth";
import {useAlert} from "../../providers/AlertProvider";
import LogoutIcon from '@mui/icons-material/Logout';
import {handleSuccessMessage} from "../../utils/constants";
import CenteredMessageLayout from "../../components/common/CenteredMessageLayout";

export default function Logout() {
    const {openAlert} = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        async function processLogout() {
            try {
                await logout();
                handleSuccessMessage("Bạn đã đăng xuất hệ thống thành công!", openAlert);
            } catch (error) {
                console.log(error);
            }
        }

        processLogout().then(() => console.debug('Process logout from system'));
    }, [navigate, openAlert]);

    return (
        <CenteredMessageLayout>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LogoutIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Đăng xuất hệ thống
                </Typography>
            </Box>
        </CenteredMessageLayout>
    );
}
