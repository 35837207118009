import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {isUserAuthenticated} from "../utils/auth";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const authenticated = isUserAuthenticated() || false;
        setIsAuthenticated(authenticated);
    }, []);
    return (
        <AuthContext.Provider value={isAuthenticated}>
            {children}
        </AuthContext.Provider>
    );
};
AuthProvider.propTypes = {
    children: PropTypes.element.isRequired,
}
export const useAuth = () => useContext(AuthContext);
