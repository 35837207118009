import dayjs from "dayjs";
import {
    API_ENDPOINT,
    convertDayjsDate,
    DATE_LONG_FORMAT,
    DATE_SHORT_FORMAT,
    formatCurrency
} from "../../utils/constants";
import axios from '../../services/axiosInstance';

export const ORDER_COLUMNS = [
    {field: 'orderId', headerName: 'Mã số', width: 120},
    {field: 'customerId', headerName: 'Mã KH', width: 120},
    {field: 'customerName', headerName: 'Tên KH', width: 170},
    {
        field: 'orderDate', headerName: 'Ngày tạo đơn', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_SHORT_FORMAT)
    },
    {
        field: 'orderType', headerName: 'Loại đơn', width: 150,
        valueFormatter: (params) => getOrderTypeLabel(params.value)
    },
    {
        field: 'buyAmount', headerName: 'Tiền vàng mới', width: 170,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'sellAmount', headerName: 'Tiền vàng khách', width: 180,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'discount', headerName: 'Giảm giá', width: 150,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'totalAmount', headerName: 'Tổng tiền', width: 150,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {field: 'orderDescription', headerName: 'Mô tả', width: 300},
    {field: 'createdBy', headerName: 'Người tạo', width: 150},
    {
        field: 'createdAt', headerName: 'Ngày tạo', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
    {field: 'updatedBy', headerName: 'Người cập nhật', width: 150},
    {
        field: 'updatedAt', headerName: 'Ngày cập nhật', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
];

export const DEFAULT_ORDER_DATA = {
    customerId: 0,
    customerName: "",
    orderDate: dayjs(new Date()),
    orderType: '',
    orderDescription: "",
    discount: 0,
    sellAmount: 0,
    buyAmount: 0,
    totalAmount: 0,
    visitingGuest: false,
    buyItems: [],
    sellItems: [],
    extraBuyJewelries: [],
    extraGoldWeight: 0,
    extraGoldAmount: 0,
};

export const ORDER_TYPE = {
    SELL_ORDER: {code: 'SELL', label: "Bán"},
    BUY_ORDER: {code: 'BUY', label: "Mua"},
    EXEC_ORDER: {code: 'EXCHANGE', label: "Trao đổi"}
};

export const ORDER_ITEM_TYPE = {
    BUYING_ITEM: {code: 'BUYING_ITEM', label: "Bán"},
    SELLING_ITEM: {code: 'SELLING_ITEM', label: "Mua"}
};
export const ORDERS_API_ENDPOINT = API_ENDPOINT + '/orders';
export const getOrderTypeByCode = (code) => {
    return Object.values(ORDER_TYPE).find((type) => type.code === code);
};
export const getOrderTypeLabel = (code) => {
    const orderType = getOrderTypeByCode(code);
    return orderType ? orderType.label : code;
};
export const fetchOrderDetails = async (orderId) => {
    try {
        const response = await axios.get(`${ORDERS_API_ENDPOINT}/${orderId}`);
        return response.data;
    } catch (error) {
        console.error("Error while fetching order details:", error)
        throw error;
    }
};

export const getAmountDes = (amount = 0) => {
    if (amount >= 0) return "(Thu)";
    return "(Chi)";
}
export const getPositiveAmount = (amount = 0) => {
    return Math.abs(amount);
}
export const findOrders = async (page, filter, sort) => {
    try {
        const {pageNumber, pageSize} = page || {pageNumber: 0, pageSize: 100};
        const sortBy = sort || "orderId,desc";
        const reqBody = filter || {fromDate: '', toDate: ''}
        const response = await axios.post(
            `${ORDERS_API_ENDPOINT}/find?page=${pageNumber}&size=${pageSize}&sort=${sortBy}`, reqBody);
        return response.data;
    } catch (error) {
        console.error("Error while fetching data: ", error);
        throw error;
    }
}

export const ORDER_SUMMARY_COLUMNS = [
    {field: 'orderId', headerName: 'Mã số', width: 120},
    {
        field: 'orderDate', headerName: 'Ngày tạo đơn',
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_SHORT_FORMAT)
    },
    {
        field: 'orderType', headerName: 'Loại đơn',
        valueFormatter: (params) => getOrderTypeLabel(params.value)
    },
    {
        field: 'buyAmount', headerName: 'Tiền vàng mới', width: 150,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'sellAmount', headerName: 'Tiền vàng khách', width: 150,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'discount', headerName: 'Giảm giá', width: 150,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'totalAmount', headerName: 'Tổng tiền', width: 150,
        valueFormatter: (params) => formatCurrency(params.value)
    },
];


export const SELECTED_ORDER_ACTIONS = [
    {label: 'Thông tin', code: 'VIEW', actStatus: ['CONFIRMED', 'DRAFT'], permission: 'VIEW'},
    {label: 'Xóa', code: 'DELETE', actStatus: ['CONFIRMED', 'DRAFT'], permission: 'DELETE'},
    {label: 'Biên nhận', code: 'RECEIPT', actStatus: ['CONFIRMED'], permission: 'RECEIPT'},
    {label: 'Khách hàng', code: 'CUSTOMER', actStatus: ['CONFIRMED', 'DRAFT'], customerPermission: 'UPDATE'},
    {label: 'Xuất HĐĐT', code: 'INVOICE', actStatus: ['CONFIRMED'], invoicePermission: 'CREATE'},
    {label: 'Trạng thái', code: 'UPDATED_STATUS', actStatus: ['CONFIRMED'], permission: 'UPDATE', administration: true},
];
export const CREATE_ORDER_ACTIONS = [
    {label: 'Đơn Mua', code: 'BUY', actStatus: []},
    {label: 'Đơn Bán', code: 'SELL', actStatus: []},
    {label: 'Trao Đổi', code: 'EXCHANGE', actStatus: []},
];

export const DEFAULT_GOLD_TYPE = "610";


export const CUSTOMER_JEWELRY_ITEM_MODE = {
    COMPENSATE: {code: 'COMPENSATE', label: "Vàng bù"},
    RESIDUAL: {code: 'RESIDUAL', label: "Vàng dư"},
    EXCHANGE: {code: 'EXCHANGE', label: "Vàng đổi"},
};

export const getExtraBuyJewelries = (buyItems = []) => {
    return buyItems?.filter((item) =>
        (item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.COMPENSATE.code || item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.code))
        .map((item) => ({
            ...item,
            id: item.itemId,
        }));
}

export const getTotalWeight = (goldItems = []) => {
    return Number(goldItems.reduce((acc, item) => acc + parseFloat(item.goldWeight || 0), 0).toFixed(3));
}

export const getTotalAmount = (goldItems = []) => {
    return goldItems.reduce((sum, item) => sum + (item.amount || 0), 0);
}
export const ORDER_DEFAULT_FILTER = {fromDate: 0, toDate: 0};
export const fetchCustomerOrders = async (customerId, pageNumber, pageSize) => {
    const filter = {
        customerId: customerId
    }
    const response = await findOrders({pageNumber: pageNumber, pageSize: pageSize}, filter, undefined);
    return response.content.map((order) => ({
        ...order,
        id: order.orderId,
        orderDate: order.orderDate,
        totalAmount: order.totalAmount,
        sellAmount: order.sellAmount,
        buyAmount: order.buyAmount,
        discount: order.discount,
        orderType: order.orderType,
    }));
}
