import * as React from 'react';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import {fetchSystemInfo, handleErrorMessage, handleSuccessMessage, ROUTE_LOGIN} from "../../utils/constants";
import {CHANGE_PWD_API_ENDPOINT, removeLoginData, storeAuthenticatedInfo} from "../../utils/auth";
import {useAlert} from "../../providers/AlertProvider";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CenteredMessageLayout from "../../components/common/CenteredMessageLayout";
import PasswordIcon from '@mui/icons-material/Password';

const defaultChangePwdData = {
    password: '',
    updatePassword: '',
    rePassword: '',
    username: ''
}
export default function ChangePassword() {
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showReNewPassword, setShowReNewPassword] = useState(false);
    const {openAlert} = useAlert();
    const navigate = useNavigate();
    const location = useLocation();
    const {loginData, passwordChangeToken} = location.state || {};
    const [changePwdData, setChangePwdData] = useState(defaultChangePwdData);
    const [updatePasswordValid, setUpdatePasswordValid] = useState(true);
    const [rePasswordValid, setRePasswordValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);

    useEffect(() => {
        if (!loginData || !passwordChangeToken) {
            removeLoginData();
            window.location.href = ROUTE_LOGIN;
        }
        setChangePwdData((prev) => ({
            ...prev,
            username: loginData.username,
            rememberMe: loginData.rememberMe,
        }))
    }, [navigate, loginData, passwordChangeToken]);
    const handleSubmit = async (event) => {
        let validated = true;
        if (changePwdData.password === '') {
            setPasswordValid(false);
            validated = false;
        } else {
            setPasswordValid(true);
        }
        if (changePwdData.updatePassword === '') {
            setUpdatePasswordValid(false);
            validated = false;
        } else {
            setUpdatePasswordValid(true);
        }
        if (changePwdData.rePassword === '' || changePwdData.rePassword !== changePwdData.updatePassword) {
            setRePasswordValid(false);
            validated = false;
        } else {
            setRePasswordValid(true);
        }
        if (validated) {
            let message = 'Đổi mật khẩu thành công!';
            try {
                const response = await axios.post(CHANGE_PWD_API_ENDPOINT, changePwdData,
                    {headers: {"Authorization": `Bearer ${passwordChangeToken}`}});
                const {access_token, full_name} = response.data;
                await storeAuthenticatedInfo(access_token, loginData.username, full_name);
                await fetchSystemInfo(openAlert);
                handleSuccessMessage(message, openAlert);
                navigate("/")
                window.location.reload();
            } catch (error) {
                console.error('Login failed:', error);
                if (error.response.status === 403) {
                    message = 'Lỗi! Không có quyền'
                } else if (error.response.status === 400) {
                    message = 'Lỗi! Mật khẩu không đúng'
                }
                handleErrorMessage(message, openAlert);
            }
        }
    };
    return (
        <CenteredMessageLayout>
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <PasswordIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Xin chào: {loginData.username}!
            </Typography>
            <Typography component="h1" variant="h5">
                Y/C thay đổi mật khẩu
            </Typography>
            <Box sx={{mt: 1, width: '100%', maxWidth: 400}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Mật khẩu cũ"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                        setChangePwdData((prev) => ({
                            ...prev,
                            password: e.target.value,
                        }));
                    }}
                    error={!passwordValid}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Nhấn vào hiện mật khẩu"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Mật khẩu mới"
                    type={showNewPassword ? "text" : "password"}
                    onChange={(e) => {
                        setChangePwdData((prev) => ({
                            ...prev,
                            updatePassword: e.target.value,
                        }));
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Nhấn vào hiện mật khẩu"
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    onMouseDown={() => setShowNewPassword(!showNewPassword)}
                                >
                                    {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    error={!updatePasswordValid}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Nhập lại mật khẩu"
                    type={showReNewPassword ? "text" : "password"}
                    onChange={(e) => {
                        setChangePwdData((prev) => ({
                            ...prev,
                            rePassword: e.target.value,
                        }));
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Nhấn vào hiện mật khẩu"
                                    onClick={() => setShowReNewPassword(!showReNewPassword)}
                                    onMouseDown={() => setShowReNewPassword(!showReNewPassword)}
                                >
                                    {showReNewPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    error={!rePasswordValid}
                />
                <CustomLoadingButton buttonLabel="Đổi mật khẩu"
                                     onClick={(event) => handleSubmit(event)}
                                     fullWidth
                                     variant="contained"
                                     sx={{mt: 3, mb: 2}}/>
            </Box>
        </CenteredMessageLayout>
    );
}
