import {DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbar} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {Box, LinearProgress} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
    convertDayjsDate,
    DATE_LONG_FORMAT,
    DEFAULT_ERROR_MESSAGE,
    GoldType_Unknown,
    handleErrorMessage,
    handleSuccessMessage,
    SYSTEMS_API_ENDPOINT
} from "../../utils/constants";
import axios from '../../services/axiosInstance';
import CustomNoRowsOverlay from "../../components/grid/CustomNoRowsOverlay";
import {useAlert} from "../../providers/AlertProvider";
import GoldTypeDetails from "./GoldTypeDetails";
import {usePermissions} from "../../providers/PermissionProvider";
import PropTypes from "prop-types";
import {randomId} from "@mui/x-data-grid-generator";

const GoldTypeGrid = ({sourceData = [], loading = false, onUpdateCompleted}) => {
    const {openAlert} = useAlert();
    const permissions = usePermissions();
    const [types, setTypes] = useState(sourceData)
    const [rowModelModes, setRowModelModes] = useState({});
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [editData, setEditData] = useState({});
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleEditClick = (id) => () => {

        const editedRow = types.find((row) => row.id === id);
        if (editedRow) {
            setEditData({
                ...editData,
                id: editedRow.id,
                code: editedRow.code,
                label: editedRow.label,
                order: editedRow.order,
                silverType: editedRow.silverType,
            });
            setOpenDetailsDialog(true);
        }
    };

    const handleSaveClick = (id) => () => {
        setRowModelModes({...rowModelModes, [id]: {mode: GridRowModes.View}});
    };

    const handleCancelClick = (id) => () => {
        setRowModelModes({
            ...rowModelModes,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = types.find((row) => row.id === id);
        if (editedRow.isNew) {
            setTypes(types.filter((row) => row.id !== id));
        }
    };
    const processUpdate = async (type) => {
        try {
            await axios.put(`${SYSTEMS_API_ENDPOINT}/gold-types/${type.id}`, type);
            handleSuccessMessage('Thông tin [' + type.label + '] được lưu thành công', openAlert);
        } catch (error) {
            handleErrorMessage(DEFAULT_ERROR_MESSAGE, openAlert);
        } finally {
            await onUpdateCompleted();
        }
    };
    const handleItemUpdate = async (updatedRow) => {
        await processUpdate(updatedRow);
        setOpenDetailsDialog(false);
    }
    const processRowUpdate = async (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setTypes(types.map((row) => (row.id === newRow.id ? updatedRow : row)));
        await processUpdate(updatedRow);
        return updatedRow;
    };

    const handleBuyItemRowModelChange = (newRowModesModel) => {
        setRowModelModes(newRowModesModel);
    };

    useEffect(() => {
        const items = sourceData.filter(item => item.code !== GoldType_Unknown.code)
        setTypes(items);
    }, [sourceData]);
    const COLUMNS = [
        {
            field: 'actions',
            type: 'actions',
            align: 'center',
            headerName: 'Tùy chọn',
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModelModes[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Lưu"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                            key={randomId()}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Hủy"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                            key={randomId()}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Sửa"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                        key={randomId()}
                    />,
                ];
            },
        },
        {field: 'id', headerName: 'M.số', width: 60},
        {field: 'label', headerName: 'Hiển thị', width: 120, editable: true},
        {field: 'code', headerName: 'Ký hiệu', width: 70, editable: false},
        {field: 'order', headerName: 'Thứ tự', width: 70, type: 'number', editable: true},
        {field: 'createdBy', headerName: 'Người tạo', width: 100, editable: false},
        {
            field: 'createdAt', headerName: 'Ngày tạo', width: 150, editable: false,
            valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
        },
        {field: 'updatedBy', headerName: 'Người cập nhật', width: 150, editable: false},
        {
            field: 'updatedAt', headerName: 'Ngày cập nhật', width: 150, editable: false,
            valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
        },
    ];
    const getFilteredColumns = () => {
        if (permissions.SYSTEMS?.includes('UPDATE_GOLD_TYPE')) {
            return COLUMNS;
        }
        return COLUMNS.filter(column => column.field !== 'actions');
    };
    return (
        <div>
            <Box sx={{width: 1, overflow: "auto", height: 450}}>
                <DataGrid
                    autoHeight={false}
                    editMode="row"
                    rowModesModel={rowModelModes}
                    onRowModesModelChange={handleBuyItemRowModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnFilter
                    disableDensitySelector
                    slots={{
                        toolbar: GridToolbar,
                        loadingOverlay: LinearProgress,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        toolbar: {
                            csvOptions: {disableToolbarButton: true},
                            printOptions: {disableToolbarButton: true},
                            showQuickFilter: true,
                        },
                    }}
                    loading={loading}
                    rows={types}
                    columns={getFilteredColumns()}
                />
            </Box>
            <GoldTypeDetails onClose={() => {
                setOpenDetailsDialog(false);
            }} open={openDetailsDialog} sourceData={editData} onItemUpdated={handleItemUpdate}/>
        </div>);
}
GoldTypeGrid.propTypes = {
    loading: PropTypes.bool,
    sourceData: PropTypes.array,
    onUpdateCompleted: PropTypes.func
};
export default GoldTypeGrid;
