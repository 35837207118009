import {Box, Button, Grid} from "@mui/material";
import React, {useState} from "react";
import {
    calculateBuyAmount,
    calculateGoldWeight,
    getGoldBrand,
    getGoldPriceByType,
    getGoldType,
    getResponsiveWidth,
    GoldBrand_Unknown,
    handleSuccessMessage,
    handleWarningMessage,
    isArrayNotEmpty,
    readGoldWeightUnits
} from "../../utils/constants";
import {
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer
} from "@mui/x-data-grid";
import {randomId} from "@mui/x-data-grid-generator";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    CUSTOMER_JEWELRY_ITEM_MODE,
    DEFAULT_GOLD_TYPE,
    getTotalWeight,
    ORDER_ITEM_TYPE,
    ORDER_TYPE
} from "./OrderConstant";
import BuyItemDetails from "./BuyItemDetails";
import {useAlert} from "../../providers/AlertProvider";
import YesNoAlertDialog from "../../components/dialog/YesNoAlertDialog";
import PropTypes from "prop-types";

const buyItemDefault = {
    type: ORDER_ITEM_TYPE.BUYING_ITEM.code,
    orderType: ORDER_TYPE.EXEC_ORDER.code,
    name: 'Dẻ',
    goldType: DEFAULT_GOLD_TYPE,
    goldBrand: GoldBrand_Unknown.code,
    goldWeight: 0,
    gemWeight: 0,
    totalWeight: 0,
    price: getGoldPriceByType(DEFAULT_GOLD_TYPE).buy,
    sellPrice: getGoldPriceByType(DEFAULT_GOLD_TYPE).sell,
    buyPrice: getGoldPriceByType(DEFAULT_GOLD_TYPE).buy,
    compPrice: 0,
    amount: 0,
    excPriceSelected: false,
    isCloneMode: false
}
const BuyItem = ({orderData, setOrderData}) => {
    const [buyItemsModes, setBuyItemsModes] = useState({});
    const {openAlert} = useAlert();

    function EditToolbar() {
        const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
        const [buyItemData, setBuyItemData] = useState(buyItemDefault);
        const [openNoticeDialog, setOpenNoticeDialog] = useState(false);
        const [noticeTitle, setNoticeTitle] = useState('');
        const [noticeContent, setNoticeContent] = useState('');
        const handleNoticeClose = async () => {
            setOpenNoticeDialog(false);
        };
        const initBuyItem = (id, goldType = DEFAULT_GOLD_TYPE) => {
            return {
                ...buyItemDefault,
                goldType: goldType,
                price: getGoldPriceByType(goldType).buy,
                sellPrice: getGoldPriceByType(goldType).sell,
                buyPrice: getGoldPriceByType(goldType).buy,
                id,
                isNew: true,
                orderType: orderData.orderType,
            }
        }

        const addJewelryItem = (goldType = DEFAULT_GOLD_TYPE) => {
            setBuyItemData(initBuyItem(randomId(), goldType));
            setOpenDetailsDialog(true);
        };

        const mixGoldTypeItems = (sourceItems = []) => {
            const goldTypeMap = sourceItems.reduce((map, item) => {
                if (!map.has(item.goldType)) {
                    map.set(item.goldType, item);
                }
                return map;
            }, new Map());
            return goldTypeMap.size > 1;
        };
        const onExchangeJewelryClick = () => {
            setNoticeTitle("Thông báo quan trọng!!!");
            setNoticeContent("Trường hợp khách có nhiều hơn một dẻ được đổi, vui lòng cộng tổng khối lượng rồi nhập (chỉ) một lần!. \n Hệ thống chưa hỗ trợ đổi dẻ khác tuổi");
            setOpenNoticeDialog(true);
        }
        const addExchangeJewelryItem = () => {
            if (isArrayNotEmpty(orderData.sellItems) && !mixGoldTypeItems(orderData.sellItems)) {
                const goldType = orderData.sellItems[0].goldType;
                const buyItem = initBuyItem(randomId(), goldType);
                setBuyItemData({...buyItem, isCloneMode: true});
                setOpenDetailsDialog(true);
            } else if (!isArrayNotEmpty(orderData.sellItems)) {
                handleWarningMessage("Món hàng bán đang trống! Phải thêm món hàng bán vào giỏ hàng trước!", openAlert);
            } else {
                console.debug("TODO to check if the sell item has more than one goldType. Show dialog to select gold type before process");
                handleWarningMessage("Món hàng khác tuổi.  Hệ thống chưa hỗ trợ!", openAlert);
            }
        };
        const handleAcceptNotice = async () => {
            setOpenNoticeDialog(false);
            addExchangeJewelryItem();
        };
        const buildBuyItem = (goldWeight, buyItem, indexNumber) => {
            return {
                ...buyItem,
                id: randomId(),
                index: indexNumber,
                goldWeight: goldWeight,
                totalWeight: 0,
                gemWeight: 0,
                isCloneMode: false,
            };
        }

        const handleAddItem = (addingItem) => {
            const goldType = addingItem.goldType;
            let indexNumber = orderData.buyItems?.length + 1;
            if (addingItem.isCloneMode === true) {
                // Thêm dẻ tương đương so món hàng bán ra, tính dẻ dư, còn thiếu
                const totalBuyGoldWeight = addingItem.goldWeight;
                const totalSellGoldWeight = getTotalWeight(orderData.sellItems);
                const goldWeightDifference = calculateGoldWeight(totalSellGoldWeight, totalBuyGoldWeight);
                const exchangeGoldWeight = goldWeightDifference < 0 ? totalSellGoldWeight : totalBuyGoldWeight;
                const updatedExchangeItem = buildBuyItem(exchangeGoldWeight, addingItem, indexNumber);
                const exchangePrice = addingItem.price;
                const exchangeCompPrice = addingItem.compPrice;
                const exchangeAmount = calculateBuyAmount(exchangeGoldWeight, exchangePrice, exchangeCompPrice);
                const exchangeItem = {
                    ...updatedExchangeItem,
                    price: exchangePrice,
                    amount: exchangeAmount,
                    itemMode: CUSTOMER_JEWELRY_ITEM_MODE.EXCHANGE.code,
                    name: CUSTOMER_JEWELRY_ITEM_MODE.EXCHANGE.label,
                };

                setOrderData((prevData) => ({
                    ...prevData,
                    buyItems: [...prevData.buyItems, exchangeItem],
                }));
                indexNumber += 1;
                // Handle any remaining gold weight difference
                if (goldWeightDifference > 0) {
                    const goldWeight = goldWeightDifference;
                    const updatedCompensateItem = buildBuyItem(goldWeight, addingItem, indexNumber);
                    const price = getGoldPriceByType(goldType).sell;
                    const compPrice = 0;
                    const amount = calculateBuyAmount(goldWeight, price, compPrice);
                    const compensateItem = {
                        ...updatedCompensateItem,
                        itemMode: CUSTOMER_JEWELRY_ITEM_MODE.COMPENSATE.code,
                        price: price,
                        amount: amount,
                        name: CUSTOMER_JEWELRY_ITEM_MODE.COMPENSATE.label,
                    };

                    setOrderData((prevData) => ({
                        ...prevData,
                        buyItems: [...prevData.buyItems, compensateItem],
                    }));
                } else if (goldWeightDifference < 0) {
                    const goldWeight = Math.abs(goldWeightDifference);
                    const updatedResidualItem = buildBuyItem(goldWeight, addingItem, indexNumber);
                    const price = addingItem.buyPrice ? parseInt(addingItem.buyPrice) : getGoldPriceByType(goldType).buy;
                    const compPrice = 0;
                    const amount = calculateBuyAmount(goldWeight, price, compPrice);
                    const residualItem = {
                        ...updatedResidualItem,
                        itemMode: CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.code,
                        price: price,
                        amount: amount,
                        name: CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.label,
                    };

                    setOrderData((prevData) => ({
                        ...prevData,
                        buyItems: [...prevData.buyItems, residualItem],
                    }));
                }
                handleSuccessMessage(`Dẻ đổi được thêm thành công`, openAlert);
            } else {
                // Thêm Dẻ thường
                const indexNumber = orderData.buyItems?.length + 1;
                const jewelryItem = {
                    ...addingItem,
                    id: randomId(),
                    index: indexNumber,
                    isNew: true
                };
                setOrderData((prevData) => ({
                    ...prevData,
                    buyItems: [...prevData.buyItems, jewelryItem],
                }));
                handleSuccessMessage(`Dẻ khách [${jewelryItem.name}] được thêm thành công`, openAlert);
            }
        };

        return (
            <>
                <GridToolbarContainer>
                    <Grid container spacing={2}>
                        <Grid item xs={getResponsiveWidth(12, 12)} container>
                            <Grid item xs={12}>
                                {(ORDER_TYPE.EXEC_ORDER.code === orderData.orderType && orderData.sellItems.length > 0) && (
                                    <Button color="primary" size="small"
                                            onClick={() => onExchangeJewelryClick()}>
                                        Thêm dẻ đổi tương ứng
                                    </Button>)}
                            </Grid>
                            {Object.values(getGoldType()).some((goldType) => goldType.code === DEFAULT_GOLD_TYPE) && (
                                <Grid item xs={4}>
                                    <Button color="primary" size="small"
                                            onClick={() => addJewelryItem(DEFAULT_GOLD_TYPE)}>
                                        Thêm dẻ 610
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '980') && (
                                <Grid item xs={4}>
                                    <Button color="primary" size="small" onClick={() => addJewelryItem('980')}>
                                        Thêm dẻ 980
                                    </Button>
                                </Grid>
                            )}
                            {Object.values(getGoldType()).some((goldType) => goldType.code === '9999') && (
                                <Grid item xs={4}>
                                    <Button color="primary" size="small" onClick={() => addJewelryItem('9999')}>
                                        Thêm dẻ 9999
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={getResponsiveWidth(12, 12)} container
                              style={{textAlign: 'right', fontWeight: 'bold'}}>
                            <Grid item xs={12}>
                                {(orderData.buyItems?.filter((item) => (item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.EXCHANGE.code)).length > 0) && (
                                    <p>{CUSTOMER_JEWELRY_ITEM_MODE.EXCHANGE.label}: {orderData.buyItems?.filter((item) => (item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.EXCHANGE.code)).length} món{orderData.buyItems.filter((item) => (item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.EXCHANGE.code)) ? '/TL: ' + readGoldWeightUnits(getTotalWeight(orderData.buyItems.filter((item) => (item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.EXCHANGE.code)))) : ''}</p>
                                )}
                                {(orderData.buyItems?.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.code).length > 0) && (
                                    <p>{CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.label}: {orderData.buyItems?.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.code).length} món{orderData.buyItems.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.code) ? '/TL: ' + readGoldWeightUnits(getTotalWeight(orderData.buyItems.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.RESIDUAL.code))) : ''}</p>
                                )}
                                {(orderData.buyItems?.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.COMPENSATE.code).length > 0) && (
                                    <p>{CUSTOMER_JEWELRY_ITEM_MODE.COMPENSATE.label}: {orderData.buyItems?.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.COMPENSATE.code).length} món{orderData.buyItems.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.COMPENSATE.code) ? '/TL: ' + readGoldWeightUnits(getTotalWeight(orderData.buyItems.filter((item) => item.itemMode === CUSTOMER_JEWELRY_ITEM_MODE.COMPENSATE.code))) : ''}</p>
                                )}
                                {(orderData.buyItems?.filter((item) => item.itemMode === undefined || item.itemMode === '').length > 0) && (
                                    <p>Dẻ: {orderData.buyItems?.filter((item) => (item.itemMode === undefined || item.itemMode === '')).length} món{orderData.buyItems.filter((item) => (item.itemMode === undefined || item.itemMode === '')) ? '/TL: ' + readGoldWeightUnits(getTotalWeight(orderData.buyItems.filter((item) => (item.itemMode === undefined || item.itemMode === '')))) : ''}</p>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
                <BuyItemDetails onClose={() => {
                    setOpenDetailsDialog(false);
                }} open={openDetailsDialog} sourceJewelry={buyItemData} onItemAdded={handleAddItem}/>
                <YesNoAlertDialog open={openNoticeDialog} onClose={handleNoticeClose} title={noticeTitle}
                                  content={noticeContent}
                                  onAccept={handleAcceptNotice}/>
            </>
        );
    }

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleSaveClick = (id) => () => {
        setBuyItemsModes({...buyItemsModes, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        const curItems = orderData.buyItems.filter((row) => row.id !== id);
        setOrderData((prev) => ({...prev, buyItems: curItems}));
    };

    const handleCancelClick = (id) => () => {
        setBuyItemsModes({
            ...buyItemsModes,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = orderData.buyItems.find((row) => row.id === id);
        if (editedRow.isNew) {
            const curItems = orderData.buyItems.filter((row) => row.id !== id);
            setOrderData((prev) => ({...prev, buyItems: curItems}));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        const curItems = orderData.buyItems.map((row) => (row.id === newRow.id ? updatedRow : row));
        setOrderData((prev) => ({...prev, buyItems: curItems}));
        return updatedRow;
    };

    const handleBuyItemRowModelChange = (newRowModesModel) => {
        setBuyItemsModes(newRowModesModel);
    };

    const calculateTotalAmount = (params) => {
        return calculateBuyAmount(params.row.goldWeight, params.row.price, params.row.compPrice);
    }
    const itemColumns = [
        {
            field: 'actions',
            type: 'actions',
            align: 'center',
            headerName: 'Tùy chọn',
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = buyItemsModes[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            key={randomId()}
                            label="Lưu"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            key={randomId()}
                            label="Hủy"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        key={randomId()}
                        label="Xóa"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {field: 'index', headerName: 'Số TT', width: 70, editable: false},
        {field: 'name', headerName: 'Tên', width: 100, editable: true},
        {
            field: 'goldType',
            headerName: 'Loại Dẻ',
            width: 150,
            type: 'singleSelect',
            valueOptions: Object.values(getGoldType()).map((type) => (
                {value: type.code, label: type.label}
            )),
        },
        {
            field: 'goldBrand',
            headerName: 'Chành',
            width: 120,
            type: 'singleSelect',
            valueOptions: Object.values(getGoldBrand()).map((brand) => (
                {value: brand.code, label: brand.label}
            )),
        },
        {
            field: 'goldWeight',
            headerName: 'TL Vàng',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'gemWeight',
            headerName: 'TL Hột',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'price',
            headerName: 'Đơn giá',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'compPrice',
            headerName: 'Giá bù(vnđ/chỉ)',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            valueFormatter: (param) => {
                return param.value ? Number(param.value).toLocaleString() : 0;
            },
        },
        {
            field: 'amount',
            headerName: 'Thành tiền',
            type: 'number',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            valueSetter: (params) => {
                return {
                    ...params.row,
                    amount: calculateTotalAmount(params)
                };
            },
        },
    ];
    return (
        <Box sx={{width: 1}}>
            <DataGrid
                rows={orderData.buyItems}
                columns={itemColumns}
                editMode="row"
                rowModesModel={buyItemsModes}
                onRowModesModelChange={handleBuyItemRowModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                hideFooterPagination
                hideFooterSelectedRowCount
                slots={{
                    toolbar: EditToolbar,
                }}
            />
        </Box>
    );
}
BuyItem.propTypes = {
    orderData: PropTypes.array.isRequired,
    setOrderData: PropTypes.func.isRequired,
}
export default BuyItem;
