import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import * as React from "react";

const CircularIndeterminate = () => {
    return (
        <Box sx={{display: 'flex'}}>
            <CircularProgress/>
        </Box>
    );
}
export default CircularIndeterminate;
