import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {AppBar, Box, createTheme, Drawer, List, ThemeProvider} from '@mui/material';
import {AlertProvider} from "./providers/AlertProvider";
import {logout} from "./utils/auth";
import AppRoutes from "./routes/AppRoutes";
import AppToolbar from "./components/apps/AppToolbar";
import NavigationMenu from "./components/apps/NavigationMenu";
import HomeIcon from "@mui/icons-material/Home";
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import StoreIcon from '@mui/icons-material/Store';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import BadgeIcon from '@mui/icons-material/Badge';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {PermissionProvider} from "./providers/PermissionProvider";
import {
    DEFAULT_PRIMARY_COLOR,
    LOCAL_STORAGE_PRIMARY_COLOR,
    ROUTE_CUSTOMER_MGMT,
    ROUTE_HOME,
    ROUTE_INVENTORY_MGMT,
    ROUTE_INVOICE_MGMT, ROUTE_LOGIN,
    ROUTE_ORDER_MGMT,
    ROUTE_PAWN_MGMT,
    ROUTE_PRICE_BOARD, ROUTE_PRICES,
    ROUTE_SYSTEM_MGMT,
    ROUTE_USER_MGMT
} from "./utils/constants";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import {AuthProvider} from "./providers/AuthProvider";

const menuItems = [
    {label: 'Trang chủ', path: ROUTE_HOME, icon: <HomeIcon/>},
    {label: 'Cầm đồ', path: ROUTE_PAWN_MGMT, icon: <MonetizationOnIcon/>},
    {label: 'Khách hàng', path: ROUTE_CUSTOMER_MGMT, icon: <ManageAccountsIcon/>},
    {label: 'Mua/Bán', path: ROUTE_ORDER_MGMT, icon: <ShopTwoIcon/>},
    {label: 'Hóa đơn', path: ROUTE_INVOICE_MGMT, icon: <ReceiptIcon/>},
    {label: 'Xuất/Nhập', path: ROUTE_INVENTORY_MGMT, icon: <StoreIcon/>},
    {label: 'Hệ thống', path: ROUTE_SYSTEM_MGMT, icon: <SettingsApplicationsIcon/>},
    {label: 'Người dùng', path: ROUTE_USER_MGMT, icon: <BadgeIcon/>},
    {label: 'Bảng giá', path: ROUTE_PRICE_BOARD, icon: <PriceChangeIcon/>},
    {label: 'Giá vàng', path: ROUTE_PRICES, icon: <LeaderboardIcon/>},
];

const App = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [primaryColor, setPrimaryColor] = useState(DEFAULT_PRIMARY_COLOR);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        const savedColor = localStorage.getItem(LOCAL_STORAGE_PRIMARY_COLOR);
        if (savedColor) {
            setPrimaryColor(savedColor);
        }

        const handleStorageChange = (event) => {
            const updatedColor = localStorage.getItem(LOCAL_STORAGE_PRIMARY_COLOR);
            if (updatedColor) {
                setPrimaryColor(updatedColor);
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const theme = createTheme({
        palette: {
            primary: {
                main: primaryColor,
            },
        },
    });

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.log("Error while processing logout:", error);
        } finally {
            window.location.href = ROUTE_LOGIN;
        }
    };

    const handleToggleFullScreen = (isFullScreen) => {
        setIsFullScreen(isFullScreen);
    };

    return (
        <AuthProvider>
            <AlertProvider>
                <PermissionProvider>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                                {!isFullScreen && (
                                    <AppBar position="static">
                                        <AppToolbar
                                            menuItems={menuItems}
                                            onDrawerToggle={handleDrawerToggle}
                                            onLogout={handleLogout}
                                        />
                                    </AppBar>
                                )}
                                {!isFullScreen && (
                                    <Drawer
                                        variant="temporary"
                                        anchor="left"
                                        open={mobileOpen}
                                        onClose={handleDrawerToggle}
                                        ModalProps={{
                                            keepMounted: true,
                                        }}
                                    >
                                        <List>
                                            <NavigationMenu
                                                menuItems={menuItems}
                                                onDrawerToggle={handleDrawerToggle}
                                                onLogout={handleLogout}
                                            />
                                        </List>
                                    </Drawer>
                                )}
                                <Box component="main" sx={{flexGrow: 1}}>
                                    <AppRoutes onToggleFullScreen={handleToggleFullScreen}/>
                                </Box>
                            </Box>
                        </Router>
                    </ThemeProvider>
                </PermissionProvider>
            </AlertProvider>
        </AuthProvider>
    );
};

export default App;
