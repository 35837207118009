import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, Grid} from "@mui/material";
import {readGoldWeightUnits, readMoneyInVietnameseDong} from "../../utils/constants";
import PropTypes from "prop-types";

const PawnReceipt = ({open, onClose, sourcePawn}) => {
    const [pawnData, setPawnData] = useState(sourcePawn);
    useEffect(() => {
        setPawnData(sourcePawn);
    }, [open, sourcePawn])
    const handlePrint = () => {
        window.print();
    };
    return (
        <div>
            <style>
                {`
                    .prePrint {
                        height: auto !important;
                        max-width: 900px !important;
                    }
               
                    /*Print Dialog*/
                    @media print {
                        html, body {
                              height:100vh; 
                              margin: 0 !important; 
                              padding: 0 !important;
                              overflow: hidden;
                            }
                        .no-print {
                            display: none;
                        }
                        .printDialog {
                            max-width: 100% !important;
                            height: auto !important;
                            font-size: 0.7em !important;
                        }
                    }
                `}
            </style>
            <Dialog open={open} onClose={onClose} classes={{paperFullScreen: "prePrint printDialog"}} fullScreen>
                <Grid container item>
                    <Grid container item sm={4} sx={{my: 5}}>
                        <Box p={2}>
                            <Grid item xs={12}>
                                <span style={{fontWeight: 'bold'}}>{pawnData.pawnDate.format('DD/MM/YYYY')}</span>
                            < /Grid>
                            <Grid item xs={12}>
                                <p style={{fontWeight: 'bold'}}>{pawnData.customerName}</p>
                                <p>Số: {pawnData.pawnId}, Mã KH: {pawnData.customerId}</p>
                                <p>Món hàng: {pawnData.itemName}</p>
                                <p>Trọng lượng: {readGoldWeightUnits(pawnData.itemWeight)}</p>
                                <p>Số tiền: {Number(pawnData.pawnAmount).toLocaleString()} vnđ</p>
                                <p>Ngày chuộc: {pawnData.pawnDueDate.format('DD/MM/YYYY')}</p>
                                <p>Lấy thêm lần 1: <span>. . . . . . . . . . . . . . . .</span></p>
                                <p>Lấy thêm lần 2: <span>. . . . . . . . . . . . . . . .</span></p>
                                <p>Lấy thêm lần 3: <span>. . . . . . . . . . . . . . . .</span></p>
                            < /Grid>
                        </Box>
                    </Grid>
                    <Grid container item sm={8}>
                        <Box style={{lineHeight: "1.1em"}}>
                            <div id="bill-header" style={{textAlign: 'center', lineHeight: "0.5em"}}>
                                <h5 style={{color: "blue"}}>TIỆM VÀNG</h5>
                                <h1 id="shop-name" style={{color: "red"}}>KIM NGÂN PHÁT</h1>
                                <p style={{fontSize: 12}}>ĐC: TỔ 4, KP.4, PHƯỜNG HỘI NGHĨA, TÂN UYÊN, BÌNH DƯƠNG</p>
                                <p style={{fontSize: 12}}>ĐT: 0982.065.218 - 0974.863.175</p>
                            </div>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{textAlign: 'center', color: "red"}}>
                                    <h3>BIÊN NHẬN CẦM ĐỒ</h3>
                                    <p>Số: {pawnData.pawnId}, KH: {pawnData.customerId}</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <p>Nhận của Ông (Bà): {pawnData.customerName}</p>
                                    <p>Tên Món Hàng: {pawnData.itemName}</p>
                                    <p>Trọng Lượng: {readGoldWeightUnits(pawnData.itemWeight)}</p>
                                    <p>Số Tiền Cầm: {Number(pawnData.pawnAmount).toLocaleString()} vnđ.</p>
                                    <p>{readMoneyInVietnameseDong(pawnData.pawnAmount)}</p>
                                    <p>Ngày chuộc/đóng lãi: {pawnData.pawnDueDate.format('DD/MM/YYYY')}</p>
                                < /Grid>
                            </Grid>
                            <Grid container style={{textAlign: 'center', lineHeight: "0.2em"}}>
                                <Grid item xs={12} style={{textAlign: 'left'}}>
                                    <p>Lấy thêm lần 1: <span>. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </span>
                                    </p>
                                    <p>Lấy thêm lần 2: <span>. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </span>
                                    </p>
                                    <p>Lấy thêm lần 3: <span>. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </span>
                                    </p>
                                </Grid>
                                <Grid item xs={4.5}/>
                                <Grid item xs={1.5}/>
                                <Grid item xs={5.5}><span
                                    style={{color: "blue"}}>Ngày {pawnData.pawnDate.format('DD [Tháng] MM [Năm] YYYY')}</span></Grid>

                                <Grid item xs={4.5}><h5 style={{color: "blue"}}>KHÁCH HÀNG</h5></Grid>
                                <Grid item xs={2}/>
                                <Grid item xs={5}><h5 style={{color: "blue"}}>CHỦ TIỆM</h5></Grid>

                                <Grid item xs={12}></Grid>
                                <Grid item xs={4.5}/>
                                <Grid item xs={2}/>
                                <Grid item xs={5}><h5 style={{color: "red"}}>KIM NGÂN PHÁT</h5>
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'left', fontSize: 13, lineHeight: '1em'}}>
                                    <p><span style={{color: "red"}}>Lưu ý: </span>Quý Khách vui lòng đến chuộc lại
                                        hoặc đóng lãi khi hết hạn, nếu quá hạn món hàng sẽ bị thanh lý.</p>
                                    <p>Xin vui lòng giữ giấy cẩn thận, nếu mất báo ngay cho tiệm. Xin cám ơn</p>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Button variant="contained" onClick={handlePrint} className='no-print'>
                    In Biên Nhận
                </Button>
            </Dialog>
        </div>
    )
};
PawnReceipt.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sourcePawn: PropTypes.object.isRequired
}
export default PawnReceipt;
