import React, {useEffect, useState} from "react";
import {GridFooter} from "@mui/x-data-grid";
import {Box, Grid} from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CustomLoadingButton from "../common/CustomLoadingButton";
import PropTypes from "prop-types";

const CustomGridFooter = ({summary, onExportFunction}) => {
    const [summaryData, setSummaryData] = useState(summary);
    useEffect(() => {
        setSummaryData(summary);
    }, [summary]);

    const handleExport = () => {
        try {
            return onExportFunction();
        } catch (error) {
            console.log("Error");
        }
    }
    return (
        <Box sx={{p: 1, display: 'flex'}}>
            <Grid container>
                <Grid container item xs={5}>
                    <Grid item xs={12}>{(summaryData && summaryData.totalItem) ? summaryData.totalItem : ''}</Grid>
                    <Grid item xs={12}>{(summaryData && summaryData.totalAmount) ? summaryData.totalAmount : ''}</Grid>
                    <Grid item xs={12}>
                        <CustomLoadingButton size="small" variant="text" buttonLabel="Xuất Excel" onClick={handleExport}
                                             startIcon={<DownloadForOfflineIcon/>}></CustomLoadingButton>
                    </Grid>
                </Grid>
                <Grid item xs={7}>
                    <Grid item xs={12}><GridFooter sx={{border: 'none'}}/></Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
CustomGridFooter.propTypes = {
    summary: PropTypes.object,
    onExportFunction: PropTypes.func.isRequired,
};
export default CustomGridFooter;
