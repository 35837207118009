import dayjs from "dayjs";
import axios from "axios";
import JsBarcode from 'jsbarcode';
import {Decimal} from 'decimal.js';
import {isMobile} from "react-device-detect";

export const API_ENDPOINT = process.env.REACT_APP_API_URL || "http://localhost:8088/api/v1";
export const SYSTEMS_API_ENDPOINT = API_ENDPOINT + '/systems';
export const GoldBrand_Unknown = {
    label: 'Chành',
    code: 'Unknown',
    address: 'Unknown',
    shortName: 'Unknown',
    pubStand: 'TCVN',
    order: 100,
    favourite: false,
    name: '',
    phone: '',
    origin: 'vn',
    silverBrand: false,
};
export const ADMINISTRATOR_USER_NAME = 'Administrator';
export const GoldType_Unknown = {
    label: 'Tuổi vàng',
    code: 'Unknown',
    name: 'Unknown',
    pawn: 2000000,
    silverType: false
};

export const DEFAULT_ERROR_MESSAGE = "Có lỗi xảy ra khi ở máy chủ. Vui lòng kiểm liên hệ quản trị viên!";
export const SYSTEM_ERROR_MSG = "Lỗi. Không thể kết nối đến máy chủ!";
export const ERR_SEVERITY = "error";
export const WARNING_SEVERITY = "warning";
export const SUCCESS_SEVERITY = "success";
export const Counter_Unknown = {label: 'Chọn quầy', id: 0, name: 'Chọn quầy', code: 'Unknown'};
export const LOCAL_STORAGE_KEY_BRANDS = "GOLD_BRANDS";
export const LOCAL_STORAGE_KEY_TYPES = "GOLD_TYPES";
export const LOCAL_STORAGE_KEY_COUNTERS = "COUNTERS_DATA";
export const LOCAL_STORAGE_KEY_PRICES = "GOLD_PRICES";
export const LOCAL_STORAGE_KEY_PRODUCTS = "JEWELRY_TOP_ITEM_NAME";
export const LOCAL_STORAGE_KEY_FRE_PROC_AMOUNTS = "FRE_PROC_AMOUNTS";
export const LOCAL_STORAGE_KEY_PAWN_PRODUCTS = "PAWN_TOP_ITEM_NAME";

export const LOCAL_STORAGE_KEY_SHOP_INFO = "SHOP_INFO";
export const LOCAL_STORAGE_KEY_CONFIGS = "SYSTEM_CONFIG";
export const LOCAL_STORAGE_KEY_TOKEN = "ACCESS_TOKEN";
export const LOCAL_STORAGE_KEY_USERNAME = "USER_NAME";
export const LOCAL_STORAGE_KEY_ACCOUNT_NAME = "ACCOUNT_NAME";
export const LOCAL_STORAGE_KEY_PAYMENT_TYPE = "PAYMENT_TYPE";
export const LOCAL_STORAGE_PRIMARY_COLOR = "PRIMARY_COLOR";
export const LOCAL_STORAGE_AVATAR = "AVATAR_ACCOUNT";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_PAWN_MGMT = "/pawn-mgmt";
export const ROUTE_CUSTOMER_MGMT = "/customer-mgmt";
export const ROUTE_INVENTORY_MGMT = "/inventory-mgmt";
export const ROUTE_ORDER_MGMT = "/order-mgmt";
export const ROUTE_INVOICE_MGMT = "/invoice-mgmt";
export const ROUTE_SYSTEM_MGMT = "/system-mgmt";
export const ROUTE_USER_MGMT = "/user-mgmt";
export const ROUTE_PRICE_BOARD = "/price-board";
export const ROUTE_PRICES = "/prices";
export const ROUTE_CHANGE_PASSWORD = "/change-password";
export const ROUTE_LOGOUT = "/logout";
export const ROUTE_NOT_AUTHORIZED = "/not-authorized";
export const ROUTE_FORBIDDEN = "/forbidden";
export const DEFAULT_PRIMARY_COLOR = '#2196f3';

localStorage.setItem(LOCAL_STORAGE_KEY_PAYMENT_TYPE, JSON.stringify([
    {code: 'CASH', label: 'Hình thức thanh toán tiền mặt', order: 1},
    {code: 'TRANSFER', label: 'Hình thức thanh toán chuyển khoản', order: 2},
]));
localStorage.setItem(LOCAL_STORAGE_KEY_PRODUCTS, JSON.stringify([
    "Bông kiểu",
    "Bông tai",
    "Dây kiểu",
    "Dây chữ công",
    "Dây chuyền",
    "Kiềng cổ",
    "Lắc tay",
    "Lắc lật",
    "Lắc chân",
    "Nhẫn nam",
    "Nhẫn cưới",
    "Nhẫn trơn",
    "Nhẫn kim tiền",
    "Nhẫn kiểu",
    "Vòng tay kiểu",
    "Vòng ngọc",
    "Vòng em",
    "Vòng Ximen",
]));


export const handleSuccessMessage = (message, openAlert, alertSeverity = SUCCESS_SEVERITY) => {
    openAlert(alertSeverity, message);
};

export const handleErrorMessage = (message, openAlert, alertSeverity = ERR_SEVERITY) => {
    openAlert(alertSeverity, message);
};
export const handleWarningMessage = (message, openAlert, alertSeverity = WARNING_SEVERITY) => {
    openAlert(alertSeverity, message);
};


const removeAuditInfo = (source = {}) => {
    const propertiesToRemove = ["createdAt", "createdBy", "updatedBy", "updatedAt", "invoicePassword", "invoiceUserName"];
    const newObj = {...source};
    propertiesToRemove.forEach(prop => delete newObj[prop]);
    return newObj;
}

function removeAuditInfoFromList(objects) {
    if (!Array.isArray(objects)) {
        return objects;
    }
    return objects.map(obj => {
        return removeAuditInfo(obj);
    });
}

export const writeSystemInfo = (systemsData = {}) => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_BRANDS);
    localStorage.setItem(LOCAL_STORAGE_KEY_BRANDS, JSON.stringify(removeAuditInfoFromList(systemsData.brands)));

    localStorage.removeItem(LOCAL_STORAGE_KEY_TYPES);
    localStorage.setItem(LOCAL_STORAGE_KEY_TYPES, JSON.stringify(removeAuditInfoFromList(systemsData.types)));

    localStorage.removeItem(LOCAL_STORAGE_KEY_COUNTERS);
    localStorage.setItem(LOCAL_STORAGE_KEY_COUNTERS, JSON.stringify(removeAuditInfoFromList(systemsData.counters)));

    localStorage.removeItem(LOCAL_STORAGE_KEY_PRICES);
    localStorage.setItem(LOCAL_STORAGE_KEY_PRICES, JSON.stringify(removeAuditInfoFromList(systemsData.prices)));

    localStorage.removeItem(LOCAL_STORAGE_KEY_SHOP_INFO);
    localStorage.setItem(LOCAL_STORAGE_KEY_SHOP_INFO, JSON.stringify(removeAuditInfo(systemsData.shopInfo)));

    localStorage.removeItem(LOCAL_STORAGE_KEY_CONFIGS);
    localStorage.setItem(LOCAL_STORAGE_KEY_CONFIGS, JSON.stringify(removeAuditInfoFromList(systemsData.configs)));
};
export const fetchSystemInfo = async (openAlert) => {
    try {
        const response = await axios.get(`${SYSTEMS_API_ENDPOINT}`);
        const systemsData = response.data;
        await writeSystemInfo(systemsData)
    } catch (error) {
        console.error("Error while fetching pawn details:", error)
        if (openAlert) {
            handleErrorMessage("Lỗi kết nối tới hệ thống. Liên hệ quản trị viên", openAlert);
        }
    }
};

export const getGoldType = () => {
    let goldTypes = localStorage.getItem(LOCAL_STORAGE_KEY_TYPES);
    if (!goldTypes) {
        return {Unknown: GoldType_Unknown};
    }
    const parsedGoldTypes = JSON.parse(goldTypes);
    return parsedGoldTypes.reduce((accumulator, currentType) => {
        accumulator[currentType.code] = {
            label: currentType.label,
            code: currentType.code,
            order: currentType.order,
            name: currentType.name,
            selected: currentType.selected,
            silverType: currentType.silverType,
        };
        return accumulator;
    }, {});
}
export const getSortedGoldType = () => {
    return Object.values(getGoldType()).sort((a, b) => a.order - b.order);
}

export const getCounters = () => {
    const counter = localStorage.getItem(LOCAL_STORAGE_KEY_COUNTERS);
    return counter ? JSON.parse(counter).reduce((accumulator, counter) => {
        accumulator[counter.code] = {
            label: counter.label,
            code: counter.code,
            counterId: counter.id,
        };
        return accumulator;
    }, {}) : {Unknown: Counter_Unknown}
}

export const getGoldBrand = () => {
    let brands = localStorage.getItem(LOCAL_STORAGE_KEY_BRANDS);
    return brands ? JSON.parse(brands).reduce((accumulator, brand) => {
        accumulator[brand.code] = {
            label: brand.label,
            code: brand.code,
            address: brand.address,
            order: brand.order,
            favourite: brand.favourite,
            name: brand.name,
            pubStand: brand.pubStand,
            shortName: brand.shortName,
            phone: brand.phone,
            origin: brand.origin,
        };
        return accumulator;
    }, {}) : {Unknown: GoldBrand_Unknown};
}
export const getSortedGoldBrand = () => {
    return Object.values(getGoldBrand()).sort((a, b) => a.order - b.order);
}
export const GoldPrice_Unknown = {
    type: GoldType_Unknown.code,
    buy: 3600000, sell: 4000000, pawn: 3000000
};

export const getGoldPrice = () => {
    const prices = localStorage.getItem(LOCAL_STORAGE_KEY_PRICES);
    return prices ? JSON.parse(prices).reduce((accumulator, price) => {
        accumulator[price.code] = {
            code: price.code,
            buy: price.buy,
            sell: price.sell,
            pawn: price.pawn,
            order: price.order,
        };
        return accumulator;
    }, {}) : {Unknown: GoldPrice_Unknown};
}

export const getShopInfo = () => {
    const shop = localStorage.getItem(LOCAL_STORAGE_KEY_SHOP_INFO);
    return shop ? JSON.parse(shop) : {
        name: '',
        code: '',
        shortName: '',
        address: '',
        shortAddress: ''
    }
}

export const getSystemConfig = () => {
    const systemConfigs = localStorage.getItem(LOCAL_STORAGE_KEY_CONFIGS);
    return systemConfigs ? JSON.parse(systemConfigs).reduce((accumulator, config) => {
        accumulator[config.key] = {
            key: config.key,
            value: config.value,
        };
        return accumulator;
    }, {}) : {};
}
export const getInvoiceSeries = () => {
    const shopInfo = localStorage.getItem(LOCAL_STORAGE_KEY_SHOP_INFO);
    return shopInfo ? JSON.parse(shopInfo).invoiceSeries : '';
}
export const getTemplateCode = () => {
    const shopInfo = localStorage.getItem(LOCAL_STORAGE_KEY_SHOP_INFO);
    return shopInfo ? JSON.parse(shopInfo).templateCode : '';
}
export const DATE_SHORT_FORMAT = "DD/MM/YYYY";
export const DATE_LONG_FORMAT = "DD/MM/YYYY HH:mm";
export const DATE_FULL_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DATE_FULL_VN_FORMAT = "HH:mm:ss DD/MM/YYYY";
export const VN_UTC_TIME = 7;
export const readMoneyInVietnameseDong = (amount) => {
    const numberToWords = (number) => {
        const units = ['', 'Ngàn', 'Triệu', 'Tỷ'];
        const words = [];
        const convertChunk = (chunk) => {
            const chunkWords = [];
            const oneToNine = ['', 'Một', 'Hai', 'Ba', 'Bốn', 'Năm', 'Sáu', 'Bảy', 'Tám', 'Chín'];

            const hundreds = Math.floor(chunk / 100);
            const remainder = chunk % 100;

            if (hundreds > 0) {
                chunkWords.push(oneToNine[hundreds], 'Trăm');
            }
            if (remainder > 0) {
                if (remainder < 10) {
                    chunkWords.push(oneToNine[remainder]);
                } else if (remainder < 20) {
                    chunkWords.push('Mười', oneToNine[remainder - 10]);
                } else {
                    const tens = Math.floor(remainder / 10);
                    const ones = remainder % 10;
                    chunkWords.push(oneToNine[tens] + ' Mươi', oneToNine[ones]);
                }
            }
            return chunkWords.join(' ');
        };

        const chunks = [];
        let remaining = Math.abs(number);

        while (remaining > 0) {
            chunks.push(remaining % 1000);
            remaining = Math.floor(remaining / 1000);
        }
        for (let i = 0; i < chunks.length; i++) {
            const chunkWords = convertChunk(chunks[i]);
            if (chunkWords) {
                words.push(chunkWords + ' ' + units[i]);
            }
        }
        return words.reverse().join(' ');
    };
    const amountInWords = numberToWords(amount);
    return `${amountInWords.trim()} đồng`;
};
export const readMoneyInVietnameseDongWithMaxLength = (amount, length) => {
    let result = readMoneyInVietnameseDong(amount);
    if (result.length <= length) return result
    else if (result.replace(' đồng', '').length <= length) return result.replace(' đồng', '');
    return result.replace(' đồng', '').substring(0, (length - 3)) + '...';
}
const convertGoldWeight = (weight = 0) => {
    if (weight === 0) return {
        luong: 0,
        chi: 0,
        phan: 0,
        li: 0,
        rem: 0,
    }
    const integerPart = Math.floor(weight);
    const fractionalPart = (weight - integerPart).toPrecision(3);
    const luongUnit = Math.floor(integerPart / 10);
    const chiUnit = integerPart % 10;
    const phanUnit = Math.floor(fractionalPart * 10);
    const liUnit = parseInt(new Decimal(fractionalPart).mul(100).mod(10));
    const remUnit = parseInt(new Decimal(fractionalPart).mul(1000).mod(10));
    return {
        luong: luongUnit,
        chi: chiUnit,
        phan: phanUnit,
        li: liUnit,
        rem: remUnit,
    }
}

export const readGoldWeightUnits = (weight = 0) => {
    const {luong, chi, phan, li, rem} = convertGoldWeight(weight);
    const units = [];
    if (luong > 0) {
        units.push(`${luong}L`);
    }
    if (chi > 0 || (chi === 0 && luong > 0)) {
        units.push(`${chi}c`);
    }
    if (phan > 0 || (phan === 0 && chi > 0 && li !== 0)) {
        units.push(`${phan}p`);
    }
    if (li > 0 || (li === 0 && phan > 0 && rem !== 0)) {
        units.push(`${li}li`);
    }
    if (rem > 0) {
        if (li === 0 && phan === 0) {
            units.push(`${rem}dem`);
        } else {
            units.push(`${rem}`);
        }
    }
    return units.join('');
};


export const readGoldWeightStamp = (weight = 0) => {
    const units = [];
    units.push(`${weight}Chỉ`);
    return units.join('');
};
export const AUTO_HIDE_DURATION = 9000;
export const formatCustomerName = (customer = {}) => {
    if (customer) {
        return customer.phone ? customer.lastName.concat(' - ', customer.phone) : customer.lastName;
    } else return '';
}
export const formatCustomerNameWithId = (customer = {}) => {
    if (customer) {
        return (customer.phone ? customer.lastName.concat(' - Số ĐT: ', customer.phone) : customer.lastName) + ' - Mã KH:' + customer.customerId;
    } else return '';
}
export const convertDayjsDate = (stringDate) => {
    return dayjs(stringDate);
}
export const convertDayjsDateOffSet = (stringDate, utcOffset = VN_UTC_TIME) => {
    return dayjs(stringDate).utcOffset(utcOffset);
};
export const isNumber = (value) => {
    const numericValue = parseFloat(value);
    return !isNaN(numericValue) && isFinite(numericValue);
};
export const findGoldBrandNameByCode = (code = GoldBrand_Unknown.code) => {
    const brands = getGoldBrand();
    if (brands.hasOwnProperty(code)) {
        return brands[code];
    } else {
        return GoldBrand_Unknown;
    }
};

export const getGoldPriceByType = (code = GoldType_Unknown.code) => {
    const codeAsString = code.toString();
    const goldPrice = getGoldPrice();
    if (goldPrice.hasOwnProperty(codeAsString)) {
        return goldPrice[codeAsString];
    }
    return GoldPrice_Unknown;
}
export const formatCurrency = (value = 0) => {
    return value?.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'});
};
export const getGoldTypeLabelByCode = (code) => {
    const codeAsString = code.toString();
    const goldTypes = getGoldType();
    if (goldTypes.hasOwnProperty(codeAsString)) {
        return goldTypes[codeAsString].label;
    } else {
        return GoldType_Unknown.label;
    }
}

export const getGoldBrandLabelByCode = (code) => {
    const codeAsString = code.toString();
    const goldBrand = getGoldBrand();
    if (goldBrand.hasOwnProperty(codeAsString)) {
        return goldBrand[codeAsString].label;
    } else {
        return GoldBrand_Unknown.label;
    }
}
export const getGoldTypeByCode = (code) => {
    const codeAsString = code.toString();
    const goldTypes = getGoldType();
    if (goldTypes.hasOwnProperty(codeAsString)) {
        return goldTypes[codeAsString];
    } else {
        return GoldType_Unknown;
    }
}
export const getTopProducts = () => {
    const products = localStorage.getItem(LOCAL_STORAGE_KEY_PRODUCTS);
    return products ? JSON.parse(products) : []
};

export const getCommonPawnProducts = () => {
    const products = localStorage.getItem(LOCAL_STORAGE_KEY_PAWN_PRODUCTS);
    return products ? JSON.parse(products) : []
};

export const setTopProducts = (name = '') => {
    let products = getTopProducts();
    if (name !== '' && products.length <= 50 && !products.includes(name)) {
        products = [...products, name];
        localStorage.setItem(LOCAL_STORAGE_KEY_PRODUCTS, JSON.stringify(products));
    }
}
export const setCommonPawnProducts = (name = '') => {
    let products = getCommonPawnProducts();
    if (name !== '' && products.length <= 50 && !products.includes(name)) {
        products = [...products, name];
        localStorage.setItem(LOCAL_STORAGE_KEY_PAWN_PRODUCTS, JSON.stringify(products));
    }
}
export const getConfigureValueByKey = (key, defVal = '') => {
    const codeAsString = key.toString();
    const systemConfig = getSystemConfig();
    if (systemConfig.hasOwnProperty(codeAsString)) {
        return systemConfig[codeAsString]?.value;
    }
    return defVal;
}
export const setUserTime = (date) => {
    const currentDate = dayjs();
    if (date) {
        return dayjs(date).set("h", currentDate.hour()).set("m", currentDate.minute()).set("s", currentDate.second());
    }
    return date;
}

export const handleApiError = (error, openAlert, alertSeverity = ERR_SEVERITY) => {
    let message;
    if (error.response) {
        const status = error.response.status;
        switch (status) {
            case 404:
                message = 'Không tìm thấy!';
                break;
            case 400:
                message = error.response.data.message || DEFAULT_ERROR_MESSAGE;
                break;
            case 401:
                message = 'Bạn không có quyền truy cập.';
                break;
            case 403:
                message = 'You do not have permission to perform this action.';
                break;
            case 500:
                message = SYSTEM_ERROR_MSG;
                break;
            default:
                message = DEFAULT_ERROR_MESSAGE;
                break;
        }
    } else if (error.code === 'ERR_CONNECTION_REFUSED' || error.code === 'ERR_NETWORK') {
        message = 'Lỗi kết nối tới hệ thống!. Vui lòng thử lại sau.';
    } else if (error.code === 'ECONNABORTED') {
        message = 'Hết thời gian kết nối tới hệ thống!. Vui lòng thử lại sau.';
    } else {
        message = 'An error occurred while processing your request. Please try again later.';
    }
    openAlert(alertSeverity, message);
};

export const formatDate = (date, format) => {
    return date ? dayjs(date).format(format) : '';
};
export const generateBarcode = (code, displayValue = true, width = 2, height = 20, margin = 10) => {
    const barcodeCanvas = document.createElement('canvas');
    JsBarcode(barcodeCanvas, code, {
        displayValue: displayValue,
        width: width,
        height: height,
        margin: margin,
    });
    return barcodeCanvas.toDataURL();
};

export const calculateGoldWeight = (totalWeight = 0, gemWeight = 0) => {
    const parsedTotalWeight = parseFloat(totalWeight);
    const parsedGemWeight = parseFloat(gemWeight);
    if (isNaN(parsedTotalWeight) || isNaN(parsedGemWeight)) {
        return 0;
    }
    return new Decimal(parsedTotalWeight).minus(parsedGemWeight);
};
export const calculateTotalWeight = (goldWeight = 0, gemWeight = 0) => {
    const parsedGoldWeight = parseFloat(goldWeight);
    const parsedGemWeight = parseFloat(gemWeight);
    if (isNaN(parsedGoldWeight) || isNaN(parsedGemWeight)) {
        return 0;
    }
    return new Decimal(parsedGoldWeight).plus(parsedGemWeight);
};

export const calculateAmount = (goldWeight = 0, price = 0, procPrice = 0, discount = 0) => {
    const parsedGoldWeight = parseFloat(goldWeight);
    const parsedPrice = parseInt(price);
    const parsedProcPrice = parseInt(procPrice);
    const parsedDiscount = parseInt(discount);
    if (isNaN(parsedGoldWeight) || isNaN(parsedPrice)) {
        return 0;
    } else if (isNaN(parsedProcPrice) || isNaN(parsedDiscount)) {
        return parsedPrice * parsedGoldWeight;
    } else {
        return ((parsedPrice * parsedGoldWeight) + parsedProcPrice) - parsedDiscount;
    }
};

export const calculateBuyAmount = (goldWeight = 0, price = 0, compPrice = 0) => {
    const parsedGoldWeight = parseFloat(goldWeight);
    const parsedPrice = parseInt(price);
    const parsedCompPrice = parseInt(compPrice);
    if (isNaN(parsedGoldWeight) || isNaN(parsedPrice)) {
        return 0;
    } else if (isNaN(parsedCompPrice)) {
        return (parsedGoldWeight * parsedPrice);
    } else {
        return (parsedGoldWeight * parsedPrice) - (parsedGoldWeight * parsedCompPrice);
    }
}

export const getProcAmounts = () => {
    const proAmounts = localStorage.getItem(LOCAL_STORAGE_KEY_FRE_PROC_AMOUNTS);
    return proAmounts ? JSON.parse(proAmounts) : []
};

export const setProcAmounts = (value = '500000') => {
    let proAmounts = getProcAmounts();
    if (value !== '' && proAmounts.length <= 50 && !proAmounts.includes(value)) {
        proAmounts = [...proAmounts, value];
        localStorage.setItem(LOCAL_STORAGE_KEY_FRE_PROC_AMOUNTS, JSON.stringify(proAmounts));
    }
}

export const generateIncreasingArray = (startNumber, step, maxNumber) => {
    const result = [];
    let currentNumber = startNumber;
    while (currentNumber < maxNumber) {
        result.push(String(currentNumber));
        currentNumber += step;
    }
    result.push(String(maxNumber));
    return result;
};
export const isArrayNotEmpty = (array) => {
    return Array.isArray(array) && array.length > 0;
};

export const createEqualFilter = (column, value) => {
    return {'columnName': column, 'value': value};
}
export const buildFilterObject = (filter) => {
    const {columnName, value} = filter;
    return {[columnName]: value};
}

export const setStartOfTheDate = (date) => {
    return date ? date.setHours(0, 0, 0, 0) : date;
}
export const setEndOfTheDate = (date) => {
    return date ? date.setHours(23, 59, 59, 0) : date;
}
export const createDateFilter = (columnName, fromDate, toDate) => {
    const filterObject = {};
    filterObject["columnName"] = columnName;
    if (fromDate) {
        filterObject['fromDate'] = fromDate;
    }
    if (toDate) {
        filterObject['toDate'] = toDate;
    }
    return {[columnName]: filterObject};
}

export const getItemFromSourcesByCode = (sources = [], code = '') => {
    return sources.find(item => item.code === code);
};
export const QUICK_ACTION_CREATE_PAWN = 'CREATE_PAWN';
export const QUICK_ACTION_CREATE_BUY_ORDER = 'CREATE_BUY_ORDER';
export const QUICK_ACTION_CREATE_SELL_ORDER = 'CREATE_SELL_ORDER';
export const QUICK_ACTION_CREATE_EXCHANGE_ORDER = 'CREATE_EXCHANGE_ORDER';

export const formatDateTime = (now = new Date()) => {
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
};

export const getResponsiveWidth = (mobile = 12, desktop = 6) => {
    return (isMobile) ? mobile : desktop;
}

export const formatBarsData = (barsData, year, month) => {
    barsData = barsData || [];
    const defaultEntry = {
        year: year,
        month: month,
        amount: 0,
        count: 0,
        weight: 0.0
    };
    const result = [];
    let currentMonth = month;
    let currentYear = year;
    for (let i = 0; i < 12; i++) {
        const currentEntry = barsData.find(item => item.year === currentYear && item.month === currentMonth);
        result.push(currentEntry || {...defaultEntry, year: currentYear, month: currentMonth});
        currentMonth--;
        if (currentMonth === 0) {
            currentMonth = 12;
            currentYear--;
        }
    }
    return result.reverse();
}
export const getPaymentTypes = () => {
    let types = localStorage.getItem(LOCAL_STORAGE_KEY_PAYMENT_TYPE);
    const parsedTypes = JSON.parse(types);
    return parsedTypes.reduce((accumulator, currentType) => {
        accumulator[currentType.code] = {
            label: currentType.label,
            code: currentType.code,
        };
        return accumulator;
    }, {});
}
export const getSortedPaymentTypes = () => {
    return Object.values(getPaymentTypes()).sort((a, b) => a.order - b.order);
}

export const JewelryStampTypes = [
    {code: '12x20', label: 'Tem trang sức kích thước: 12x20mm'},
    {code: '16x30', label: 'Tem trang sức kích thước: 16x30mm'},
]
export const getJewelryStampTypeByCode = (code) => {
    const shopInfo = localStorage.getItem(LOCAL_STORAGE_KEY_SHOP_INFO);
    const jewelryStampType = shopInfo ? JSON.parse(shopInfo).jewelryStampType : '';
    const item = JewelryStampTypes.find(item => item.code === jewelryStampType);
    return item || JewelryStampTypes[0];
};
export const UNKNOWN_ACTIONS = [
    {label: 'Hành động', code: 'UNKNOWN'}
];

export const getCurrentUser = () => {
    const username = localStorage.getItem(LOCAL_STORAGE_KEY_USERNAME);
    return username || '';
};
export const validateField = (fieldValue, setValidationFunc, invalidValue = "") => {
    if (fieldValue === invalidValue || fieldValue.trim() === "") {
        setValidationFunc(false);
        return false;
    } else {
        setValidationFunc(true);
        return true;
    }
};
export const validateNumberField = (fieldValue, setValidationFunc, invalidValue = 0) => {
    if (fieldValue && fieldValue !== invalidValue) {
        setValidationFunc(true);
        return true;
    } else {
        setValidationFunc(false);
        return false;
    }
};
export const validateFieldCondition = (fieldValue, setValidationFunc, conditionFunc) => {
    if (conditionFunc(fieldValue)) {
        setValidationFunc(true);
        return true;
    } else {
        setValidationFunc(false);
        return false;
    }
};

