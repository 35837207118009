import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {
    convertDayjsDate,
    DATE_LONG_FORMAT,
    DEFAULT_ERROR_MESSAGE,
    handleErrorMessage,
    handleSuccessMessage,
    SYSTEMS_API_ENDPOINT
} from "../../utils/constants";
import {DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbar} from "@mui/x-data-grid";
import axios from '../../services/axiosInstance';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {PAWN_EXPIRATION_DAYS, PAWN_INTEREST_VALUE} from "../pawn/PawnConstants";
import {useAlert} from "../../providers/AlertProvider";
import SysConfigDetails from "./SysConfigDetails";
import {usePermissions} from "../../providers/PermissionProvider";
import PropTypes from "prop-types";
import {randomId} from "@mui/x-data-grid-generator";

const SysConfig = ({sourceData = [], loading = false, onUpdateCompleted}) => {
    const permissions = usePermissions();
    const {openAlert} = useAlert();
    const [configs, setConfigs] = useState(sourceData);
    const [rowModelModes, setRowModelModes] = useState({});
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [editData, setEditData] = useState({});
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleEditClick = (id) => () => {
        const editedRow = configs.find((row) => row.id === id);
        if (editedRow) {
            setEditData({
                ...editData,
                id: editedRow.id,
                key: editedRow.key,
                description: editedRow.description,
                value: editedRow.value,
            });
            setOpenDetailsDialog(true);
        }
    };

    const handleSaveClick = (id) => () => {
        setRowModelModes({...rowModelModes, [id]: {mode: GridRowModes.View}});
    };

    const handleCancelClick = (id) => () => {
        setRowModelModes({
            ...rowModelModes,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = configs.find((row) => row.id === id);
        if (editedRow.isNew) {
            setConfigs(configs.filter((row) => row.id !== id));
        }
    };
    const processUpdate = async (config) => {
        try {
            if (!validateInput(config)) {
                handleErrorMessage('Giá trị cài đặt phải là kiểu số!', openAlert);
            }
            await axios.put(`${SYSTEMS_API_ENDPOINT}/sys-configs/${config.id}`, config);
            handleSuccessMessage('Thông tin số: [' + config.description + '] được lưu thành công', openAlert);
        } catch (error) {
            handleErrorMessage(DEFAULT_ERROR_MESSAGE, openAlert);
        } finally {
            await onUpdateCompleted();
        }
    };

    const handleItemUpdate = async (updatedRow) => {
        await processUpdate(updatedRow);
        setOpenDetailsDialog(false);
    }

    const processRowUpdate = async (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setConfigs(configs.map((row) => (row.id === newRow.id ? updatedRow : row)));
        await processUpdate(updatedRow);
        return updatedRow;
    };

    const handleBuyItemRowModelChange = (newRowModesModel) => {
        setRowModelModes(newRowModesModel);
    };

    useEffect(() => {
        setConfigs(sourceData);
    }, [sourceData]);
    const validateInput = (config) => {
        const key = config.id;
        if (key === PAWN_EXPIRATION_DAYS || key === PAWN_INTEREST_VALUE) {
            if (isNaN(config.value)) {
                return false;
            }
        }
        return true;
    }
    const COLUMNS = [
        {
            field: 'actions',
            type: 'actions',
            align: 'center',
            headerName: 'Tùy chọn',
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModelModes[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            key={randomId()}
                            label="Lưu"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            key={randomId()}
                            label="Hủy"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key={randomId()}
                        icon={<EditIcon/>}
                        label="Sửa"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {field: 'key', headerName: 'Ký hiệu', width: 200, editable: false},
        {field: 'description', headerName: 'Tên loại cấu hình', width: 200, editable: true},
        {field: 'value', headerName: 'Giá trị', width: 120, editable: true},
        {field: 'createdBy', headerName: 'Người tạo', width: 150, editable: false},
        {
            field: 'createdAt', headerName: 'Ngày tạo', width: 200, editable: false,
            valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
        },
        {field: 'updatedBy', headerName: 'Người cập nhật', width: 150, editable: false},
        {
            field: 'updatedAt', headerName: 'Ngày cập nhật', width: 200, editable: false,
            valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
        },
    ];
    const getFilteredColumns = () => {
        if (permissions.SYSTEMS?.includes('UPDATE_COMMON_SETTING')) {
            return COLUMNS;
        }
        return COLUMNS.filter(column => column.field !== 'actions');
    };
    return (
        <div>
            <Box sx={{width: 1, overflow: "auto"}}>
                <DataGrid
                    autoHeight={false}
                    editMode="row"
                    rowModesModel={rowModelModes}
                    onRowModesModelChange={handleBuyItemRowModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    disableColumnFilter
                    disableDensitySelector
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            csvOptions: {disableToolbarButton: true},
                            printOptions: {disableToolbarButton: true},
                            showQuickFilter: true,
                        },
                    }}
                    loading={loading}
                    rows={configs}
                    columns={getFilteredColumns()}
                />
            </Box>
            <SysConfigDetails onClose={() => {
                setOpenDetailsDialog(false);
            }} open={openDetailsDialog} sourceData={editData} onItemUpdated={handleItemUpdate}/>
        </div>);
};
SysConfig.propTypes = {
    loading: PropTypes.bool,
    sourceData: PropTypes.array,
    onUpdateCompleted: PropTypes.func
};
export default SysConfig;
