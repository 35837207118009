import React, {useEffect, useState} from "react";
import {vi_locale} from 'dayjs/locale/vi';
import {Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Select, TextField,} from "@mui/material";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import axios from '../../services/axiosInstance';
import {
    calculateAge,
    CUSTOMERS_API_ENDPOINT,
    DEFAULT_CUSTOMER_DATA,
    geDefaultDateOfBirth,
    Gender
} from "./CustomerConstants";
import {
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    getResponsiveWidth,
    handleApiError,
    handleSuccessMessage,
    setUserTime
} from "../../utils/constants";
import GridSummary from "../../components/grid/GridSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {PAWN_SUMMARY_COLUMNS} from "../pawn/PawnConstants";
import {ORDER_SUMMARY_COLUMNS} from "../order/OrderConstant";
import {useAlert} from "../../providers/AlertProvider";
import CustomDialog from "../../components/dialog/CustomDialog";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import NationalCard from "./NationalCard";
import PropTypes from "prop-types";

const DEFAULT_PANEL = 'panel0';
const UpdateCustomer = ({open, onClose, sourceCustomer, mode = "UPDATE"}) => {
    const {openAlert} = useAlert();
    const [customerData, setCustomerData] = useState(sourceCustomer);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [expanded, setExpanded] = React.useState(DEFAULT_PANEL);
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        mode !== "READ_ONLY" ? setReadOnly(false) : setReadOnly(true);
    }, [mode])
    const openDatePicker = () => {
        setCustomerData((prev) => ({
            ...prev,
            dateOfBirth: convertDayjsDate(geDefaultDateOfBirth()),
        }));
    }
    useEffect(() => {
        setCustomerData(sourceCustomer);
    }, [open, sourceCustomer]);

    const onSave = async (customerData) => {
        try {
            const response = await axios.put(`${CUSTOMERS_API_ENDPOINT}/${customerData.customerId}`, customerData);
            handleSuccessMessage('Khách hàng [' + response.data.lastName + '] được cập nhật thành công', openAlert);
            onClose();
            setExpanded(DEFAULT_PANEL);
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };

    const handleSave = async () => {
        let validated = true;
        if (customerData.lastName.trim() === "") {
            setLastNameValid(false);
            validated = false;
        } else {
            setLastNameValid(true);
        }
        if (validated) {
            await onSave(customerData);
            setCustomerData(DEFAULT_CUSTOMER_DATA);
            setLastNameValid(true);
        }
    };
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleClose = async () => {
        setExpanded(DEFAULT_PANEL);
        onClose();
    };
    const formActions = (
        (mode !== "READ_ONLY") ? (
            <CustomLoadingButton variant="contained" onClick={handleSave}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Cập nhật"/>
        ) : {}
    );
    const pageTitle = (
        (mode !== "READ_ONLY") ? (<>
            {`Cập nhật khách hàng số ${customerData.customerId}`}
        </>) : <>{`Thông tin khách hàng số ${customerData.customerId}`}</>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Accordion expanded={expanded === 'panel0'} onChange={handleChangeAccordion('panel0')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin chung
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={getResponsiveWidth()}>
                                <TextField
                                    disabled={readOnly}
                                    label="Tên khách hàng"
                                    value={customerData.lastName || ''}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            lastName: e.target.value,
                                        }))
                                    }
                                    inputProps={{maxLength: 11}}
                                    fullWidth
                                    error={!lastNameValid}
                                />
                            < /Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <TextField
                                    disabled={readOnly}
                                    label="Số điện thoại"
                                    value={customerData.phone || ''}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/\D/g, '');
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            phone: numericInput,
                                        }));
                                    }}
                                    inputProps={{maxLength: 11}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <Select
                                    disabled={readOnly}
                                    value={customerData.gender || ''}
                                    fullWidth
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            gender: e.target.value,
                                        }))
                                    }
                                >
                                    {Object.values(Gender).map((gender) => (
                                        <MenuItem key={gender.code} value={gender.code}>
                                            {gender.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={getResponsiveWidth()}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                                    <DatePicker label="Ngày sinh"
                                                disabled={readOnly}
                                                value={customerData.dateOfBirth}
                                                onChange={(date) => {
                                                    const selectedDate = setUserTime(date);
                                                    setCustomerData((prev) => ({
                                                        ...prev,
                                                        dateOfBirth: selectedDate,
                                                        age: calculateAge(selectedDate),
                                                    }));
                                                }}
                                                fullWidth
                                                format={DATE_SHORT_FORMAT}
                                                slotProps={{
                                                    field: {shouldRespectLeadingZeros: true, clearable: true},
                                                    textField: {error: false, fullWidth: true}
                                                }}
                                                disableFuture={true}
                                                onOpen={openDatePicker}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Email"
                                    disabled={readOnly}
                                    value={customerData.email || ''}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            email: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Sở thích"
                                    disabled={readOnly}
                                    value={customerData.habit ? customerData.habit : ''}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            habit: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Ghi chú"
                                    disabled={readOnly}
                                    value={customerData.description ? customerData.description : ''}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            description: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={getResponsiveWidth(12, 12)}>
                                <TextField
                                    label="Địa chỉ"
                                    disabled={readOnly}
                                    value={customerData.address ? customerData.address : ''}
                                    onChange={(e) =>
                                        setCustomerData((prev) => ({
                                            ...prev,
                                            address: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        Thông tin CCCD
                    </AccordionSummary>
                    <AccordionDetails>
                        <NationalCard customerData={customerData} setCustomerData={setCustomerData}
                                      readOnly={readOnly}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}> Đơn cầm của khách</AccordionSummary>
                    <AccordionDetails>
                        <GridSummary rowData={customerData.pawns} columnData={PAWN_SUMMARY_COLUMNS}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccordion('panel4')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>Lịch sử đơn hàng</AccordionSummary>
                    <AccordionDetails>
                        <GridSummary rowData={customerData.orders} columnData={ORDER_SUMMARY_COLUMNS}/>
                    </AccordionDetails>
                </Accordion>
            </CustomDialog>
        </div>
    )
};
UpdateCustomer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sourceCustomer: PropTypes.object.isRequired,
    mode: PropTypes.string
};
export default UpdateCustomer;



