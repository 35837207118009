import {convertDayjsDate, DATE_LONG_FORMAT} from "../../utils/constants";

export const USER_COLUMNS = [
    {field: 'username', headerName: 'Tên đăng nhập', width: 200},
    {field: 'name', headerName: 'Tên nhân viên', width: 200},
    {field: 'email', headerName: 'Địa chỉ email', width: 200},
    {field: 'translatedStatus', headerName: 'Trạng thái', width: 150},
    {field: 'createdBy', headerName: 'Người tạo', width: 150},
    {
        field: 'createdAt', headerName: 'Ngày tạo', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
    {field: 'updatedBy', headerName: 'Người cập nhật', width: 200},
    {
        field: 'updatedAt', headerName: 'Ngày cập nhật', width: 200,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
];

export const UserStatus = {
    ACTIVE: {code: 'ACTIVE', label: 'Đang hoạt động'},
    INACTIVE: {code: 'INACTIVE', label: 'Ngưng hoạt động'},
    REQUIRED_PWD_CHANGE: {code: 'REQUIRED_PWD_CHANGE', label: 'Y/C đổi mật khẩu'},
    UNKNOWN: {code: 'UNKNOWN', label: 'Không xác định'},
};

export const getUserStatusLabel = (code) => {
    const status = Object.values(UserStatus).find((status) => status.code === code);
    return status ? status.label : 'Không xác định';
};
export const DEFAULT_USER_DATA = {
    username: '',
    name: '',
    email: '',
    password: '',
};

export const SELECTED_USER_ACTIONS = [
    {
        label: 'Cập nhật',
        code: 'UPDATED',
        actStatus: ['ACTIVE', 'INACTIVE', 'REQUIRED_PWD_CHANGE'],
        permission: 'UPDATE'
    },
    {
        label: 'Sửa Mật Khẩu',
        code: 'UPDATED_PASSWORD',
        actStatus: ['ACTIVE', 'INACTIVE', 'REQUIRED_PWD_CHANGE'],
        permission: 'RESET_PASSWORD'
    },
    {label: 'Khóa Tài Khoản', code: 'LOCK_ACCOUNT', actStatus: ['ACTIVE', 'REQUIRED_PWD_CHANGE'], permission: 'LOCK'},
    {label: 'Phân Quyền', code: 'MANAGE_PERMISSIONS', actStatus: ['ACTIVE'], permission: 'PERMISSION'}
];
