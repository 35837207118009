import React, {memo, useEffect, useRef} from 'react';
import {Box} from "@mui/material";
import {isDesktop} from "react-device-detect";

function TradingViewTickerTape() {
    const container = useRef();
    const getSymbols = () => {
        if (isDesktop) {
            return `[
                {
                  "description": "Chỉ số Composite Nasdaq",
                  "proName": "NASDAQ:IXIC"
                },
                {
                  "description": "Giá vàng thế giới",
                  "proName": "OANDA:XAUUSD"
                },
                {
                  "proName": "FX_IDC:EURUSD",
                  "title": "Giá đồng EUR to USD"
                },
                {
                  "proName": "FOREXCOM:SPXUSD",
                  "title": "Chỉ số S&P 500"
                },
                {
                  "description": "Tiền số Bitcoin",
                  "proName": "COINBASE:BTCUSD"
                },
                {
                  "proName": "COINBASE:ETHUSD",
                  "title": "Tiền số Ethereum"
                }
            ]`;
        } else {
            return `[
                {
                  "description": "Giá vàng thế giới",
                  "proName": "OANDA:XAUUSD"
                },
                {
                  "proName": "FX_IDC:EURUSD",
                  "title": "Giá đồng EUR to USD"
                },
                {
                  "proName": "FOREXCOM:SPXUSD",
                  "title": "Chỉ số S&P 500"
                },
                {
                  "description": "Tiền số Bitcoin",
                  "proName": "COINBASE:BTCUSD"
                }
            ]`
        }
    }
    useEffect(() => {
        if (!container.current) return;
        if (container.current.querySelector("script")) return;
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.innerHTML = `
        {
            "symbols": ${getSymbols()},
            "showSymbolLogo": true,
            "isTransparent": false,
            "displayMode": "adaptive",
            "colorTheme": "light",
            "locale": "vi_VN"
        }`;

        container.current.appendChild(script);
    }, []);

    return (
        <Box>
            <div className="tradingview-widget-container" ref={container}>
                <div className="tradingview-widget-container__widget"></div>
            </div>
        </Box>
    );
}

export default memo(TradingViewTickerTape);
