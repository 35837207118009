import {vi_locale} from 'dayjs/locale/vi';
import {
    convertDayjsDate,
    DATE_SHORT_FORMAT,
    formatCustomerName,
    getResponsiveWidth,
    handleApiError,
    handleSuccessMessage,
    readMoneyInVietnameseDong,
    setUserTime
} from "../../utils/constants";
import React, {useEffect, useState} from "react";
import {Grid, InputAdornment, styled, TextField} from "@mui/material";
import axios from '../../services/axiosInstance';
import {
    fetchOrderDetails,
    getExtraBuyJewelries,
    getTotalAmount,
    getTotalWeight,
    ORDER_TYPE,
    ORDERS_API_ENDPOINT
} from "./OrderConstant";
import BuyItem from "./BuyItem";
import SellItem from "./SellItem";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {handlePrinting} from "./OrderStamp";
import {useAlert} from "../../providers/AlertProvider";
import CustomDialog from "../../components/dialog/CustomDialog";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import PropTypes from "prop-types";

const UpdateOrder = ({open, onClose, sourceOrder, mode = "READ_ONLY"}) => {
    const {openAlert} = useAlert();
    const [orderData, setOrderData] = useState(sourceOrder);
    const [totalAmountValid, setTotalAmountValid] = useState(true);
    const [buyAmountValid, setBuyAmountValid] = useState(true);
    const [sellAmountValid, setSellAmountValid] = useState(true);

    useEffect(() => {
        setOrderData(sourceOrder);
    }, [open, sourceOrder])

    useEffect(() => {
        if (mode !== "READ_ONLY") {
            const buyAmount = orderData.buyItems.reduce((total, item) => total + item.amount, 0);
            setOrderData((prev) => ({...prev, buyAmount: buyAmount}));

            const sellAmount = orderData.sellItems.reduce((total, item) => total + item.amount, 0);
            setOrderData((prev) => ({...prev, sellAmount: sellAmount}));

            calculateTotalAmount(buyAmount, sellAmount, orderData.discount);

            setTotalAmountValid(true);
            setBuyAmountValid(true);
            setSellAmountValid(true);
        }

    }, [orderData.buyItems, orderData.sellItems, orderData.discount, mode]);

    const calculateTotalAmount = (buyAmount = 0, sellAmount = 0, discount = 0) => {
        const totalAmount = sellAmount - buyAmount - discount;
        setOrderData((prev) => ({...prev, totalAmount: totalAmount}));
    };
    const getAmountDes = (totalAmount = 0) => {
        if (totalAmount >= 0) return "(Phải thu)";
        return "(Phải chi)";
    }

    const onUpdateOrder = async (orderData) => {
        try {
            const response = await axios.put(`${ORDERS_API_ENDPOINT}/${orderData.orderId}`, orderData);
            handleSuccessMessage('Đơn hàng số [' + response.data.orderId + '] được lưu thành công', openAlert);
            onClose();
        } catch (error) {
            handleApiError(error, openAlert);
        }
    };
    const handleSave = async () => {
        await onUpdateOrder(orderData);
    };
    const handleSaveAndPrint = async () => {
        await handleSave();
        const order = await fetchOrderDetails(orderData.orderId);
        const extraBuyJewelries = getExtraBuyJewelries(orderData.buyItems);
        const formattedData = {
            ...order,
            orderDate: convertDayjsDate(order.orderDate),
            customerName: formatCustomerName(order.customer),
            sellItems: order.sellItems?.map((item) => ({
                ...item,
                id: item.itemId
            })),
            buyItems: order.buyItems?.map((item) => ({
                ...item,
                id: item.itemId
            })),
            extraBuyJewelries: extraBuyJewelries,
            extraGoldWeight: getTotalWeight(extraBuyJewelries),
            extraGoldAmount: getTotalAmount(extraBuyJewelries),
        };
        await handlePrinting(formattedData);
    }
    const Div = styled('div')(({theme}) => ({
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        textAlign: 'right',
        fontWeight: 'bold',
    }));
    const handleClose = async () => {
        onClose();
    };

    const formActions = (
        (mode !== "READ_ONLY") ? (<>
            <CustomLoadingButton variant="contained" onClick={handleSaveAndPrint}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Lưu và in"/>
            <CustomLoadingButton variant="contained" onClick={handleSave}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Chỉ Lưu"/>
        </>) : <></>
    );
    const pageTitle = (
        <>
            {`Thông tin đơn hàng số: ${orderData.orderId}`}
        </>
    );
    return (
        <div>
            <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
                <Grid container spacing={2}>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Tên khách hàng"
                            disabled
                            value={orderData.customerName}
                            fullWidth
                        />
                    < /Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={vi_locale}>
                            <DatePicker label="Ngày tạo"
                                        value={orderData.orderDate}
                                        onChange={(date) => {
                                            setOrderData((prev) => ({
                                                ...prev,
                                                orderDate: setUserTime(date),
                                            }));
                                        }}
                                        fullWidth
                                        format={DATE_SHORT_FORMAT}
                                        slotProps={{
                                            field: {shouldRespectLeadingZeros: true},
                                            textField: {fullWidth: true}
                                        }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Ghi chú"
                            value={orderData.orderDescription}
                            onChange={(e) =>
                                setOrderData((prev) => ({
                                    ...prev,
                                    orderDescription: e.target.value,
                                }))
                            }
                            fullWidth
                        />
                    </Grid>
                    {(ORDER_TYPE.SELL_ORDER.code === orderData.orderType || ORDER_TYPE.EXEC_ORDER.code === orderData.orderType) && (
                        <Grid item xs={12}><SellItem orderData={orderData} setOrderData={setOrderData}/></Grid>)}
                    {(ORDER_TYPE.BUY_ORDER.code === orderData.orderType || ORDER_TYPE.EXEC_ORDER.code === orderData.orderType) && (
                        <Grid item xs={12}><BuyItem orderData={orderData} setOrderData={setOrderData}/></Grid>)}
                    {(ORDER_TYPE.SELL_ORDER.code === orderData.orderType || ORDER_TYPE.EXEC_ORDER.code === orderData.orderType) && (
                        <Grid item xs={12} container>
                            <Grid item xs={6}><Div>{"Tiền vàng mới: "}</Div></Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    value={Number(orderData.sellAmount).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/\D/g, '');
                                        setOrderData((prev) => ({
                                            ...prev,
                                            sellAmount: numericInput,
                                        }));
                                        calculateTotalAmount(orderData.buyAmount, numericInput, orderData.discount);
                                    }}
                                    fullWidth
                                    error={!sellAmountValid}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>)}
                    {(ORDER_TYPE.BUY_ORDER.code === orderData.orderType || ORDER_TYPE.EXEC_ORDER.code === orderData.orderType) && (
                        <Grid item xs={12} container>
                            <Grid item xs={6}><Div>{"Tiền vàng khách: "}</Div></Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    value={Number(orderData.buyAmount).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/\D/g, '');
                                        setOrderData((prev) => ({
                                            ...prev,
                                            buyAmount: numericInput,
                                        }));
                                        calculateTotalAmount(numericInput, orderData.sellAmount, orderData.discount);
                                    }}
                                    fullWidth
                                    error={!buyAmountValid}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>)}
                    {(ORDER_TYPE.SELL_ORDER.code === orderData.orderType || ORDER_TYPE.EXEC_ORDER.code === orderData.orderType) && (
                        <Grid item xs={12} container>
                            <Grid item xs={6}><Div>{"Tiền giảm giá: "}</Div></Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    value={Number(orderData.discount).toLocaleString()}
                                    onChange={(e) => {
                                        const numericInput = e.target.value.replace(/\D/g, '');
                                        setOrderData((prev) => ({
                                            ...prev,
                                            discount: numericInput,
                                        }));
                                        calculateTotalAmount(orderData.buyAmount, orderData.sellAmount, numericInput);
                                    }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">vnđ</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid></Grid>
                    )}
                    <Grid item xs={12} container>
                        <Grid item
                              xs={6}><Div>{"Tổng thanh toán"} {getAmountDes(orderData.totalAmount)}{": "}</Div></Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                value={Number(orderData.totalAmount).toLocaleString()}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/\D/g, '');
                                    setOrderData((prev) => ({
                                        ...prev,
                                        totalAmount: numericInput,
                                    }));
                                    setTotalAmountValid(true);
                                }}
                                fullWidth
                                error={!totalAmountValid}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid></Grid>
                    <Grid item
                          xs={12}><Div>{"Bằng chữ:"} {readMoneyInVietnameseDong(orderData.totalAmount)}</Div></Grid>
                </Grid>
            </CustomDialog>
        </div>
    );
}

UpdateOrder.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    sourceOrder: PropTypes.object.isRequired,
    mode : PropTypes.string
}
export default UpdateOrder;
