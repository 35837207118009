import dayjs from "dayjs";
import {convertDayjsDate, DATE_LONG_FORMAT} from "../../utils/constants";

export const INVOICE_COLUMNS = [
    {field: 'invoiceId', headerName: 'Mã số', minWidth: 120},
    {field: 'orderId', headerName: 'Mã đơn hàng', minWidth: 170},
    {field: 'invoiceStatus', headerName: 'Trạng thái', minWidth: 150},
    {field: 'invoiceNo', headerName: 'Số hóa đơn', minWidth: 150},
    {field: 'invoiceSeries', headerName: 'Mẫu số - Ký hiệu', minWidth: 200},
    {
        field: 'invoiceIssuedDate', headerName: 'Ngày lập hóa đơn', minWidth: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
    {field: 'buyerName', headerName: 'Họ tên người mua hàng', minWidth: 220},
    {field: 'buyerTaxCode', headerName: 'Mã số thuế', minWidth: 150},
    {field: 'buyerLegalName', headerName: 'Tên đơn vị', minWidth: 200},
    {field: 'buyerPhoneNumber', headerName: 'Số điện thoại', minWidth: 150},
    {field: 'buyerEmail', headerName: 'Địa chỉ email', minWidth: 150},
    {field: 'createdBy', headerName: 'Người tạo', minWidth: 150},
    {
        field: 'createdAt', headerName: 'Ngày tạo', minWidth: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
    {field: 'updatedBy', headerName: 'Người cập nhật', minWidth: 150},
    {
        field: 'updatedAt', headerName: 'Ngày cập nhật', minWidth: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
];
export const DEFAULT_VISITING_GUEST_NAME = 'Khách lẻ không lấy hóa đơn';
export const DEFAULT_INVOICE_DATA = {
    orderId: 0,
    invoiceId: 0,
    invoiceSeries: '',
    paymentType: 'CASH',
    currencyCode: 'VND',
    status: 'DRAFT',
    invoiceIssuedDate: dayjs(),
    buyerInfo: {
        visitingGuest: true,
        buyerName: DEFAULT_VISITING_GUEST_NAME,
        buyerLegalName: '',
        buyerPhoneNumber: '',
        buyerEmail: '',
        buyerAddressLine: '',
        buyerTaxCode: '',
        buyerIdNo: '',
    },
    itemInfo: [],
};
export const SELECTED_INVOICE_ACTIONS = [
    {label: 'Thông tin', code: 'VIEW', actStatus: ['COMPLETED', 'DRAFT', 'FAILED'], permission: 'DETAILS'},
    {label: 'Cập Nhật', code: 'UPDATE', actStatus: ['DRAFT', 'FAILED'], permission: 'UPDATE'},
    {label: 'Tải hóa đơn', code: 'DOWNLOAD', actStatus: ['COMPLETED'], permission: 'DETAILS'},
    {label: 'Xóa Hóa đơn', code: 'DELETE', actStatus: ['DRAFT', 'FAILED'], permission: 'DELETE'},
];

export const mapStatusToText = (invoiceStatus) => {
    switch (invoiceStatus) {
        case 'COMPLETED':
            return 'Đã xuất HĐ thuế';
        case 'DRAFT':
            return 'Chưa xuất HĐ thuế';
        case 'FAILED':
            return 'Lỗi chưa xuất HĐ thuế';
        default:
            return 'Không xác định';
    }
};
