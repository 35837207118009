import {Box, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    CREATE_ORDER_ACTIONS,
    DEFAULT_ORDER_DATA,
    fetchOrderDetails,
    getExtraBuyJewelries,
    getTotalAmount,
    getTotalWeight,
    ORDER_COLUMNS,
    ORDER_DEFAULT_FILTER,
    ORDER_TYPE,
    ORDERS_API_ENDPOINT,
    SELECTED_ORDER_ACTIONS
} from "./OrderConstant";
import CreateOrder from "./CreateOrder";
import axios from '../../services/axiosInstance';
import {
    ADMINISTRATOR_USER_NAME,
    calculateAmount,
    convertDayjsDate,
    fetchSystemInfo,
    formatCurrency,
    formatCustomerName,
    generateBarcode,
    getCurrentUser,
    getResponsiveWidth,
    handleApiError,
    handleErrorMessage,
    handleSuccessMessage,
    QUICK_ACTION_CREATE_BUY_ORDER,
    QUICK_ACTION_CREATE_EXCHANGE_ORDER,
    QUICK_ACTION_CREATE_SELL_ORDER, ROUTE_INVOICE_MGMT,
    UNKNOWN_ACTIONS
} from "../../utils/constants";
import DeleteOrder from "./DeleteOrder";
import UpdateOrder from "./UpdateOrder";
import {handlePrinting} from "./OrderStamp";
import {useAlert} from "../../providers/AlertProvider";
import DropDownButton from "../../components/common/DropDownButton";
import CustomDataGrid from "../../components/grid/CustomDataGrid";
import {useLocation, useNavigate} from "react-router-dom";
import {usePermissions} from "../../providers/PermissionProvider";
import {DEFAULT_CUSTOMER_DATA, getCustomerDetailsById} from "../customer/CustomerConstants";
import UpdateCustomer from "../customer/UpdateCustomer";
import YesNoAlertDialog from "../../components/dialog/YesNoAlertDialog";

const OrderGrid = () => {
    const permissions = usePermissions();
    const {openAlert} = useAlert();
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 100,
        totalRowCount: 100,
        summary: {},
    });
    const [loading, setLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(undefined);
    const [orders, setOrders] = useState([])
    const [openCreateOrder, setOpenCreateOrder] = useState(false);
    const [orderType, setOrderType] = useState(ORDER_TYPE.SELL_ORDER.code);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [orderData, setOrderData] = useState(DEFAULT_ORDER_DATA);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [selectedAction, setSelectedAction] = React.useState('VIEW');
    const [selectedCreateAction, setSelectedCreateAction] = React.useState('EXCHANGE');
    const location = useLocation();
    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState(DEFAULT_CUSTOMER_DATA);
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [statusUpdateDialog, setStatusUpdateDialog] = useState(false);
    const [statusUpdateContent, setStatusUpdateContent] = useState('');
    const handleChangePage = async (page, event) => {
        setPagination((prevPage) => ({
            ...prevPage,
            page: page?.page,
            pageSize: page?.pageSize,
        }));
    };
    const handleCreateSellOrderClick = () => {
        setOrderType(ORDER_TYPE.SELL_ORDER.code);
        setOpenCreateOrder(true);
    };
    const handleCreateBuyOrderClick = () => {
        setOrderType(ORDER_TYPE.BUY_ORDER.code);
        setOpenCreateOrder(true);
    };
    const handleCreateExchangeOrderClick = () => {
        setOrderType(ORDER_TYPE.EXEC_ORDER.code);
        setOpenCreateOrder(true);
    };
    const fetchOrders = async (pageNumber, pageSize, reqFilter = undefined) => {
        try {
            setLoading(true);
            const searchRequest = reqFilter || ORDER_DEFAULT_FILTER
            const response = await axios.post(
                `${ORDERS_API_ENDPOINT}/find?page=${pageNumber}&size=${pageSize}&sort=orderId,desc`, searchRequest);
            setOrders(response.data.content.map((order) => ({
                ...order,
                id: order.orderId,
                orderDate: order.orderDate,
                totalAmount: order.totalAmount,
                sellAmount: order.sellAmount,
                buyAmount: order.buyAmount,
                discount: order.discount,
                createdAt: order.createdAt,
                updatedAt: order.updatedAt,
                customerName: formatCustomerName(order.customer),
                customerId: order.customer?.customerId ? order.customer.customerId : 0,
                orderType: order.orderType,
            })));
            setPagination(prevPagination => ({
                ...prevPagination,
                totalRowCount: response?.data?.totalElements,
                pageSize: response?.data?.size,
                totalPages: response.data.totalPages,
                currentPage: response.data.number,
                summary: calculateSummary(response.data.content, response?.data?.totalElements),
            }));
        } catch (error) {
            console.error("Error while fetching data: ", error);
            handleApiError(error, openAlert);
        } finally {
            setLoading(false);
        }
    }
    const calculateSummary = (orders, totalElements) => {
        const totalItem = 'Số đơn: ' + orders.length + ' của ' + totalElements;
        const amount = orders.reduce((sum, order) => sum + (order.totalAmount || 0), 0);
        const totalAmount = 'Số tiền: ' + formatCurrency(amount);
        return {totalItem, totalAmount, totalElements};
    };
    const loadOrderDetails = async (orderId) => {
        try {
            const order = await fetchOrderDetails(orderId);
            const formattedData = {
                ...order,
                orderDate: convertDayjsDate(order.orderDate),
                customerName: formatCustomerName(order.customer),
                sellItems: order.sellItems?.map((item) => ({
                    ...item,
                    id: item.itemId
                })),
                buyItems: order.buyItems?.map((item) => ({
                    ...item,
                    id: item.itemId
                }))
            };
            setOrderData(formattedData);
            return order;
        } catch (error) {
            console.error("Error while fetching jewelry details:", error)
            handleApiError(error, openAlert);
        }
    };
    const handleDeleteClick = async (orderId) => {
        await loadOrderDetails(orderId)
        setOpenDeleteDialog(true);
    }
    const handleViewClick = async (orderId) => {
        await loadOrderDetails(orderId)
        setOpenViewDialog(true);

    }
    const handleUpdateClick = async (orderId) => {
        await loadOrderDetails(orderId)
        setOpenUpdateDialog(true);
    }
    const handlePrintClick = async (orderId) => {
        const order = await fetchOrderDetails(orderId);
        const barcode = generateBarcode(order.orderId);
        const extraBuyJewelries = getExtraBuyJewelries(orderData.buyItems);
        const formattedData = {
            ...order,
            orderDate: convertDayjsDate(order.orderDate),
            customerName: formatCustomerName(order.customer),
            sellItems: order.sellItems?.map((item) => ({
                ...item,
                id: item.itemId,
                goldAmount: calculateAmount(item.goldWeight, item.price),
            })),
            buyItems: order.buyItems?.map((item) => ({
                ...item,
                id: item.itemId
            })),
            barcode,
            extraBuyJewelries: extraBuyJewelries,
            extraGoldWeight: getTotalWeight(extraBuyJewelries),
            extraGoldAmount: getTotalAmount(extraBuyJewelries),
        };
        await handlePrinting(formattedData);
    };
    const handleActionClick = async (action) => {
        await fetchSystemInfo(openAlert);
        setOrderData({...orderData, sellItems: [], buyItems: []});
        switch (action) {
            case 'BUY': {
                return handleCreateBuyOrderClick();
            }
            case 'SELL': {
                return handleCreateSellOrderClick();
            }
            default: {
                return handleCreateExchangeOrderClick();
            }
        }
    };
    const getCustomerDetails = async (order) => {
        const customerId = order.customer?.customerId || 0;
        try {
            if (customerId !== 0) {
                const response = await getCustomerDetailsById(customerId);
                setCustomerData(response);
                setOpenCustomerDialog(true);
            }
        } catch (error) {
            console.error("Error while fetching data: ", error);
            handleApiError(error, openAlert)
        } finally {
            setLoading(false);
        }
    }

    const handleCreateInvoiceClick = async (orderId) => {
        navigate(`${ROUTE_INVOICE_MGMT}?requestAction=create&orderId=${orderId}`);
    }
    const statusUpdateClick = async (orderId) => {
        const item = await loadOrderDetails(orderId)
        if (item.visible) {
            setOrderData(prev => ({
                ...prev,
                visible: false,
            }));
            setStatusUpdateContent(`Đơn hàng [${item.orderId}] đang ở trạng thái [hiển thị], Bạn muốn ẩn nó?`);
        } else {
            setOrderData(prev => ({
                ...prev,
                visible: true,
            }));
            setStatusUpdateContent(`Đơn hàng [${item.orderId}] đang ở trạng thái [ẩn], Bạn muốn hiển thị nó?`)
        }
        setStatusUpdateDialog(true);
    }
    const handleActActionClick = async (action) => {
        try {
            setLoading(true);
            if (!selectedOrder) return;
            const orderId = selectedOrder.orderId;
            await fetchSystemInfo(openAlert);
            switch (action) {
                case 'UPDATED': {
                    return await handleUpdateClick(orderId);
                }
                case 'DELETE': {
                    return await handleDeleteClick(orderId);
                }
                case 'RECEIPT': {
                    return await handlePrintClick(orderId);
                }
                case 'CUSTOMER': {
                    await getCustomerDetails(selectedOrder);
                    break;
                }
                case 'INVOICE': {
                    await handleCreateInvoiceClick(orderId);
                    break;
                }
                case 'UPDATED_STATUS': {
                    return await statusUpdateClick(orderId);
                }
                case 'UNKNOWN': {
                    return;
                }
                default: {
                    return await handleViewClick(orderId);
                }
            }
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setLoading(false);
        }
    };
    const handleMenuItemClick = (event, selectedAction) => {
        setSelectedCreateAction(selectedAction);
    };

    const handleQuickActionActionClick = async (quickAction) => {
        await fetchSystemInfo(openAlert);
        setOrderData({...orderData, sellItems: [], buyItems: []});
        switch (quickAction) {
            case QUICK_ACTION_CREATE_BUY_ORDER: {
                return handleCreateBuyOrderClick();
            }
            case QUICK_ACTION_CREATE_SELL_ORDER: {
                return handleCreateSellOrderClick();
            }
            case QUICK_ACTION_CREATE_EXCHANGE_ORDER: {
                return handleCreateExchangeOrderClick();
            }
            default: {
                return handleCreateBuyOrderClick();
            }
        }

    };
    useEffect(() => {
        const {quickAction} = location.state || {};
        if (quickAction) {
            const openCreateOrder = async () => {
                await handleQuickActionActionClick(quickAction);
                navigate(location.state, {quickAction: undefined});
            }
            openCreateOrder().then(() => console.log("Order create actioned!"));
        }
        // eslint-disable-next-line
    }, [location.state]);

    useEffect(() => {
        const {filter} = location.state || {};
        const fetchData = async () => {
            const requestBody = filter || ORDER_DEFAULT_FILTER;
            await fetchOrders(pagination.page, pagination.pageSize, requestBody);
        };
        fetchData().then(() => console.log("Order fetched!"));
        // eslint-disable-next-line
    }, [pagination.page, pagination.pageSize, location.state]);


    const getAvailableOptions = () => {
        const status = selectedOrder ? selectedOrder.status : undefined;
        if (!status || !permissions || !permissions['ORDER']) {
            return UNKNOWN_ACTIONS;
        }
        const actions = SELECTED_ORDER_ACTIONS.filter(action =>
                action.actStatus.includes(status)
                && (
                    (permissions.ORDER?.includes(action.permission) &&
                        (!action.administration || (action.administration && ADMINISTRATOR_USER_NAME === getCurrentUser())))
                    || permissions.CUSTOMER?.includes(action.customerPermission)
                    || permissions.INVOICE?.includes(action.invoicePermission)
                )
        );
        if (actions.length > 0 && !actions.find(action => action.code === selectedAction)) {
            setSelectedAction(actions[0].code);
        } else if (actions.length === 0) {
            return UNKNOWN_ACTIONS;
        }
        return actions;
    }
    const getAvailableCreateOptions = () => {
        return CREATE_ORDER_ACTIONS;
    }
    const handleActMenuItemClick = (event, selectedAction) => {
        setSelectedAction(selectedAction);
    };
    const handleSetSelectedRow = async (selectedRow, rows) => {
        try {
            const selectedId = selectedRow.length === 1 ? selectedRow[0] : 0;
            const rowData = rows.find((row) => row.orderId === selectedId);
            setSelectedOrder(rowData);
        } catch (error) {
            console.log("Error at process selected item", selectedRow);
        }
    };
    const handleFindById = async (orderId) => {
        const order = await loadOrderDetails(orderId);
        if (order) {
            return await handleViewClick();
        }
    };
    const handleSearch = async (searchFilter) => {
        navigate(location.state, {filter: undefined});
        const searchResult = await fetchOrders(pagination.page, pagination.pageSize, searchFilter);
        if (searchResult && searchResult.totalElements === 1 && searchResult.content[0].orderId) {
            await handleFindById(searchResult.content[0].orderId);
        }
    };
    const disableButton = () => {
        return !selectedOrder;
    }

    const processUpdateStatus = async () => {
        try {
            await axios.patch(`${ORDERS_API_ENDPOINT}/${orderData.orderId}/visible`, orderData);
            handleSuccessMessage(`Thay đổi thành công đơn hàng [${orderData.orderId}]`, openAlert);
            await fetchOrders(pagination.page, pagination.pageSize);
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            setStatusUpdateDialog(false);
        }
    };
    return (
        <div>
            <Box>
                <Grid container spacing={2} sx={{paddingBottom: 2}}>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        {permissions.ORDER?.includes('CREATE') && (
                            <DropDownButton
                                fullWidth={true}
                                disabled={false}
                                options={getAvailableCreateOptions()}
                                action={selectedCreateAction}
                                handleActionClick={handleActionClick}
                                handleMenuItemClick={handleMenuItemClick}
                                isLoading={false}
                            />)}
                    </Grid>
                    <Grid item xs={getResponsiveWidth(6, 6)}>
                        <DropDownButton
                            fullWidth={true}
                            disabled={disableButton()}
                            options={getAvailableOptions()}
                            action={selectedAction}
                            handleActionClick={handleActActionClick}
                            handleMenuItemClick={handleActMenuItemClick}
                            isLoading={loading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <CustomDataGrid
                rows={orders}
                pagination={pagination}
                loading={loading}
                setSelectedRows={handleSetSelectedRow}
                columns={ORDER_COLUMNS}
                onSearch={handleSearch}
                onPaginationModelChange={handleChangePage}
                onFindById={handleFindById}
                pageSummary={pagination.summary}
                onExportData={() => {
                    handleErrorMessage("Chức năng đang phát triển.", openAlert);
                }}
            />
            <CreateOrder open={openCreateOrder} onClose={async () => {
                setOpenCreateOrder(false);
                await fetchOrders(pagination.page, pagination.pageSize);
            }} orderType={orderType}/>
            <DeleteOrder open={openDeleteDialog} onClose={async () => {
                setOpenDeleteDialog(false);
                await fetchOrders(pagination.page, pagination.pageSize);
            }} orderData={orderData}/>
            <UpdateOrder open={openViewDialog} onClose={() => {
                setOpenViewDialog(false);
            }} sourceOrder={orderData}
                         mode="READ_ONLY"/>
            <UpdateOrder open={openUpdateDialog} onClose={async () => {
                setOpenUpdateDialog(false);
                await fetchOrders(pagination.page, pagination.pageSize);
            }} sourceOrder={orderData}
                         mode="UPDATE"/>
            <UpdateCustomer open={openCustomerDialog} onClose={async () => {
                setOpenCustomerDialog(false);
            }} sourceCustomer={customerData}/>

            <YesNoAlertDialog open={statusUpdateDialog} onClose={() => {
                setStatusUpdateDialog(false);
            }}
                              content={statusUpdateContent}
                              onAccept={processUpdateStatus}/>
        </div>
    );
}
export default OrderGrid;
