import {DataGrid} from "@mui/x-data-grid";
import {Box, LinearProgress} from "@mui/material";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import {useEffect, useState} from "react";
import CustomGridToolbar from "./CustomGridToolbar";
import CustomGridFooter from "./CustomGridFooter";
import PropTypes from "prop-types";

const CustomDataGrid = ({
                            rows,
                            loading,
                            setSelectedRows,
                            columns,
                            onSearch,
                            pagination,
                            onPaginationModelChange,
                            onFindById,
                            pageSummary,
                            onExportData,
                        }) => {
    const [rowData, setRowData] = useState(rows);
    useEffect(() => {
        setRowData(rows);
    }, [rows, pagination, pageSummary]);
    return (
        <Box sx={{
            width: '100%', height: '75vh',
            '& .due-date-warn': {backgroundColor: '#EEE482FF'},
            '& .due-date-red': {backgroundColor: '#F8978EFF'},
            '& .due-date-green': {backgroundColor: '#3AA622FF'},
        }}>
            <DataGrid
                slots={{
                    toolbar: CustomGridToolbar,
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: CustomNoRowsOverlay,
                    footer: CustomGridFooter,
                }}
                slotProps={{
                    toolbar: {
                        onSearch: onSearch,
                        onFindById: onFindById
                    },
                    footer: {
                        summary: pageSummary,
                        onExportFunction: onExportData
                    },
                }}
                disableColumnFilter={true}
                disableDensitySelector={true}
                paginationMode="server"
                onRowSelectionModelChange={(selection) => {
                    setSelectedRows(selection, rows);
                }}
                rows={rowData}
                columns={columns}
                pageSizeOptions={[pagination.pageSize]}
                rowCount={pagination.totalRowCount}
                loading={loading}
                paginationModel={pagination}
                onPaginationModelChange={onPaginationModelChange}
            />
        </Box>
    );
};
CustomDataGrid.propTypes = {
    loading: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    onPaginationModelChange: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    onFindById: PropTypes.func,
    pageSummary: PropTypes.func.isRequired,
    onExportData: PropTypes.func.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    rows: PropTypes.array,
}
export default CustomDataGrid;
