import CustomDialog from "../../components/dialog/CustomDialog";
import {Autocomplete, Grid, InputAdornment, MenuItem, Select, Switch, TextField} from "@mui/material";
import {isMobile} from "react-device-detect";
import React, {useEffect, useState} from "react";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import {
    calculateAmount,
    calculateGoldWeight,
    getProcAmounts,
    getResponsiveWidth,
    getSortedGoldBrand,
    getSortedGoldType,
    isNumber
} from "../../utils/constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ORDER_TYPE} from "./OrderConstant";
import PropTypes from "prop-types";

const SellItemDetails = ({open, onClose, sourceJewelry, onItemAdded}) => {
    const [jewelryData, setJewelryData] = useState(sourceJewelry);
    const [itemNameValid, setItemNameValid] = useState(true);
    const [goldWeightValid, setGoldWeightValid] = useState(true);
    const [gemWeightValid, setGemWeightValid] = useState(true);
    const [priceValid, setPriceValid] = useState(true);
    const [procPriceValid, setProcPriceValid] = useState(true);
    const [amountValid, setAmountValid] = useState(true);
    useEffect(() => {
        setJewelryData(sourceJewelry);
    }, [open, sourceJewelry]);
    const handleClose = async () => {
        onClose();
    };
    const handleAddSellItem = () => {
        let validated = true;
        if (jewelryData.name.trim() === "") {
            setItemNameValid(false);
            validated = false;
        } else {
            setItemNameValid(true);
        }
        if (jewelryData.goldWeight && jewelryData.goldWeight !== 0) {
            setGoldWeightValid(true);
        } else {
            setGoldWeightValid(false);
            validated = false;
        }
        if (isNumber(jewelryData.gemWeight)) {
            setGemWeightValid(true);
        } else {
            validated = false;
            setGemWeightValid(false);
        }
        if (isNumber(jewelryData.price)) {
            setPriceValid(true);
        } else {
            setPriceValid(false);
            validated = false;
        }
        if (isNumber(jewelryData.procPrice)) {
            setProcPriceValid(true);
        } else {
            setProcPriceValid(false);
            validated = false;
        }
        if (isNumber(jewelryData.amount)) {
            setAmountValid(true);
        } else {
            setAmountValid(false);
            validated = false;
        }
        if (validated) {
            return onItemAdded(jewelryData);
        }
    }
    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleAddSellItem}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Thêm vào đơn"/>
            <CustomLoadingButton variant="contained" onClick={handleClose}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Hủy"/>
        </>
    );
    const pageTitle = (
        <>
            {`Thêm món hàng${jewelryData.jewelryId ? ` mã số [${jewelryData.jewelryId}], tên [${jewelryData.name}]` : ''} vào đơn!`}
        </>
    );
    return (
        <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
            <Grid container spacing={2}>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Tên món hàng"
                        value={jewelryData.name ? jewelryData.name : ''}
                        onChange={(e) => {
                            setJewelryData((prev) => ({
                                ...prev,
                                name: e.target.value,
                            }));
                            setItemNameValid(true);
                        }}
                        fullWidth
                        error={!itemNameValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(6, 12)}>
                    <Select
                        value={jewelryData.goldType}
                        fullWidth
                        MenuProps={{
                            disableAutoFocusItem: true,
                        }}
                        onChange={(e) => {
                            setJewelryData((prev) => ({
                                ...prev,
                                goldType: e.target.value,
                            }));
                        }}
                    >
                        {getSortedGoldType().map((goldType) => (
                            <MenuItem key={goldType.code} value={goldType.code}>
                                {goldType.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={getResponsiveWidth(6, 12)}>
                    <Select
                        value={jewelryData.goldBrand}
                        fullWidth
                        MenuProps={{
                            disableAutoFocusItem: true,
                        }}
                        onChange={(e) => {
                            setJewelryData((prev) => ({
                                ...prev,
                                goldBrand: e.target.value,
                            }));
                        }}
                    >
                        {getSortedGoldBrand().map((goldBrand) => (
                            <MenuItem key={goldBrand.code} value={goldBrand.code}>
                                {goldBrand.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={getResponsiveWidth(6, 12)}>
                    <TextField
                        label={(isMobile) ? "TL tổng" : "Trọng lượng tổng"}
                        value={jewelryData.totalWeight}
                        fullWidth
                        InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                            const goldWeight = calculateGoldWeight(numericInput, jewelryData.gemWeight);
                            const amount = calculateAmount(goldWeight, jewelryData.price, jewelryData.procPrice, jewelryData.discount);
                            const goldAmount = calculateAmount(goldWeight, jewelryData.price);
                            setJewelryData((prev) => ({
                                ...prev,
                                totalWeight: numericInput,
                                goldWeight: goldWeight,
                                amount: amount,
                                goldAmount: goldAmount,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(6, 12)}>
                    <TextField
                        label={(isMobile) ? "TL hột" : "Trọng lượng hột"}
                        value={jewelryData.gemWeight}
                        fullWidth
                        InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                            const goldWeight = calculateGoldWeight(jewelryData.totalWeight, numericInput);
                            const amount = calculateAmount(goldWeight, jewelryData.price, jewelryData.procPrice, jewelryData.discount);
                            const goldAmount = calculateAmount(goldWeight, jewelryData.price);
                            setJewelryData((prev) => ({
                                ...prev,
                                gemWeight: numericInput,
                                goldWeight: goldWeight,
                                amount: amount,
                                goldAmount: goldAmount,
                            }));
                            setGemWeightValid(true);
                        }}
                        error={!gemWeightValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label={(isMobile) ? "TL vàng" : "Trọng lượng vàng"}
                        readOnly disabled
                        value={jewelryData.goldWeight}
                        fullWidth
                        InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                            const amount = calculateAmount(numericInput, jewelryData.price, jewelryData.procPrice, jewelryData.discount);
                            const goldAmount = calculateAmount(numericInput, jewelryData.price);
                            setJewelryData((prev) => ({
                                ...prev,
                                goldWeight: numericInput,
                                amount: amount,
                                goldAmount: goldAmount,
                            }));
                            setGoldWeightValid(true);
                        }}
                        error={!goldWeightValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Đơn giá"
                        value={Number(jewelryData.price).toLocaleString()}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/\D/g, '');
                            const amount = calculateAmount(jewelryData.goldWeight, numericInput, jewelryData.procPrice, jewelryData.discount);
                            const goldAmount = calculateAmount(jewelryData.goldWeight, numericInput);
                            setJewelryData((prev) => ({
                                ...prev,
                                price: numericInput,
                                amount: amount,
                                goldAmount: goldAmount,
                            }));
                            setAmountValid(true);
                            setPriceValid(true);
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                            type: 'search',
                        }}
                        error={!priceValid}
                    />
                </Grid>
                {(jewelryData.jewelryId && ORDER_TYPE.EXEC_ORDER.code === jewelryData.orderType) && (
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <FormControlLabel control={
                            <Switch onChange={(e) => {
                                const price = (e.target.checked) ? sourceJewelry.excProcPrice : sourceJewelry.procPrice;
                                const amount = calculateAmount(jewelryData.goldWeight, jewelryData.price, price, jewelryData.discount);
                                setJewelryData((prev) => ({
                                    ...prev,
                                    procPrice: price,
                                    amount: amount,
                                }));
                            }}/>
                        }
                                          label="Áp dụng tiền công đổi"/>
                    </Grid>
                )}
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <Autocomplete
                        freeSolo
                        value={!isNaN(jewelryData.procPrice) ? Number(jewelryData.procPrice).toLocaleString() : '0'}
                        onChange={(event, selectedValue) => {
                            const numericInput = selectedValue ? selectedValue.value : 0;
                            const amount = calculateAmount(jewelryData.goldWeight, jewelryData.price, numericInput, jewelryData.discount);
                            setJewelryData((prev) => ({
                                ...prev,
                                procPrice: numericInput,
                                amount: amount,
                            }));
                        }}
                        options={getProcAmounts().map((option) => ({
                            label: Number(option).toLocaleString(),
                            value: parseInt(option),
                        }))}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                value={Number(jewelryData.procPrice).toLocaleString()}
                                onChange={(e) => {
                                    const numericInput = e.target.value.replace(/\D/g, '');
                                    const amount = calculateAmount(jewelryData.goldWeight, jewelryData.price, numericInput, jewelryData.discount);
                                    setJewelryData((prev) => ({
                                        ...prev,
                                        procPrice: numericInput,
                                        amount: amount,
                                    }));
                                    setProcPriceValid(true);
                                    setAmountValid(true);
                                }}
                                fullWidth
                                label="Tiền công"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    endAdornment: (
                                        <InputAdornment position="end">vnđ</InputAdornment>
                                    ),
                                }}
                                error={!procPriceValid}
                            />}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Tiền giảm giá"
                        value={Number(jewelryData.discount).toLocaleString()}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/\D/g, '');
                            const discount = numericInput ? parseInt(numericInput) : '';
                            const amount = calculateAmount(jewelryData.goldWeight, jewelryData.price, jewelryData.procPrice, discount);
                            setJewelryData((prev) => ({
                                ...prev,
                                discount: discount,
                                amount: amount,
                            }))
                        }}
                        fullWidth
                        InputProps={{
                            type: 'search',
                            endAdornment: (
                                <InputAdornment position="end">vnđ</InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Thành tiền luôn công"
                        readOnly disabled
                        value={Number(jewelryData.amount).toLocaleString()}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">vnđ</InputAdornment>
                            ),
                        }}
                        error={!amountValid}
                    />
                </Grid>
            </Grid>
        </CustomDialog>
    )
}
SellItemDetails.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    sourceJewelry: PropTypes.object.isRequired,
    onItemAdded: PropTypes.func.isRequired
}
export default SellItemDetails;
