import React from 'react';
import {Navigate} from 'react-router-dom';
import {usePermissions} from "../providers/PermissionProvider";
import {ROUTE_LOGIN} from "../utils/constants";
import PropTypes from "prop-types";

const ProtectedRoute = ({auth = false, requiredPermission, children}) => {
    const permissions = usePermissions();

    if (!auth) {
        return <Navigate to={ROUTE_LOGIN}/>;
    }

    if (requiredPermission) {
        const [entity, action] = requiredPermission.split(':');
        if (!permissions[entity] || !permissions[entity].includes(action)) {
            return <Navigate to="/not-authorized"/>;
        }
    }

    return children;
};
ProtectedRoute.propTypes = {
    auth: PropTypes.bool,
    children: PropTypes.element,
    requiredPermission: PropTypes.any
};
export default ProtectedRoute;
