import dayjs from "dayjs";
import {
    API_ENDPOINT,
    calculateTotalWeight,
    convertDayjsDate,
    DATE_LONG_FORMAT,
    DATE_SHORT_FORMAT,
    formatCurrency,
    formatCustomerName,
    generateIncreasingArray,
    getCommonPawnProducts,
    getConfigureValueByKey,
    getGoldBrandLabelByCode,
    getGoldTypeLabelByCode,
    getTopProducts,
    GoldBrand_Unknown,
    readGoldWeightUnits
} from "../../utils/constants";
import axios from '../../services/axiosInstance';

export const addDays = (date = new Date(), days = 0) => {
    date.setDate(date.getDate() + days);
    return date;
}
export const PAWNS_API_ENDPOINT = API_ENDPOINT + '/pawns';
export const CUSTOMERS_API_ENDPOINT = API_ENDPOINT + '/customers';
export const PAWN_EXPIRATION_DAYS = "PAWN_EXPIRATION_DAYS";
export const PAWN_INTEREST_VALUE = "PAWN_INTEREST_VALUE";
export const PAWN_INTEREST_CALCULATION = "PAWN_INTEREST_CALCULATION";
export const getPawnDays = () => {
    const config = getConfigureValueByKey(PAWN_EXPIRATION_DAYS, '30');
    return config ? parseInt(config) : 30
};
export const getPawnInterestValue = () => {
    const config = getConfigureValueByKey(PAWN_INTEREST_VALUE, '3');
    return config ? parseInt(config) : 3
};

export const getInterestDaysPerMonth = () => {
    const config = getConfigureValueByKey(PAWN_INTEREST_CALCULATION, '30');
    return config ? parseInt(config) : 30
};

export const initDefaultPawn = () => {
    return {
        customerId: 0,
        customerName: "",
        phone: "",
        itemName: "",
        itemBrand: GoldBrand_Unknown.code,
        itemType: '610',
        itemDescription: "",
        itemValue: 0,
        itemWeight: 0,
        totalWeight: 0,
        pawnDate: dayjs(new Date()),
        pawnDueDate: dayjs(addDays(new Date(), getPawnDays())),
        pawnAmount: 0,
        interestRate: getPawnInterestValue(),
        forfeitedReason: "",
        interestAmount: 0,
        totalAmount: 0,
        weightIncludedGem: false,
        gemWeight: 0,
        visitingGuest: false,
        interestDaysPerMonth: getInterestDaysPerMonth(),
        createdBy: '',
        createdAt: dayjs(new Date()),
        updatedBy: '',
        updatedAt: dayjs(new Date()),
    }
}

export const initDefaultForfeitedPawn = () => {
    return {
        ...initDefaultPawn(),
        forfeitedDate: dayjs(new Date()),
        forfeitedReason: '',
        forfeitedAmount: 0,
        forfeitedReturnAmount: 0
    }
}
export const initDefaultRedeemPawn = () => {
    return {
        ...initDefaultPawn(),
        heldDays: 0,
        redeemDate: dayjs(new Date()),
        extendDate: dayjs(new Date()),
        extendDueDate: dayjs(addDays(new Date(), getPawnDays())),
        extendAmount: 0
    }
}
export const PawnStatus = {
    PAWNED: 'PAWNED',
    REDEEMED: 'REDEEMED',
    FORFEITED: 'FORFEITED',
    CANCELLED: 'CANCELLED',
};
export const mapPawnStatusToText = (pawnStatus) => {
    switch (pawnStatus) {
        case PawnStatus.PAWNED:
            return 'Đang cầm';
        case PawnStatus.REDEEMED:
            return 'Đã trả';
        case PawnStatus.FORFEITED:
            return 'Đã thanh lý';
        case PawnStatus.CANCELLED:
            return 'Đã hủy';
        default:
            return 'Không xác định';
    }
};

export const calculateRedeemPawn = async (pawnId, redeemDate, extendingPawn = false) => {
    const redeemRequest = {
        redeemDate: convertDayjsDate(redeemDate),
        additionalAmount: 0,
        extendingRequest: extendingPawn,
    };
    const response = await axios.post(`${PAWNS_API_ENDPOINT}/${pawnId}/redeem`, redeemRequest);
    return {
        ...response.data,
        pawnDate: convertDayjsDate(response.data.pawnDate),
        redeemDate: convertDayjsDate(response.data.redeemDate),
        pawnDueDate: convertDayjsDate(response.data.pawnDueDate),
        customerName: formatCustomerName(response.data.customer)
    };
};
export const getPawnDueDateClassName = (pawnDueDate = convertDayjsDate(new Date()).format(DATE_SHORT_FORMAT)) => {
    try {
        const currentDateStr = convertDayjsDate(new Date()).format(DATE_SHORT_FORMAT);
        const [day, month, year] = pawnDueDate.split('/');
        const [curDay, curMonth, curYear] = currentDateStr.split('/');
        const endDate = new Date(`${year}-${month}-${day}`);
        const currentDate = new Date(`${curYear}-${curMonth}-${curDay}`);
        const diffDate = Math.ceil((endDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24))
        if (diffDate < 0) {
            return 'due-date-red';
        } else if (diffDate < 5) {
            return 'due-date-warn';
        }
    } catch (error) {
        console.error("Error while compare 2 dates");
    }
    return 'due-date-green';
}

export const PAWN_COLUMNS = [
    {field: 'pawnId', headerName: 'Mã số', width: 120},
    {field: 'customerId', headerName: 'Mã KH', width: 120},
    {field: 'customerName', headerName: 'Tên KH', width: 170},
    {field: 'itemName', headerName: 'Món hàng', width: 150},
    {
        field: 'itemWeight', headerName: 'TL Vàng', width: 130,
        valueFormatter: (params) => readGoldWeightUnits(params.value)
    },
    {
        field: 'gemWeight', headerName: 'TL Hột', width: 120,
        valueFormatter: (params) => readGoldWeightUnits(params.value)
    },
    {
        field: 'pawnDate', headerName: 'Ngày cầm', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_SHORT_FORMAT)
    },
    {
        field: 'pawnDueDate', headerName: 'Đến hạn', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_SHORT_FORMAT),
        cellClassName: (params) => {
            return getPawnDueDateClassName(convertDayjsDate(params.value).format(DATE_SHORT_FORMAT));
        },
    },
    {
        field: 'pawnAmount', headerName: 'Số tiền', width: 120,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'interestRate', headerName: 'Lãi Suất', width: 120,
        valueFormatter: (params) => params.value + '(%)'
    },
    {
        field: 'pawnStatus', headerName: 'Trạng thái', width: 120,
        valueFormatter: (params) => mapPawnStatusToText(params.value)
    },
    {
        field: 'itemBrand', headerName: 'Chành', width: 120,
        valueFormatter: (params) => getGoldBrandLabelByCode(params.value)
    },
    {
        field: 'itemType', headerName: 'Tuổi', width: 120,
        valueFormatter: (params) => getGoldTypeLabelByCode(params.value)
    },
    {
        field: 'itemValue', headerName: 'Giá trị', width: 150,
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {field: 'itemDescription', headerName: 'Mô tả', width: 150},
    {field: 'canceledReason', headerName: 'Lý do hủy', width: 150},
    {field: 'forfeitedReason', headerName: 'Lý do thanh lý', width: 150},
    {field: 'createdBy', headerName: 'Người tạo', width: 150},
    {
        field: 'createdAt', headerName: 'Ngày tạo', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
    {field: 'updatedBy', headerName: 'Người cập nhật', width: 150},
    {
        field: 'updatedAt', headerName: 'Ngày cập nhật', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_LONG_FORMAT)
    },
];

export const calculatePawnDueDate = (pawnDate) => {
    return convertDayjsDate(pawnDate).add(getPawnDays(), 'day').toDate();
}

export const PAWN_SUMMARY_COLUMNS = [
    {field: 'pawnId', headerName: 'Mã số', width: 90},
    {field: 'itemName', headerName: 'Tên món hàng', width: 150},
    {
        field: 'pawnDate',
        headerName: 'Ngày cầm',
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_SHORT_FORMAT)
    },
    {
        field: 'pawnAmount', headerName: 'Số tiền',
        valueFormatter: (params) => formatCurrency(params.value)
    },
    {
        field: 'pawnStatus', headerName: 'Trạng thái',
        valueFormatter: (params) => mapPawnStatusToText(params.value)
    },
    {
        field: 'pawnDueDate', headerName: 'Đến hạn', width: 150,
        valueFormatter: (params) => convertDayjsDate(params.value).format(DATE_SHORT_FORMAT),
        cellClassName: (params) => {
            return getPawnDueDateClassName(convertDayjsDate(params.value).format(DATE_SHORT_FORMAT));
        },
    },
];
const sumPawnAndRequestAmount = (pawns) => {
    let totalSum = 0;
    pawns?.forEach(item => {
        totalSum += (item.pawnAmount ? parseInt(item.pawnAmount) : 0);
        item.reqMoneys?.forEach(req => {
            totalSum += (req.requestAmount ? parseInt(req.requestAmount) : 0);
        })
    });
    return totalSum;
}
export const calculateSummary = (pawns, totalElements) => {
    const totalItem = 'Số đơn: ' + pawns.length + ' của ' + totalElements;
    const amount = sumPawnAndRequestAmount(pawns);
    const totalAmount = 'Số tiền: ' + formatCurrency(amount);
    return {totalItem, totalAmount, totalElements};
};

export const InterestTypes = [
    {code: 30, label: 'Lãi 30 ngày/tháng'},
    {code: 25, label: 'Lãi 25 ngày/tháng'},
]
export const getInterestTypeLabel = (code) => {
    const interestType = InterestTypes.find(type => type.code === code);
    return interestType ? interestType.label : '';
}

export const findPawns = async (pagination, filter, sort) => {
    try {
        const {page, pageSize} = pagination || {pageNumber: 0, pageSize: 100};
        const sortBy = sort || "pawnId,desc";
        const reqBody = filter || {pawnStatuses: ["PAWNED"]}
        const response = await axios.post(
            `${PAWNS_API_ENDPOINT}/find?page=${page}&size=${pageSize}&sort=${sortBy}`, reqBody
        );
        return response.data;
    } catch (error) {
        console.error("Error while fetching data: ", error);
        throw error;
    }
}

export const exportPawns = async (pagination, filter, sort) => {
    try {
        const {page, pageSize} = pagination || {pageNumber: 0, pageSize: 100};
        const sortBy = sort || "pawnId,desc";
        const reqBody = filter || {pawnStatuses: ["PAWNED"]}
        const response = await axios.post(
            `${PAWNS_API_ENDPOINT}/export?page=${page}&size=${pageSize}&sort=${sortBy}`, reqBody, {
                responseType: 'blob'
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error while exporting data: ", error);
        throw error;
    }
}


export const formattedPawnData = (pawnData) => {
    return {
        ...pawnData,
        pawnDate: convertDayjsDate(pawnData.pawnDate),
        pawnDueDate: convertDayjsDate(pawnData.pawnDueDate),
        forfeitedDate: convertDayjsDate(new Date()),
        forfeitedReason: '',
        forfeitedAmount: parseInt(pawnData.itemValue, 0),
        forfeitedReturnAmount: 0,
        customerName: formatCustomerName(pawnData.customer),
        extendDate: convertDayjsDate(new Date()),
        extendDueDate: convertDayjsDate(addDays(new Date(), getPawnDays())),
        extendAmount: pawnData.pawnAmount,
        totalWeight: calculateTotalWeight(pawnData.itemWeight, pawnData.gemWeight),
    }
};
export const PAWN_ACTIONS = [
    {label: 'Sửa', code: 'UPDATED', actStatus: ['PAWNED', 'REDEEMED', 'FORFEITED', 'CANCELLED'], permission: 'UPDATE'},
    {label: 'Thông tin', code: 'VIEW', actStatus: ['PAWNED', 'REDEEMED', 'FORFEITED', 'CANCELLED'], permission: 'VIEW'},
    {label: 'Xóa', code: 'DELETE', actStatus: ['PAWNED', 'REDEEMED', 'FORFEITED', 'CANCELLED'], permission: 'DELETE'},
    {label: 'Hủy', code: 'CANCEL', actStatus: ['PAWNED'], permission: 'CANCEL'},
    {label: 'Thanh lý', code: 'FORFEIT', actStatus: ['PAWNED'], permission: 'FORFEIT'},
    {label: 'Chuộc đồ', code: 'REDEEM', actStatus: ['PAWNED'], permission: 'EXTEND'},
    {label: 'Lấy thêm', code: 'REQ_MONEY', actStatus: ['PAWNED'], permission: 'REQ_MONEY'},
    {label: 'Đóng lãi', code: 'EXTEND', actStatus: ['PAWNED'], permission: 'EXTEND'},
    {
        label: 'Biên nhận',
        code: 'RECEIPT',
        actStatus: ['PAWNED', 'REDEEMED', 'FORFEITED', 'CANCELLED'],
        permission: 'RECEIPT'
    },
    {
        label: 'Khách hàng',
        code: 'CUSTOMER',
        actStatus: ['PAWNED', 'REDEEMED', 'FORFEITED', 'CANCELLED'],
        customerPermission: 'UPDATE'
    },
    {label: 'Trạng thái', code: 'UPDATED_STATUS', actStatus: ['PAWNED'], permission: 'UPDATE', administration: true}
];

export const fetchPawnData = async (pawnId) => {
    const response = await axios.get(`${PAWNS_API_ENDPOINT}/${pawnId}`);
    return response.data;
};

export const suggestPawnItemName = () => {
    const jewelryItem = getTopProducts();
    const pawnItems = getCommonPawnProducts();
    const combinedItems = [...pawnItems, ...jewelryItem];
    return Array.from(new Set(combinedItems));
}

export const suggestPawnValue = (startNumber = 500000,
                                 step = 100000,
                                 maxNumber = 100000000) => {
    return generateIncreasingArray(startNumber, step, maxNumber);
}
export const suggestPawnInterestValue = (value = 0) => {
    const suggestedValues = generateIncreasingArray(5000, 5000, 1000000);
    if (!suggestedValues.includes(String(value))) {
        return [String(value), ...suggestedValues];
    }
    return suggestedValues;
}
export const PAWN_DEFAULT_FILTER = {pawnStatuses: ["PAWNED"]};

export const fetchCustomerPawns = async (customerId, pageNumber, pageSize) => {
    const filter = {
        customerId: customerId
    }
    const response = await findPawns({pageNumber: pageNumber, pageSize: pageSize}, filter, "pawnDate,desc");
    return response.content.map((pawn) => ({
        ...pawn,
        id: pawn.pawnId,
        pawnDate: pawn.pawnDate,
        pawnDueDate: pawn.pawnDueDate,
        pawnStatus: pawn.pawnStatus,
        pawnAmount: pawn.pawnAmount,
    }))
}
