import React from "react";
import {Box, Button, Dialog, DialogTitle,} from "@mui/material";
import axios from '../../services/axiosInstance';
import {handleApiError, handleSuccessMessage} from "../../utils/constants";
import {ORDERS_API_ENDPOINT} from "./OrderConstant";
import {useAlert} from "../../providers/AlertProvider";
import PropTypes from "prop-types";

const DeleteOrder = ({open, onClose, orderData}) => {
    const {openAlert} = useAlert();
    const handleDeleteItem = async () => {
        try {
            await axios.delete(`${ORDERS_API_ENDPOINT}/${orderData.orderId}`);
            handleSuccessMessage('Đơn hàng số [' + orderData.orderId + '] được xóa thành công', openAlert);
        } catch (error) {
            handleApiError(error, openAlert);
        } finally {
            onClose();
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    {`Xóa đơn hàng số [${orderData.orderId}]?`}
                </DialogTitle>

                <Box p={2} display="flex" justifyContent="space-between">
                    <Button onClick={onClose} color="primary" variant="contained">
                        Không
                    </Button>
                    <Button onClick={handleDeleteItem} color="secondary" variant="contained">
                        Xóa đơn
                    </Button>
                </Box>
            </Dialog>
        </div>
    );
};
DeleteOrder.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    orderData: PropTypes.object.isRequired,
}
export default DeleteOrder;
