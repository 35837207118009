import CustomDialog from "../../components/dialog/CustomDialog";
import {Grid, InputAdornment, TextField} from "@mui/material";
import {isMobile} from "react-device-detect";
import {calculateBuyAmount, getResponsiveWidth, isNumber} from "../../utils/constants";
import React, {useEffect, useState} from "react";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import PropTypes from "prop-types";

const InvoiceItemDetails = ({open, onClose, sourceItem, onItemAdded}) => {
    const [invoiceItem, setInvoiceItem] = useState(sourceItem);
    const [itemNameValid, setItemNameValid] = useState(true);
    const [goldWeightValid, setGoldWeightValid] = useState(true);
    const [priceValid, setPriceValid] = useState(true);
    const [amountValid, setAmountValid] = useState(true);
    useEffect(() => {
        setInvoiceItem(sourceItem);
    }, [open, sourceItem]);
    const handleClose = async () => {
        onClose();
    };
    const handleAddSellItem = () => {
        let validated = true;
        if (invoiceItem.itemName) {
            setItemNameValid(true);
        } else {
            setItemNameValid(false);
            validated = false;
        }
        if (isNumber(invoiceItem.itemTotalAmountWithTax)) {
            setAmountValid(true);
        } else {
            setAmountValid(false);
            validated = false;
        }
        if (isNumber(invoiceItem.quantity) && invoiceItem.quantity !== 0) {
            setGoldWeightValid(true);
        } else {
            setGoldWeightValid(false);
            validated = false;
        }
        if (isNumber(invoiceItem.unitPrice) && invoiceItem.unitPrice !== 0) {
            setPriceValid(true);
        } else {
            setPriceValid(false);
            validated = false;
        }
        if (validated) {
            return onItemAdded(invoiceItem);
        }
    }
    const formActions = (
            <CustomLoadingButton variant="contained" onClick={handleAddSellItem}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth
                                 buttonLabel="Thêm món hàng vào hóa đơn"/>
    );
    const pageTitle = (
        <>
            {`Thêm món hàng vào hóa đơn!`}
        </>
    );
    return (
        <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
            <Grid container spacing={2}>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Tên món hàng"
                        required
                        value={invoiceItem.itemName}
                        onChange={(e) => {
                            setInvoiceItem((prev) => ({
                                ...prev,
                                itemName: e.target.value,
                            }));
                            setItemNameValid(true);
                        }}
                        fullWidth
                        error={!itemNameValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label={(isMobile) ? "TL Vàng" : "Trọng lượng Vàng"}
                        value={invoiceItem.quantity}
                        fullWidth
                        required
                        InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                            const amount = calculateBuyAmount(numericInput, invoiceItem.unitPrice, 0);
                            setInvoiceItem((prev) => ({
                                ...prev,
                                quantity: numericInput,
                                itemTotalAmountWithTax: amount,
                            }));
                            setGoldWeightValid(true);
                        }}
                        error={!goldWeightValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Đơn vị tính"
                        readOnly
                        disabled
                        value={invoiceItem.unit}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Đơn giá vnđ/chỉ"
                        required
                        value={Number(invoiceItem.unitPrice).toLocaleString()}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/\D/g, '');
                            const amount = calculateBuyAmount(invoiceItem.quantity, numericInput, 0);
                            setInvoiceItem((prev) => ({
                                ...prev,
                                unitPrice: numericInput ? parseInt(numericInput) : '',
                                itemTotalAmountWithTax: amount,
                            }));
                            setAmountValid(true);
                            setPriceValid(true);
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="end">vnđ/chỉ</InputAdornment>),
                            type: 'search',
                        }}
                        error={!priceValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Thành tiền"
                        readOnly
                        disabled
                        required
                        value={Number(invoiceItem.itemTotalAmountWithTax).toLocaleString()}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">vnđ</InputAdornment>
                            ),
                        }}
                        error={!amountValid}
                    />
                </Grid>
            </Grid>
        </CustomDialog>
    )
};
InvoiceItemDetails.propTypes = {
    open: PropTypes.bool.isRequired,
    sourceItem: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onItemAdded: PropTypes.func.isRequired,
};
export default InvoiceItemDetails;
