import React, {useEffect, useState} from 'react';
import {SimpleTreeView} from '@mui/x-tree-view/SimpleTreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import CustomDialog from "../../components/dialog/CustomDialog";
import {API_ENDPOINT, handleApiError, handleErrorMessage} from "../../utils/constants";
import {connect} from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useAlert} from "../../providers/AlertProvider";
import axios from "../../services/axiosInstance";
import {updateUserPermissions} from "./userThunks";
import PropTypes from "prop-types";

const groupPermissionsByFeature = (permissions) => {
    return permissions.reduce((acc, {feature, permission, description}) => {
        acc[feature] = acc[feature] || [];
        acc[feature].push({permission, description});
        return acc;
    }, {});
};

const translations = {
    PAWN: "Trang quản lý cầm đồ",
    ORDER: "Trang quản lý đơn hàng",
    CUSTOMER: "Trang quản lý khách hàng",
    INVOICE: "Trang quản lý hóa đơn",
    INVENTORY: "Trang quản lý món hàng",
    SYSTEMS: "Trang quản lý hệ thống",
    USER: "Trang quản lý người dùng",
    DASHBOARD: "Bảng điều khiển",
    COMMON: "Khác",
    GOLD_PRICE: "Bảng Giá vàng",
};

const ManagePermissions = ({open, onClose, userData, onUpdatePressed}) => {
    const {openAlert} = useAlert();
    const [permissions, setPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.get(`${API_ENDPOINT}/permissions`);
                setAllPermissions(response.data);
            } catch (error) {
                console.error("Error fetching permissions:", error);
                handleErrorMessage('Không thể tải danh sách quền sử dụng.', openAlert);
            }
        };
        fetchPermissions().then(() => console.debug("Permission fetched!"));
    }, [openAlert]);

    useEffect(() => {
        if (userData && userData.permissions) {
            setPermissions(userData.permissions);
            setChecked(userData.permissions.map(p => `${p.feature}-${p.permission}`));
        }
    }, [userData]);

    const handleCheck = (event, nodeIds) => {
        setChecked(nodeIds);
        const newPermissions = nodeIds.map(id => {
            const [feature, permission] = id.split('-');
            return {feature, permission};
        });
        setPermissions(newPermissions);
    };

    const onSaveClick = async () => {
        try {
            const permissionsToSend = permissions.map(({feature, permission}) => {
                const permissionObj = allPermissions.find(p => p.feature === feature && p.permission === permission);
                return {id: permissionObj.id, feature, permission};
            });
            await onUpdatePressed(userData.username, permissionsToSend);
            onClose();
        } catch (error) {
            console.error("Error while saving permissions:", error);
            handleApiError(error, openAlert);
        }
    };

    const permissionTree = groupPermissionsByFeature(allPermissions);

    const formActions = (
        <CustomLoadingButton
            variant="contained"
            onClick={onSaveClick}
            sx={{minWidth: 170, marginBottom: 1}}
            fullWidth
            buttonLabel="Lưu thay đổi"
        />
    );

    const pageTitle = `Phân quyền người dùng [ ${userData.username} ]`;

    return (
        <CustomDialog open={open} onClose={onClose} title={pageTitle} dialogActions={formActions}>
            <SimpleTreeView
                multiSelect
                selected={checked}
            >
                {Object.entries(permissionTree).map(([feature, permissions]) => (
                    <TreeItem
                        itemId={feature}
                        key={feature}
                        label={translations[feature] || feature}
                    >
                        {permissions.map(({permission, description}) => (
                            <TreeItem
                                key={`${feature}-${permission}`}
                                itemId={`${feature}-${permission}`}
                                label={
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked.includes(`${feature}-${permission}`)}
                                                onChange={(event) => {
                                                    const newChecked = event.target.checked
                                                        ? [...checked, `${feature}-${permission}`]
                                                        : checked.filter(id => id !== `${feature}-${permission}`);
                                                    handleCheck(event, newChecked);
                                                }}
                                            />
                                        }
                                        label={description}
                                    />
                                }
                            />
                        ))}
                    </TreeItem>
                ))}
            </SimpleTreeView>
        </CustomDialog>
    );
};

const mapStateToProps = state => ({
    users: state.users,
});

const mapDispatchToProps = dispatch => ({
    onUpdatePressed: (username, permissions) => dispatch(updateUserPermissions(username, permissions)),
});

ManagePermissions.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userData: PropTypes.object.isRequired,
    onUpdatePressed: PropTypes.func.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagePermissions);
