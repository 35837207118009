import React, {useEffect, useState} from "react";
import {convertDayjsDate, DATE_LONG_FORMAT} from "../../utils/constants";
import {Tooltip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from "prop-types";

const RequestMoneyView = ({
                              reqMoneyData,
                              editable = false,
                              onDelete = () => {
                              }
                          }) => {
    const [data, setData] = useState(reqMoneyData);
    useEffect(() => {
        setData(reqMoneyData);
    }, [editable, reqMoneyData])
    const handleDelete = (requestId) => {
        const updatedData = data.filter((item) => item.requestId !== requestId);
        setData(updatedData);
        onDelete(requestId);
    };

    if (data && data.length > 0) {
        const sortedData = data.slice().sort((a, b) => new Date(a.requestDate) - new Date(b.requestDate));
        return (
            <div>
                {sortedData.map((reqMoney, index) => (
                    <div key={reqMoney.requestId} style={{display: "flex", alignItems: "center"}}>
                        {editable && (
                            <Tooltip title="Xóa dòng này" arrow>
                                <DeleteIcon fontSize="small" onClick={() => handleDelete(reqMoney.requestId)}
                                            style={{cursor: "pointer"}}/>
                            </Tooltip>
                        )}
                        <p style={{margin: 0, marginLeft: 8}}>
                            LT lần {index + 1}: {convertDayjsDate(reqMoney.requestDate).format(DATE_LONG_FORMAT)}.
                            ST: {Number(reqMoney.requestAmount).toLocaleString()}vnđ
                            {reqMoney.heldDays
                                ? `, Số ngày: ${Number(reqMoney.heldDays).toLocaleString()}`
                                : ''}
                            {reqMoney.interestAmount
                                ? `, Tiền lãi: ${Number(reqMoney.interestAmount).toLocaleString()}vnđ`
                                : ''}
                        </p>
                    </div>
                ))}
            </div>
        );
    } else {
        return null;
    }
};
RequestMoneyView.propTypes = {
    reqMoneyData: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    onDelete: PropTypes.func
};
export default RequestMoneyView
