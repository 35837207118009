import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import axios from 'axios';
import {useAlert} from "../../providers/AlertProvider";
import {getShopInfo, handleApiError, SYSTEMS_API_ENDPOINT} from "../../utils/constants";
import PropTypes from "prop-types";

const GoldPriceTable = ({onToggleFullScreen}) => {
    const {openAlert} = useAlert();
    const [goldPrices, setGoldPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const slideshowRef = useRef(null);

    useEffect(() => {
        const fetchGoldPrices = async () => {
            try {
                const response = await axios.get(`${SYSTEMS_API_ENDPOINT}`);
                setGoldPrices(response.data.prices);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching gold prices:', error);
                handleApiError(error, openAlert);
                setLoading(false);
            }
        };

        fetchGoldPrices();
    }, [openAlert]);

    const handleFullScreenToggle = () => {
        if (slideshowRef.current) {
            if (!document.fullscreenElement) {
                slideshowRef.current.requestFullscreen().then(onToggleFullScreen(true)).catch(err => {
                    console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                });
            } else {
                document.exitFullscreen().then(onToggleFullScreen(false));
            }
        }
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <Box sx={{padding: 3}}>
            <Divider><Typography variant="h4" component="div" sx={{flexGrow: 1}}>
                    <span
                        style={{textTransform: 'uppercase'}}>{getShopInfo().name ? getShopInfo().name : 'QUẢN LÝ TIỆM VÀNG'}</span>
            </Typography></Divider>
            <TableContainer component={Paper} sx={{marginTop: 2}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Loại vàng</TableCell>
                            <TableCell>Giá mua vào (VND)</TableCell>
                            <TableCell>Giá bán ra (VND)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {goldPrices.map((gold) => (
                            <TableRow key={gold.id}>
                                <TableCell>{gold.label}</TableCell>
                                <TableCell>{gold.buy.toLocaleString()}</TableCell>
                                <TableCell>{gold.sell.toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div ref={slideshowRef} style={{display: 'none'}}>
            </div>
            <Button
                variant="contained"
                onClick={handleFullScreenToggle}
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                }}
            >
                Full màn hình
            </Button>
        </Box>
    );
};
GoldPriceTable.propTypes = {
    onToggleFullScreen: PropTypes.func
};

export default GoldPriceTable;
