import CustomDialog from "../../components/dialog/CustomDialog";
import {Grid, InputAdornment, MenuItem, Select, Switch, TextField} from "@mui/material";
import {isMobile} from "react-device-detect";
import {
    calculateBuyAmount,
    calculateGoldWeight,
    getResponsiveWidth,
    getSortedGoldBrand,
    getSortedGoldType,
    isNumber
} from "../../utils/constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, {useEffect, useState} from "react";
import CustomLoadingButton from "../../components/common/CustomLoadingButton";
import {ORDER_TYPE} from "./OrderConstant";
import PropTypes from "prop-types";

const BuyItemDetails = ({open, onClose, sourceJewelry, onItemAdded}) => {
    const [validationState, setValidationState] = useState({
        isItemNameValid: true,
        isGoldWeightValid: true,
        isGemWeightValid: true,
        isPriceValid: true,
        isAmountValid: true,
    });
    const [jewelryData, setJewelryData] = useState(sourceJewelry);
    const {isItemNameValid, isGoldWeightValid, isGemWeightValid, isPriceValid, isAmountValid} = validationState;
    const setItemNameValid = (isValid) => {
        setValidationState(prevState => ({...prevState, isItemNameValid: isValid}));
    };

    const setGoldWeightValid = (isValid) => {
        setValidationState(prevState => ({...prevState, isGoldWeightValid: isValid}));
    };

    const setGemWeightValid = (isValid) => {
        setValidationState(prevState => ({...prevState, isGemWeightValid: isValid}));
    };

    const setPriceValid = (isValid) => {
        setValidationState(prevState => ({...prevState, isPriceValid: isValid}));
    };

    const setAmountValid = (isValid) => {
        setValidationState(prevState => ({...prevState, isAmountValid: isValid}));
    };

    useEffect(() => {
        setJewelryData(sourceJewelry);
    }, [open, sourceJewelry]);
    const handleClose = async () => {
        onClose();
    };
    const handleAddSellItem = () => {
        let validated = true;
        if (jewelryData.name.trim() === "") {
            setItemNameValid(false);
            validated = false;
        } else {
            setItemNameValid(true);
        }
        if (jewelryData.goldWeight && jewelryData.goldWeight !== 0) {
            setGoldWeightValid(true);
        } else {
            setGoldWeightValid(false);
            validated = false;
        }
        if (isNumber(jewelryData.gemWeight)) {
            setGemWeightValid(true);
        } else {
            validated = false;
            setGemWeightValid(false);
        }
        if (isNumber(jewelryData.price)) {
            setPriceValid(true);
        } else {
            setPriceValid(false);
            validated = false;
        }
        if (isNumber(jewelryData.amount)) {
            setAmountValid(true);
        } else {
            setAmountValid(false);
            validated = false;
        }
        if (validated) {
            return onItemAdded(jewelryData);
        }
    }
    const formActions = (
        <>
            <CustomLoadingButton variant="contained" onClick={handleAddSellItem}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Thêm dẻ vào đơn"/>
            <CustomLoadingButton variant="contained" onClick={handleClose}
                                 sx={{minWidth: 170, marginBottom: 1}} fullWidth buttonLabel="Hủy"/>
        </>
    );
    const pageTitle = (
        <>
            {`Thêm dẻ khách vào đơn!`}
        </>
    );
    return (
        <CustomDialog open={open} onClose={handleClose} title={pageTitle} dialogActions={formActions}>
            <Grid container spacing={2}>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Tên dẻ"
                        value={jewelryData.name ? jewelryData.name : ''}
                        onChange={(e) => {
                            setJewelryData((prev) => ({
                                ...prev,
                                name: e.target.value,
                            }));
                            setItemNameValid(true);
                        }}
                        fullWidth
                        error={!isItemNameValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(6, 12)}>
                    <Select
                        value={jewelryData.goldType}
                        fullWidth
                        MenuProps={{
                            disableAutoFocusItem: true,
                        }}
                        onChange={(e) => {
                            setJewelryData((prev) => ({
                                ...prev,
                                goldType: e.target.value,
                            }));
                        }}
                    >
                        {getSortedGoldType().map((goldType) => (
                            <MenuItem key={goldType.code} value={goldType.code}>
                                {goldType.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={getResponsiveWidth(6, 12)}>
                    <Select
                        value={jewelryData.goldBrand}
                        fullWidth
                        MenuProps={{
                            disableAutoFocusItem: true,
                        }}
                        onChange={(e) => {
                            setJewelryData((prev) => ({
                                ...prev,
                                goldBrand: e.target.value,
                            }));
                        }}
                    >
                        {getSortedGoldBrand().map((goldBrand) => (
                            <MenuItem key={goldBrand.code} value={goldBrand.code}>
                                {goldBrand.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={getResponsiveWidth(6, 12)}>
                    <TextField
                        label={(isMobile) ? "TL tổng" : "Trọng lượng tổng"}
                        value={jewelryData.totalWeight}
                        fullWidth
                        InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                            const goldWeight = calculateGoldWeight(numericInput, jewelryData.gemWeight);
                            const amount = calculateBuyAmount(goldWeight, jewelryData.price, jewelryData.compPrice);
                            setJewelryData((prev) => ({
                                ...prev,
                                totalWeight: numericInput,
                                goldWeight: goldWeight,
                                amount: amount,
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(6, 12)}>
                    <TextField
                        label={(isMobile) ? "TL hột" : "Trọng lượng hột"}
                        value={jewelryData.gemWeight}
                        fullWidth
                        InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                            const goldWeight = calculateGoldWeight(jewelryData.totalWeight, numericInput);
                            const amount = calculateBuyAmount(goldWeight, jewelryData.price, jewelryData.compPrice);
                            setJewelryData((prev) => ({
                                ...prev,
                                gemWeight: numericInput,
                                goldWeight: goldWeight,
                                amount: amount
                            }));
                            setGemWeightValid(true);
                        }}
                        error={!isGemWeightValid}
                    />
                </Grid>
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label={(isMobile) ? "TL vàng" : "Trọng lượng vàng"}
                        readOnly disabled
                        value={jewelryData.goldWeight}
                        fullWidth
                        InputProps={{endAdornment: (<InputAdornment position="end">chỉ</InputAdornment>),}}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/[^0-9.]/g, '');
                            const amount = calculateBuyAmount(numericInput, jewelryData.price, jewelryData.compPrice);
                            setJewelryData((prev) => ({
                                ...prev,
                                goldWeight: numericInput,
                                amount: amount,
                            }));
                            setGoldWeightValid(true);
                        }}
                        error={!isGoldWeightValid}
                    />
                </Grid>
                {(ORDER_TYPE.EXEC_ORDER.code === jewelryData.orderType) && (
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <FormControlLabel control={
                            <Switch
                                onChange={(e) => {
                                    const price = (e.target.checked) ? sourceJewelry.sellPrice : sourceJewelry.price;
                                    const amount = calculateBuyAmount(jewelryData.goldWeight, price, jewelryData.compPrice);
                                    setJewelryData((prev) => ({
                                        ...prev,
                                        price: price,
                                        amount: amount,
                                        excPriceSelected: (e.target.checked)
                                    }));
                                }}/>
                        }
                                          label="Áp dụng đổi ngang bù công(đơn giá bằng giá bán ra)"/>
                    </Grid>)}
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Đơn giá"
                        value={Number(jewelryData.price).toLocaleString()}
                        onChange={(e) => {
                            const numericInput = e.target.value.replace(/\D/g, '');
                            const amount = calculateBuyAmount(jewelryData.goldWeight, numericInput, jewelryData.compPrice);
                            setJewelryData((prev) => ({
                                ...prev,
                                price: numericInput,
                                amount: amount,
                            }));
                            setAmountValid(true);
                            setPriceValid(true);
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                            type: 'search',
                        }}
                        error={!isPriceValid}
                    />
                </Grid>
                {(ORDER_TYPE.EXEC_ORDER.code === jewelryData.orderType) && (
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Đơn giá mua dẻ dư(nếu có)"
                            value={Number(jewelryData.buyPrice).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                setJewelryData((prev) => ({
                                    ...prev,
                                    buyPrice: numericInput,
                                }));
                            }}
                            fullWidth
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">vnđ</InputAdornment>),
                            }}
                            error={!isPriceValid}
                        />
                    </Grid>
                )}
                {(ORDER_TYPE.EXEC_ORDER.code === jewelryData.orderType) && (
                    <Grid item xs={getResponsiveWidth(12, 12)}>
                        <TextField
                            label="Giá bù nếu có (vnđ/chỉ)"
                            value={Number(jewelryData.compPrice).toLocaleString()}
                            onChange={(e) => {
                                const numericInput = e.target.value.replace(/\D/g, '');
                                const amount = calculateBuyAmount(jewelryData.goldWeight, jewelryData.price, numericInput);
                                setJewelryData((prev) => ({
                                    ...prev,
                                    compPrice: numericInput,
                                    amount: amount,
                                }))
                            }}
                            fullWidth
                            InputProps={{
                                type: 'search',
                                endAdornment: (
                                    <InputAdornment position="end">vnđ/chỉ</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={getResponsiveWidth(12, 12)}>
                    <TextField
                        label="Thành tiền"
                        readOnly disabled
                        value={Number(jewelryData.amount).toLocaleString()}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">vnđ</InputAdornment>
                            ),
                        }}
                        error={!isAmountValid}
                    />
                </Grid>
            </Grid>
        </CustomDialog>
    )
}
BuyItemDetails.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    sourceJewelry: PropTypes.object.isRequired,
    onItemAdded: PropTypes.func,
}
export default BuyItemDetails;
